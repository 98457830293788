import React, { useEffect, useState } from "react";
import login from "../../../assets/images/Computer login-bro.svg";
import mobileLogin from "../../../assets/images/Mobile login-cuate.svg";
import GeneralMsgModal from "../../../components/modals/GeneralMsgModal";
import EmailVerificationModal from "../../../components/modals/EmailVerficationModal";
import orgLogin from "../../../assets/orglogin.json";
import { useNavigate, useLocation } from "react-router-dom";
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
} from "firebase/auth";
import { getDocs, collection } from "firebase/firestore";
import { auth, db } from "../../../config/FirebaseConfig";
import "../../../App.css";
import Loader from "../../../components/loader";
import AnimateImg from "../../../components/animations/AnimateImg";
const OrganizationLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("admin")) {
      navigate("/");
    }
  }, []);

  const [authCred, setAuthCred] = useState({ email: "", password: "" });
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const onChange = (e) => {
    setAuthCred({ ...authCred, [e.target.name]: e.target.value });
  };

  const handleLogin = async () => {
    if (authCred.email === "" || authCred.password === "") {
      setError(true);
      setMessage("Please fill all the fields");
      setLoading(false);
    } else {
      setLoading(true);
      try {
        const user = await signInWithEmailAndPassword(
          auth,
          authCred.email,
          authCred.password
        );
        onAuthStateChanged(auth, (user) => {
          if (user) {
            if (user.emailVerified) {
              getDocs(collection(db, "raiser")).then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                  if (doc.id === user.uid) {
                    localStorage.setItem("admin", true);
                    localStorage.setItem("uid", user.uid);
                    setLoading(false);
                    navigate("/admin");
                  } else {
                    setMessage("User Not found in Organization Login");
                    setLoading(false);
                    setError(true);
                  }
                });
              });
            } else {
              setLoading(false);
              setShowErrorModal(true);
            }
          } else {
            setLoading(false);
            setMessage("Check your Login Role");
            setError(true);
          }
        });
      } catch (error) {
        if (error.code === "auth/user-not-found") {
          setMessage("This email is not registered");
          setLoading(false);
          setError(true);
        } else if (error.code === "auth/wrong-password") {
          setMessage("Password is incorrect");
          setLoading(false);
          setError(true);
        } else {
          setMessage(error.message);
          setError(true);
          setLoading(false);
        }
      }
    }
  };

  const resendVerificationEmail = async () => {
    try {
      // Send the verification email to the currently signed-in user
      await sendEmailVerification(auth.currentUser);
      // Show a success message or update UI accordingly
      console.log("Verification email sent successfully");
    } catch (error) {
      // Handle any errors here
      console.error("Error sending verification email:", error.message);
    }
  };

  return (
    <div className="md:h-screen md:overflow-hidden md:flex-row flex-col-reverse flex gap-5">
      <div className="flex flex-col gap-4 height xl:w-[45%] md:w-[55%] w-full lg:px-32 md:px-20 px-9 py-10 md:py-0 text-white  justify-center bg-[#283a99]">
        <div className="flex md:flex-row flex-col-reverse justify-between md:items-center">
          <span className="text-white text-sm md:hidden block">
            Before Continue, Login First
          </span>
          <span className="md:text-4xl text-3xl font-bold">
            Organization Login
          </span>
          <i
            onClick={() => {
              navigate("/", { replace: true });
            }}
            style={{ fontSize: "30px", cursor: "pointer", color: "white" }}
            className="fa fa-home"
            aria-hidden="true"
          ></i>
        </div>

        <div className=" mx-auto img w-auto  md:hidden flex">
          <AnimateImg json={orgLogin} width="100%" height="100%" />
        </div>

        <div className="relative mt-6">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
            <i
              style={{ color: "white", fontSize: "20px" }}
              className="fa fa-user"
              aria-hidden="true"
            ></i>
          </div>
          <input
            onChange={onChange}
            name="email"
            type="email"
            id="input-group-1"
            className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg focus:ring-0 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
            placeholder="name@flowbite.com"
          />
        </div>

        <div className="relative ">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
            <i
              style={{ color: "white", fontSize: "20px" }}
              className="fa fa-lock"
              aria-hidden="true"
            ></i>
          </div>
          <input
            onChange={onChange}
            name="password"
            type="password"
            id="input-group-2"
            className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg  block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
            placeholder="**********"
          />
        </div>

        <div className="flex justify-end">
          <span
            onClick={() => {
              navigate("/forget");
            }}
            className="cursor-pointer hover:text-slate-300"
          >
            Forget Password?{" "}
          </span>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <button
            onClick={handleLogin}
            className="p-3 bg-white text-[#283a99] text-md font-bold hover:scale-[1.1] cursor-pointer transition-transform duration-500 ease-in-out rounded-xl"
          >
            Login
          </button>
        )}

        <span className="mt-5 ">
          Don't have and account?{" "}
          <span
            onClick={() => {
              navigate("/orgSignup");
            }}
            className="cursor-pointer hover:text-slate-300 underline "
          >
            SignUp
          </span>{" "}
        </span>
      </div>

      <div className="md:flex hidden xl:flex-1 md:flex-[0.8] items-center justify-center">
        <div src={login} alt="" className=" xl:w-5/6 md:flex hidden">
          <AnimateImg json={orgLogin} width="100%" height="100%" />
        </div>
        <div className=" w-5/6 md:hidden flex">
          <AnimateImg json={orgLogin} width="100%" height="100%" />
        </div>
      </div>

      {error && (
        <GeneralMsgModal
          message={message}
          heading={"Warning"}
          closeModal={() => {
            setError(false);
          }}
        />
      )}

      {showErrorModal && (
        <EmailVerificationModal
          closeModal={() => setShowErrorModal(false)}
          resendVerification={resendVerificationEmail}
        />
      )}
    </div>
  );
};

export default OrganizationLogin;
