import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { db, auth } from "../../../config/FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
const ShowSuperAdminUserCampaign = ({ uid }) => {
  useEffect(() => {
    getJoinedData();
  }, []);
  const navigate = useNavigate();
  const [joinedData, setJoinedData] = useState([]);
  const getJoinedData = async () => {
    let dataArr = [];
    await getDocs(collection(db, "campaigns")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const {
          campId,
          commission,
          description,
          image,
          isApproved,
          joinedUsers,
          name,
          raisId,
          url,
          category,
        } = doc.data();
        joinedUsers.forEach((usr) => {
          if (usr.uid === uid) {
            dataArr.push({
              campId,
              commission,
              description,
              image,
              isApproved,
              joinedUsers,
              name,
              raisId,
              url,
              category,
            });
          }
        });
      });
    });
    setJoinedData(dataArr);
  };
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-center  text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-white uppercase bg-[#283a99] ">
          <tr>
            <th scope="col" className="">
              #
            </th>
            <th scope="col" className="px-6 py-3">
              Id
            </th>
            <th scope="col" className="px-6 py-3">
              Name
            </th>
            <th scope="col" className="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {joinedData.length === 0 ? (
            <span>No Campaign Data</span>
          ) : (
            joinedData.map((camp, index) => {
              return (
                <tr
                  key={index}
                  className={`bg-white border-b ${
                    index % 2 !== 0 && "bg-[#fee5f78a]"
                  } `}
                >
                  <th
                    scope="row"
                    className=" font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </th>
                  <td scope="row" className="px-6 py-3 ">
                    {camp.campId}
                  </td>
                  <td className="px-6 py-3">{camp.name}</td>
                  <td
                    onClick={() => {
                      localStorage.setItem("item", JSON.stringify(camp));
                      localStorage.setItem("isSuperAdmin", true);
                      navigate("/admin/campDetail", { state: { item: camp } });
                    }}
                    className="px-6 py-3 underline hover:text-blue-500 cursor-pointer"
                  >
                    Details
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ShowSuperAdminUserCampaign;
