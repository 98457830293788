import React, { useEffect } from "react";
import bg from "../../assets/images/paymentTracking.jpg";
import { useState } from "react";
import { auth, db } from "../../config/FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import Loader from "../../components/loader";
import PaymentBox from "../../superAdmin/pages/payments/PaymentBox";
const PaymentTracking = () => {
  const [userData, setdata] = useState([]);
  const [usersCampainsData, setUsersCampainsData] = useState([]);
  const [usersPaymentData, setUsersPaymentData] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    let users = [];
    const querySnapshot = await getDocs(collection(db, "users"));
    querySnapshot.forEach((doc) => {
      if (doc.data().uid === localStorage.getItem("uid")) {
        users.push(doc.data());
      }
    });
    const camps = await getUsersCampaign();
    let usersWithCampaigns = [];
    users.forEach((user) => {
      // Filter the joinedUsers array to find matching addID
      const matchingCampaigns = camps.filter((camp) =>
        camp.joinedUsers.some((joinedUser) => joinedUser.affId === user.affid)
      );

      // If matching campaigns are found, add them to the user object
      if (matchingCampaigns.length > 0) {
        usersWithCampaigns.push({
          userId: user.affid,
          camps: matchingCampaigns,
        });
      }
    });
    let usersPayment = [];
    const querySnapshotPayment = await getDocs(collection(db, "payment"));
    querySnapshotPayment.forEach((doc) => {
      usersPayment.push(doc.data());
    });
    setUsersPaymentData(usersPayment);
    setUsersCampainsData(usersWithCampaigns);
    setdata(users);
    setLoading(false);
  };

  const getUsersCampaign = async () => {
    let campData = [];
    const querySnapshot = await getDocs(collection(db, "campaigns"));
    querySnapshot.forEach((doc) => {
      campData.push(doc.data());
    });
    return campData;
  };

  const [selected, setSelected] = useState(0);
  const handleSelected = (key) => {
    if (selected === key) {
      setSelected(0);
    } else {
      setSelected(key);
    }
  };

  const getTotalEarning = (affid) => {
    let sum = 0;
    const foundUser = usersCampainsData.find((user) => user.userId === affid);
    if (foundUser) {
      foundUser.camps.map((camp) => {
        const newFoundUser = camp.joinedUsers.find(
          (joinedUser) => joinedUser.affId === affid
        );
        if (newFoundUser) {
          if (newFoundUser.amount) {
            sum +=
              (Number(newFoundUser.amount) / 100) * Number(camp.commission);
          }
        }
      });
    }
    return sum.toFixed(0);
  };
  const [totalPay, setTotalPay] = useState(0);
  const getTotalPayment = (affid) => {
    let sum = 0;
    usersPaymentData.forEach((user) => {
      if (user.affId === affid) {
        sum += Number(user.amount);
      }
    });
    return sum.toFixed(0);
  };
  const getBalance = (totalPay, earning) => {
    return (Number(earning) - Number(totalPay)).toFixed(0);
  };
  return (
    <>
      {/* Header */}
      <div
        className="h-[40vh] bg-center flex-col bg-cover flex items-center justify-center"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <span className=" pl-5 text-white md:text-4xl sm:text-3xl text-2xl font-bold text-shadow-md ">
          Payments Tracking
        </span>
        <span className=" pl-5  max-w-5xl text-gray-300 my-5">
          Effortlessly manage and monitor your financial transactions with our
          comprehensive payment tracking system. Stay in control of your cash
          flow, review transaction details, and gain valuable insights into your
          financial performance. Experience the convenience of centralized
          payment tracking, providing you with a clear and organized view of
          your financial landscape.
        </span>
      </div>

      <div className="flex flex-col w-[83%] mx-auto pl-5">
        <div className="flex justify-between items-center  bg-[#cbdaff] p-3  mt-10 mb-3 rounded-md">
          <span className="font-bold flex-[0.2]">#</span>
          <span className="flex-1 text-center">Name</span>
          <span className="flex-1 text-center">Earning</span>
          <span className="flex-1 text-center">Total Payment</span>
          <span className="flex-1 text-center">Balance</span>
        </div>
        {loading ? (
          <Loader />
        ) : (
          userData.map((item, key) => {
            return (
              <>
                <div
                  key={key}
                  onClick={() => {
                    handleSelected(1);
                  }}
                  className="flex justify-between items-center cursor-pointer  bg-[#cbdaff] p-3 mt-0 my-10 rounded-md"
                >
                  <span className="font-bold flex-[0.2]">{key + 1}</span>
                  <span className="flex-1 text-center">{item.firstName}</span>
                  <span className="flex-1 text-center ">
                    ${getTotalEarning(item.affid)}
                  </span>
                  <span className="flex-1 text-center ">
                    ${getTotalPayment(item.affid)}
                  </span>
                  <span className="flex-1 text-center ">
                    $
                    {getBalance(
                      getTotalPayment(item.affid),
                      getTotalEarning(item.affid)
                    )}
                  </span>

                  {selected === 1 ? (
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  ) : (
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                  )}
                </div>
                {selected === 1 && (
                  <PaymentBox
                    totalPayment={getTotalPayment(item.affid)}
                    item={item}
                    user={true}
                    aff={item.affid}
                    key={key}
                    balance={getBalance(
                      getTotalPayment(item.affid),
                      getTotalEarning(item.affid)
                    )}
                    earning={getTotalEarning(item.affid)}
                  />
                )}
              </>
            );
          })
        )}
      </div>
    </>
  );
};

export default PaymentTracking;
