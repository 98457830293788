export const termsList = [
  {
    heading: "Introduction",
    para: "Welcome to the Affiliate Marketing Program operated by JCloud Media LLC, doing business as 'Jewish Affiliate Network' (hereinafter referred to as 'JCloud Media,' 'we,' 'our,' or 'us'). By using our website and services, Advertisers ('you' or 'your') agree to comply with and be bound by these Terms and Conditions. Please read this agreement carefully.",
  },
  {
    heading: "Definitions",
    points: [
      '"Organization" refers to entities running commission-based fundraising campaigns.',
      "'Advertisers' refer to individuals or entities promoting Organization's campaigns through unique affiliate links.",
      "'Donation' refers to contributions made by donors to Organization's campaigns",
      "'Commission' refers to the earnings Advertisers receive from valid Donations made through their unique affiliate links.",
    ],
  },
  {
    heading: "Affiliate Program Overview",
    para: "Jewish Affiliate Network provides a platform for Organizations to display their campaigns and for Advertisers to promote these campaigns using unique affiliate links. Advertisers earn a commission for valid Donations made through their affiliate links.",
  },
  {
    heading: "Commission Structure",
    points: [
      "Advertisers earn a commission on valid Donations made through their unique affiliate links.",
      "Donations not made through the affiliate links are not credited to the Advertiser.",
      "The commission structure and rates will be specified by individual Organizations.",
    ],
  },
  {
    heading: "Payment",
    points: [
      "Earnings from commissions will be paid out at the end of each month.",
      "There is no minimum payout requirement",
      "Payments will be made via PayPal",
      "Payments are conditional upon the Organization's payment to Jewish Affiliate Network.",
    ],
  },
  {
    heading: "Duration of Earnings",
    para: "Earnings for a specific campaign cease after two weeks from the date of agreeing to advertise for that campaign.",
  },
  {
    heading: "Responsibilities",
    points: [
      "Jewish Affiliate Network is responsible for making payments to Advertisers, conditional upon Organization payments",
      "Jewish Affiliate Network is responsible for making payments to Advertisers, conditional upon Organization payments",
    ],
  },
  {
    heading: "Modification of Agreement",
    para: "Jewish Affiliate Network reserves the right to modify these terms and conditions at any time. Advertisers will be notified of any changes.",
  },
  {
    heading: "Termination",
    para: "Jewish Affiliate Network reserves the right to terminate or suspend an Advertiser's account for any violation of these terms and conditions or for any other reason at our discretion.",
  },
  {
    heading: "Contact Information",
    para: "If you have any questions or concerns about these terms and conditions, please contact us at Info@Jewishaffiliatenetwork.com .",
  },
  {
    heading: "Agreement Acceptance",
    para: "By using Jewish Affiliate Network and participating in our affiliate program, you acknowledge that you have read, understood, and agree to these Terms and Conditions.",
  },
];
