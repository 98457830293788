import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAUSTVgRYHQeMha5n8p3UtxCf0CxA0jOt8",
  authDomain: "jewish-affiliate-network.firebaseapp.com",
  projectId: "jewish-affiliate-network",
  storageBucket: "jewish-affiliate-network.appspot.com",
  messagingSenderId: "804630522088",
  appId: "1:804630522088:web:1ff89118eec158194ac15d",
  measurementId: "G-1PHHPLTKXW"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const db = getFirestore(app);

export const storage = getStorage(app);
