import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useFetcher } from "react-router-dom";
import {
  getDocs,
  collection,
  updateDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../../config/FirebaseConfig";
import Loading from "../../components/animations/Loading";
import QrGenerator from "../../components/qrcode/QrGenerator";
import CarouselComp from "../../components/Carousel";
import category1 from "../../assets/images/category1.jpeg";
import category2 from "../../assets/images/category2.jpeg";
import Charidy from "../../components/backend/charidy";
import Chesedfund from "../../components/backend/chesedfund";
import SuccessModal from "../../components/modals/SuccessModal";
import HomePayoutModal from "../../components/modals/HomePayoutModal";
import Chesetrack from "../../components/backend/chesetrack";
import Charidytrack from "../../components/backend/charidytrack";

const CampaignDetail = () => {
  const [loading, setLoading] = useState(false);
  const [campDataItem, setCampDataItem] = useState("");
  const handleCopy = (copyText) => {
    navigator.clipboard.writeText(copyText);
  };
  const location = useLocation();
  const [joined, setJoined] = useState(
    localStorage.getItem("joined") ? true : false
  );
  const [clicks, setClicks] = useState(0);
  const [clickss, setClickss] = useState(0);
  const [tinyUrl, setTinyUrl] = useState("");
  const [earning, setEarning] = useState("");
  const [User, setUser] = useState("");
  const [result, setResult] = useState();
  const [useramount, setUserAmount] = useState();
  const [comison, setComison] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    setDataFromLocalStorage();
    checkDate();
    const fetchData = async () => {
      try {
        if (!localStorage.getItem("auth")) {
          console.log("User not authenticated.");
          // Handle the case where the user is not authenticated
          return;
        }

        const userId = localStorage.getItem("uid");
        const affid = localStorage.getItem("affId");
        const storedItem = await JSON.parse(localStorage.getItem("item"));
        const campaignUrl = storedItem.url; // Replace with the actual campaign URL

        const campaignRef = collection(db, "campaigns");
        const q = query(campaignRef, where("url", "==", campaignUrl));
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const campaignDoc = querySnapshot.docs[0];
          const campaignData = campaignDoc.data();

          // Assuming you have a "joinedUsers" field in your campaign document
          const joinedUsers = campaignData.joinedUsers || [];

          // Find the user's entry in the joinedUsers array
          const userEntry = joinedUsers.find((entry) => entry.uid === userId);

          if (userEntry) {
            // Assuming you have an "amount" field in the user's entry
            const userAmount = userEntry.amount;
            setUserAmount(userAmount);
          } else {
            console.log("User not found in joinedUsers array.");
            // Handle the case where the user is not found in the joinedUsers array
          }
        } else {
          console.log("No matching campaign found.");
          // Handle the case where no matching campaign is found
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error reporting or display to the user
      }
    };

    fetchData();
  }, []);

  const setDataFromLocalStorage = async () => {
    const item = await JSON.parse(localStorage.getItem("item"));
    setCampDataItem(item);
    setComison(item.commission);
  };

  const handleGetHits = async (turl) => {
    let idToken = "";
    try {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (!user) {
          console.log("User not authenticated.");
          // You might want to handle this case, e.g., redirect to login.
          return;
        }

        idToken = await user.getIdToken();
        const localEmulatorURL =
          "https://us-central1-jewish-affiliate-network.cloudfunctions.net/getTinyURLHits"; // Update with your local emulator URL
        const tinyURL = turl;

        const response = await fetch(
          `${localEmulatorURL}?tinyURL=${encodeURIComponent(tinyURL)}`,
          {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          setClickss(data);
          await updateClicksDatabase(data);
        } else {
          console.error("Error:", response.statusText);
        }
      });

      // const idToken = tokens; // Replace with the actual ID token

      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const updateClicksDatabase = async (click) => {
    const querySnapshot = await getDocs(collection(db, "campaigns"));
    querySnapshot.forEach((docc) => {
      const { campId, joinedUsers } = docc.data();
      if (campId === localStorage.getItem("campId")) {
        for (const user of joinedUsers) {
          if (user.uid === localStorage.getItem("uid")) {
            user.clicks = click;
          }
        }
        const newDocRef = doc(collection(db, "campaigns"), docc.id);
        updateDoc(newDocRef, { joinedUsers: joinedUsers });
      }
    });
  };
  const navigate = useNavigate();
  const [expired, setExpired] = useState(false);
  const [entended, setEntended] = useState(false);
  const [showPayoutDate, setShowPayoutDate] = useState("-");
  const [successModal, setSuccessModal] = useState(false);

  const checkDate = async () => {
    setLoading(true);
    await setDataFromLocalStorage();
    const currentDate = new Date();
    let turl;
    var storedArrayString = localStorage.getItem("joinedUsers");
    var retrievedArray = storedArrayString ? JSON.parse(storedArrayString) : [];
    for (const user of retrievedArray) {
      if (user.uid === localStorage.getItem("uid")) {
        setEntended(user.extended);
        setTinyUrl(user.tinyUrl);
        setEarning(user.earning);

        turl = user.tinyUrl;
        const joinedDate = user.date.split("-");
        const joinedDateObj = new Date(
          joinedDate[2],
          joinedDate[1] - 1,
          joinedDate[0]
        );

        // Calculate 14 days from the joined date
        const days = 14 * 24 * 60 * 60 * 1000; // 14 days in milliseconds
        const payoutDate = new Date(joinedDateObj.getTime() + days);

        // Convert payoutDate to a string
        const payoutDateStr = `${
          payoutDate.getMonth() + 1
        }-${payoutDate.getDate()}-${payoutDate.getFullYear()}`;

        setShowPayoutDate(payoutDateStr);
        const timeDifference = currentDate.getTime() - joinedDateObj.getTime();
        const differenceInDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
        if (differenceInDays <= 14) {
          setExpired(false);
        } else {
          setExpired(true);
        }
      }
    }
    if (turl) {
      // await getTinyURLHits(turl);
      await handleGetHits(turl);
    }
    setLoading(false);
  };

  const shortenUrl = async (originalUrl) => {
    const apiToken =
      "PuQdqRMZVtHNMF9oIDFWTIXOYZ7aVMph9Jpz4f7jiLlq8ebLrXUodMuQjq9Q";
    try {
      const response = await fetch(
        "https://api.tinyurl.com/create?api_token=",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${apiToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            url: originalUrl,
            domain: "tinyurl.com",
          }),
        }
      );

      const data = await response.json();
      const { tiny_url } = data.data;
      return tiny_url;
    } catch (error) {
      console.error("Error shortening URL:", error);
    }
  };
  const [payoutModel, setPayoutModel] = useState(false);

  const handleJoinCampaign = async () => {
    const profile = localStorage.getItem("profile");
    if (profile !== "false") {
      setLoading(true);
      const tinny = await shortenUrl(
        campDataItem.category === "Chesed Fund Campaign"
          ? `${campDataItem.url}?aff=${localStorage.getItem("affId")}`
          : campDataItem.category === "Charidy Campaign"
          ? `${campDataItem.url}?utm_source=${localStorage.getItem("affId")}`
          : campDataItem.category === "Others"
          ? `${campDataItem.url}${campDataItem.tracking}${localStorage.getItem(
              "affId"
            )}`
          : null
      );
      setTinyUrl(tinny);
      const date = new Date();

      try {
        const querySnapshot = await getDocs(collection(db, "campaigns"));

        for (const docc of querySnapshot.docs) {
          const { campId, joinedUsers, raisId } = docc.data();

          if (campId === localStorage.getItem("campId")) {
            joinedUsers.push({
              uid: localStorage.getItem("uid"),
              date: `${date.getDate()}-${
                date.getMonth() + 1
              }-${date.getFullYear()}`,
              extended: false,
              tinyUrl: tinny,
              affId: localStorage.getItem("affId"),
            });

            const countDocRef = doc(collection(db, "campaigns"), docc.id);
            await updateDoc(countDocRef, { joinedUsers });

            await updateRaiser(raisId, campId);

            localStorage.setItem("joined", true);
            var arrayString = JSON.stringify(joinedUsers);

            localStorage.setItem("joinedUsers", arrayString);

            setLoading(false);
            setSuccessModal(true);
          }
        }
      } catch (error) {
        console.error("Error joining campaign:", error);
        setLoading(false);
        // Handle the error as needed
      }
    } else {
      setPayoutModel(true);
    }
  };

  const changeNav = () => {
    navigate("/campaign");
  };

  const updateRaiser = async (raisId, campId) => {
    const date = new Date();
    const querySnapshot = await getDocs(collection(db, "raiser"));
    querySnapshot.forEach((docc) => {
      const { campaigns, joinedUsers, uid } = docc.data();
      if (uid === raisId) {
        joinedUsers.push({
          uid: localStorage.getItem("uid"),
          date:
            date.getDate() +
            "-" +
            (date.getMonth() + 1) +
            "-" +
            date.getFullYear(),
          extended: false,
        });
        const newDocRef = doc(collection(db, "raiser"), raisId);
        updateDoc(newDocRef, { joinedUsers: joinedUsers });
        setJoined(true);
      }
    });
    // alert("done");
  };

  const updateRaiserExtendDate = async (raisId, campId) => {
    const date = new Date();
    const querySnapshot = await getDocs(collection(db, "raiser"));
    querySnapshot.forEach((docc) => {
      const { campaigns, joinedUsers, uid } = docc.data();
      if (uid === raisId) {
        for (const user of joinedUsers) {
          if (user.uid === localStorage.getItem("uid")) {
            user.date = `${date.getDate()}-${
              date.getMonth() + 1
            }-${date.getFullYear()}`;
            user.extended = true;
          }
        }
        const newDocRef = doc(collection(db, "raiser"), raisId);
        updateDoc(newDocRef, { joinedUsers: joinedUsers });
      }
    });
    alert("done");
  };

  const handleExtendDate = async () => {
    setLoading(true);
    setEntended(true);
    const date = new Date();
    try {
      const querySnapshot = await getDocs(collection(db, "campaigns"));

      for (const docc of querySnapshot.docs) {
        const { campId, joinedUsers, raisId } = docc.data();

        if (campId === localStorage.getItem("campId")) {
          for (const user of joinedUsers) {
            if (user.uid === localStorage.getItem("uid")) {
              user.date = `${date.getDate()}-${
                date.getMonth() + 1
              }-${date.getFullYear()}`;
              user.extended = true;
            }
          }

          const countDocRef = doc(collection(db, "campaigns"), docc.id);
          await updateDoc(countDocRef, { joinedUsers });

          await updateRaiserExtendDate(raisId, campId);
          setLoading(false);
          navigate("/campaign");
        }
      }
    } catch (error) {
      console.error("Error joining campaign:", error);
      setLoading(false);
      // Handle the error as needed
    }
  };

  const setJoinedOption = () => {
    const currentDate = new Date();
    const payoutDate = new Date(
      currentDate.getTime() + 14 * 24 * 60 * 60 * 1000
    ); // Add 14 days in milliseconds

    // Format the payout date as a string
    const formattedPayoutDate = `${payoutDate.getDate()}-${
      payoutDate.getMonth() + 1
    }-${payoutDate.getFullYear()}`;

    setJoined(true);
    setShowPayoutDate(formattedPayoutDate);
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {campDataItem !== "" && <CarouselComp image={campDataItem.image} />}

          <div className=" max-w-[88rem] my-5 mb-10 p-5 m-auto flex flex-col gap-3">
            <div className="flex items-center">
              <span className="border-t-4 border-[#283a99] w-[23%]"></span>
              <i
                style={{ color: "#283a99", fontWeight: "bold" }}
                className="fa fa-ravelry"
                aria-hidden="true"
              ></i>
            </div>
            <div className="flex sm:flex-row flex-col justify-between items-center gap-5">
              <span className=" sm:text-4xl w-[70%] text-3xl font-bold text-[#283a99] ">
                {campDataItem.name}
              </span>
              {!localStorage.getItem("home") && (
                <div className="flex gap-2 items-center">
                  {expired ? (
                    !localStorage.getItem("ended") ? (
                      <button
                        className=" rounded-md bg-[#283a99] hover:bg-[#283a999c] text-white px-5 py-2 "
                        onClick={handleExtendDate}
                      >
                        {" "}
                        <i
                          className="fa pr-3 fa-handshake-o"
                          aria-hidden="true"
                        ></i>
                        Click to Extend
                      </button>
                    ) : (
                      <button className=" rounded-md bg-[#283a99] hover:bg-[#283a999c] text-white px-5 py-2 ">
                        {" "}
                        <i
                          className="fa pr-3 fa-handshake-o"
                          aria-hidden="true"
                        ></i>
                        Ended
                      </button>
                    )
                  ) : localStorage.getItem("joined") ? (
                    !localStorage.getItem("ended") ? (
                      <button className=" rounded-md bg-[#283a99] hover:bg-[#283a999c] text-white px-5 py-2 ">
                        {" "}
                        <i
                          className="fa pr-3 fa-handshake-o"
                          aria-hidden="true"
                        ></i>
                        {entended ? "Extended" : "Joined"}
                      </button>
                    ) : (
                      <button className=" rounded-md bg-[#283a99] hover:bg-[#283a999c] text-white px-5 py-2 ">
                        {" "}
                        <i
                          className="fa pr-3 fa-handshake-o"
                          aria-hidden="true"
                        ></i>
                        Ended
                      </button>
                    )
                  ) : (
                    <button
                      onClick={handleJoinCampaign}
                      className=" rounded-md bg-[#283a99] hover:bg-[#283a999c] text-white px-5 py-2 "
                    >
                      {" "}
                      <i
                        className="fa pr-3 fa-handshake-o"
                        aria-hidden="true"
                      ></i>
                      Click to Join
                    </button>
                  )}
                  <button
                    onClick={() => {
                      navigate(-1);
                    }}
                    className=" rounded-md bg-[#283a99] hover:bg-[#283a999c] text-white px-5 py-2 "
                  >
                    {" "}
                    <i
                      className="fa pr-3 fa-handshake-o"
                      aria-hidden="true"
                    ></i>
                    Go Back
                  </button>
                </div>
              )}
            </div>

            <span className=" text-gray-600 text-justify max-w-screen-md">
              {campDataItem.description}
            </span>

            {campDataItem.promotion && (
              <div className="flex flex-col gap-2">
                <span className="mt-5 font-bold">Promotion Text</span>
                <div className="flex items-center gap-10">
                  <span>{campDataItem.promotion}</span>
                  <button
                    onClick={() => {
                      handleCopy(campDataItem.promotion);
                    }}
                    className="rounded-md bg-[#283a99] hover:bg-[#283a999c] w-fit p-3 text-white"
                  >
                    Copy Text
                  </button>
                </div>
              </div>
            )}

            {joined && (
              <span className=" mt-5 font-bold">
                Payout Date: {showPayoutDate}
              </span>
            )}

            <span className="mt-10 font-semibold text-lg text-black max-w-screen-md">
              Category
            </span>
            <div
              className={`  border border-[#283a99] bg-slate-100 rounded-xl w-fit p-10 `}
            >
              {campDataItem.category === "Charidy Campaign" ? (
                <img
                  src={category1}
                  alt="categoryImage"
                  className="w-[200] h-[200]"
                />
              ) : campDataItem.category === "Chesed Fund Campaign" ? (
                <img
                  src={category2}
                  alt="CategoryImage"
                  className="w-[200] h-[200]"
                />
              ) : (
                <div className="w-[200px] h-auto">
                  <span className="font-bold text-2xl">Others</span>
                </div>
              )}
            </div>

            <div className="flex lg:flex-row flex-col justify-between pt-10 gap-5">
              <div className=" flex flex-col gap-2">
                <div className="bg-gray-100 shadow-md flex flex-col gap-2 rounded-md sm:p-10 p-5">
                  <span className="text-2xl text-[#283a99] font-semibold">
                    URL Details
                  </span>
                  <span className=" font-normal text-sm text-gray-500">
                    This is your Unique Url to share with others and only
                    activity through this link will be track in your account.
                  </span>
                  {localStorage.getItem("joined") || joined ? (
                    <>
                      {/* <div className="flex sm:flex-row flex-col gap-4 sm:gap-3 justify-between  items-center my-5 ">
                        <span
                          style={{ overflowWrap: "anywhere" }}
                          className="underline"
                        >
                          {campDataItem.category === "Chesed Fund Campaign"
                            ? `${campDataItem.url}?aff=${localStorage.getItem(
                                "affId"
                              )}`
                            : campDataItem.category === "Charidy Campaign"
                            ? `${
                                campDataItem.url
                              }?utm_source=${localStorage.getItem("affId")}`
                            : campDataItem.category === "Others"
                            ? `${campDataItem.url}${
                                campDataItem.tracking
                              }${localStorage.getItem("affId")}`
                            : null}
                        </span>

                        <button
                          onClick={() => {
                            const urlWithAffid =
                              campDataItem.category === "Chesed Fund Campaign"
                                ? `${
                                    campDataItem.url
                                  }?aff=${localStorage.getItem("affId")}`
                                : campDataItem.category === "Charidy Campaign"
                                ? `${
                                    campDataItem.url
                                  }?utm_source=${localStorage.getItem("affId")}`
                                : campDataItem.category === "Others"
                                ? `${campDataItem.url}${
                                    campDataItem.tracking
                                  }${localStorage.getItem("affId")}`
                                : null;
                            if (urlWithAffid) {
                              handleCopy(urlWithAffid);
                            }
                          }}
                          className="rounded-md bg-[#283a99] hover:bg-[#283a999c] sm:w-[25%] w-full p-3 text-white"
                        >
                          Copy URL
                        </button>
                      </div> */}
                      {/* <span className="text-2xl text-[#283a99] font-semibold">
                        Short URL
                      </span> */}
                      <div className="flex sm:flex-row flex-col gap-4 sm:gap-3 justify-between  items-center  ">
                        <span
                          style={{ overflowWrap: "anywhere" }}
                          className="underline"
                        >
                          {tinyUrl}
                        </span>

                        <button
                          onClick={() => {
                            handleCopy(tinyUrl);
                          }}
                          className="rounded-md bg-[#283a99] hover:bg-[#283a999c] sm:w-[25%] w-full p-3 text-white"
                        >
                          Copy URL
                        </button>
                      </div>
                    </>
                  ) : (
                    <div>Join to see the link</div>
                  )}
                </div>

                {/* Commissions Details Here */}

                <div className="bg-gray-100 relative shadow-md flex flex-col gap-2 rounded-md sm:p-10 p-5">
                  <span className="text-2xl text-[#283a99] font-semibold">
                    Commission
                  </span>
                  <span className=" font-normal text-sm text-gray-500">
                    When Somone Donate from your link You will get Percentage of
                    commission from total donation.
                  </span>

                  <div className="flex  items-center justify-around my-5 ">
                    <div className="flex flex-col gap-2">
                      <span className=" underline">Commission</span>
                      <span className="text-[#283a99] text-2xl font-semibold text-center ">
                        {campDataItem.commission}%
                      </span>
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className=" underline">Collections</span>
                      <span className="text-[#283a99] text-2xl font-semibold text-center ">
                        ${useramount}
                      </span>
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className=" underline">Earnings</span>
                      <span className="text-[#283a99] text-2xl font-semibold text-center ">
                        $
                        {Number(
                          (Number(useramount) / 100) * Number(comison)
                        ).toFixed(0)}
                      </span>
                    </div>
                  </div>
                  <span className="absolute bottom-3 font-bold right-5">
                    {campDataItem.category === "Others"
                      ? campDataItem.excelTime
                      : "30 sec ago"}
                  </span>
                </div>
              </div>

              {!localStorage.getItem("home") && (
                <div className=" flex flex-col ">
                  <div className="bg-gray-100 shadow-md flex flex-col justify-center gap-2 rounded-md h-[20vh] sm:p-10 p-5">
                    <span className="text-2xl text-[#283a99] font-semibold">
                      No of clicks
                    </span>
                    <span className="text-xl text-[#000] font-semibold">
                      {clickss}
                    </span>
                  </div>
                  <QrGenerator
                    urlCamp={
                      campDataItem.category === "Chesed Fund Campaign"
                        ? `${campDataItem.url}?aff=${localStorage.getItem(
                            "affId"
                          )}`
                        : campDataItem.category === "Charidy Campaign"
                        ? `${
                            campDataItem.url
                          }?utm_source=${localStorage.getItem("affId")}`
                        : campDataItem.category === "Others"
                        ? `${campDataItem.url}${
                            campDataItem.tracking
                          }${localStorage.getItem("affId")}`
                        : null
                    }
                  />
                </div>
              )}
            </div>

            {campDataItem.video !== "" && (
              <div className="flex flex-col my-10 gap-2">
                <div className="flex items-center">
                  <span className="text-xl font-bold mr-5 text-[#283a99]">
                    Attached Video
                  </span>

                  <a
                    href={campDataItem.video}
                    download={"campaignImage.jpg"}
                    target="_blank"
                  >
                    <div className="flex cursor-pointer transition-all ease-in-out duration-75 hover:scale-110 ">
                      <i
                        style={{ margin: "0px", fontSize: "20px" }}
                        className="fa mr-2 fa-cloud-download"
                        aria-hidden="true"
                      ></i>
                      <span className=" ml-2">Download Video</span>
                    </div>
                  </a>
                </div>
                {campDataItem !== "" && (
                  <video
                    style={{ borderRadius: "10px" }}
                    width="800"
                    height="600"
                    controls
                  >
                    <source src={campDataItem.video} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            )}
          </div>
        </>
      )}
      {successModal && (
        <SuccessModal
          changeNav={changeNav}
          title={"Success"}
          message={"You have successfully joined the campaign"}
          closeModal={() => setSuccessModal(false)}
          setJoined={setJoinedOption}
        />
      )}
      {payoutModel && (
        <HomePayoutModal closeModal={() => setPayoutModel(false)} />
      )}
    </>
  );
};

export default CampaignDetail;
