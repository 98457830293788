import React from "react";

const MessageBox = ({ toogleSelected, selected, index, message }) => {
  return (
    <div
      onClick={() => {
        toogleSelected(index);
      }}
      className="flex px-4 pb-4  cursor-pointer rounded-md bg-[#d3d3d352] pr-10 flex-col"
    >
      <div className="flex justify-between items-center">
        <div className="flex my-5 flex-col">
          <span className="font-bold text-[18px] ">Sender:</span>
          <span className="">{message.email}</span>
        </div>
        <div className="flex items-center gap-5">
          <span>{message.date}</span>
          <i
            className="fa cursor-pointer fa-angle-down text-2xl"
            aria-hidden="true"
          ></i>
        </div>
      </div>
      {selected === index && (
        <div className="flex flex-col">
          <span className="font-bold text-[18px]">Message:</span>
          <span className="max-w-5xl">{message.message}</span>
        </div>
      )}
    </div>
  );
};

export default MessageBox;
