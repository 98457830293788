import {
  getDocs,
  collection,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import { auth, db } from "../../config/FirebaseConfig"; // Assuming you have a 'db' instance exported from '../firebase'

async function Charidytrack({ idToken, campaignUrls, affid }) {
  try {
    // Step 1: Fetch the campaign document
    const q = query(
      collection(db, "campaigns"),
      where("url", "==", campaignUrls)
    );

    const campaignQuery = await getDocs(q);
    const campaignDoc = campaignQuery.docs[0];

    if (!campaignDoc) {
      console.error("Campaign not found.");
      return;
    }

    // Step 2: Find the user with the provided affid in the "joineduser" array
    const joinedUsers = campaignDoc.data().joinedUsers || [];
    const userToUpdate = joinedUsers.find((user) => user.affId === affid);

    if (!userToUpdate) {
      console.error("User not found in joined users.");
      return;
    }

    const charidyFunctionURL = `https://us-central1-jewish-affiliate-network.cloudfunctions.net/charidyFunction?url=${campaignUrls}&utmSource=${affid} `;
    // Step 3: Add the response data to the specific user's array
    const response = await fetch(charidyFunctionURL, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const responseData = await response.json();

      // Add the response data to the user's array

      userToUpdate.earning = responseData;

      // Update the document in Firestore
      await updateDoc(doc(db, "campaigns", campaignDoc.id), {
        joinedUsers: joinedUsers,
      });

      return responseData;
    } else {
      const errorMessage = await response.text();
      console.error("Error:", response.statusText, errorMessage);
      throw new Error(`Error: ${response.statusText}`);
    }
  } catch (error) {
    console.error("An error occurred:", error);
    throw error;
  }
}

export default Charidytrack;
