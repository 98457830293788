import React from "react";

const PointParaItem = ({ points, heading }) => {
  return (
    <div className="flex flex-col gap-4">
      <span className=" font-bold text-[#283a99] text-[22px]">{heading}</span>
      <ul className="list-disc list-inside">
        {points.map((point, i) => (
          <li className="my-4 text-gray-500" key={i}>
            {point}
          </li>
        ))}
        <style jsx>{`
          li::marker {
            color: #283a99;
          }
        `}</style>
      </ul>
    </div>
  );
};

export default PointParaItem;
