import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { getDocs, collection } from "firebase/firestore";
import { db, auth } from "../../config/FirebaseConfig";
import human from "../../assets/images/human.png";
import { useLocation } from "react-router-dom";
import JoinedCampaigns from "../admin/users/JoinedCampaigns";
import ShowCampaigns from "../../superAdmin/components/raisers/ShowCampaigns";
import ShowSuperAdminUserCampaign from "../../superAdmin/components/users/ShowSuperAdminUserCampaign";
export default function ProfileModal({
  closeProfile,
  userData,
  showJoined,
  loading,
  showSuperAdminCampaigns,
  showSuperAdminUserCampaigns,
  org,
}) {
  const [open, setOpen] = useState(true);
  const [showCampaigns, setShowCampaigns] = useState(false);
  const [showRaiserCampaign, setShowRaiserCampaign] = useState(false);
  const [showUserCampaign, setShowUserCampaign] = useState(false);
  const toogleShowCampaign = () => {
    setShowCampaigns(!showCampaigns);
  };

  const toogleShowSuperAdminCampaigns = () => {
    setShowRaiserCampaign(!showRaiserCampaign);
  };

  const toogleshowSuperAdminUserCampaigns = () => {
    setShowUserCampaign(!showUserCampaign);
  };

  const cancelButtonRef = useRef(null);
  const location = useLocation();

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[10000]"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setOpen();
          closeProfile();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl w-full">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-24 sm:w-24">
                      <img src={human} alt="" className=" w-[100%]" />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 w-full ">
                      <Dialog.Title
                        as="h1"
                        className="text-2xl text-center font-semibold text-gray-900"
                      >
                        Account Details
                      </Dialog.Title>
                      <div className="mt-5 flex sm:pl-10 gap-20">
                        {userData.length === 0 ? (
                          <div role="status">
                            <svg
                              aria-hidden="true"
                              className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                              />
                            </svg>
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                          userData.map((user, index) => {
                            return (
                              <div
                                key={index}
                                className="flex flex-col w-full gap-5"
                              >
                                {/* First Name and LAst Name */}
                                {!org && (
                                  <div className="flex sm:flex-row flex-col justify-between sm:gap-20 gap-4">
                                    <div className="flex flex-col w-[100%] items-start gap-3">
                                      <div className="flex items-center gap-2">
                                        <i
                                          style={{ color: "black" }}
                                          className="fa fa-user"
                                          aria-hidden="true"
                                        ></i>
                                        <span className="font-bold text-black">
                                          First Name
                                        </span>
                                      </div>
                                      <span className="text-sm text-gray-600 ">
                                        {user.firstName}
                                      </span>
                                    </div>

                                    <div className="flex flex-col w-[100%] items-start gap-3">
                                      <div className="flex items-center gap-2">
                                        <i
                                          style={{ color: "black" }}
                                          className="fa fa-user"
                                          aria-hidden="true"
                                        ></i>
                                        <span className="font-bold text-black">
                                          Last Name
                                        </span>
                                      </div>
                                      <span className="text-sm text-gray-600 ">
                                        {user.lastName}
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {/* Email And Password */}
                                {!org && (
                                  <div className="flex sm:flex-row flex-col justify-between sm:gap-20 gap-4">
                                    <div className="flex flex-col w-[100%] items-start gap-3">
                                      <div className="flex items-center gap-2">
                                        <i
                                          style={{ color: "black" }}
                                          className="fa fa-envelope"
                                          aria-hidden="true"
                                        ></i>
                                        <span className="font-bold text-black">
                                          Email
                                        </span>
                                      </div>
                                      <span className="text-sm text-gray-600 ">
                                        {user.email}
                                      </span>
                                    </div>

                                    <div className="flex flex-col w-[100%] items-start gap-3">
                                      <div className="flex items-center gap-2">
                                        <i
                                          style={{ color: "black" }}
                                          className="fa fa-lock"
                                          aria-hidden="true"
                                        ></i>
                                        <span className="font-bold text-black">
                                          Password
                                        </span>
                                      </div>
                                      <span className="text-sm text-gray-600 ">
                                        ************
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {/* Phone Number and Payment Methods */}
                                <div className="flex sm:flex-row flex-col justify-between sm:gap-20 gap-4">
                                  {!org ? (
                                    <div className="flex flex-col w-[100%] items-start gap-3">
                                      <div className="flex items-center gap-2">
                                        <i
                                          style={{ color: "black" }}
                                          className="fa fa-phone-square"
                                          aria-hidden="true"
                                        ></i>
                                        <span className="font-bold text-black">
                                          Phone Number
                                        </span>
                                      </div>
                                      <span className="text-sm text-gray-600 ">
                                        {user.phoneNumber}
                                      </span>
                                    </div>
                                  ) : (
                                    <div className="flex flex-col w-[100%] items-start gap-3">
                                      <div className="flex items-center gap-2">
                                        <i
                                          style={{ color: "black" }}
                                          className="fa fa-phone-square"
                                          aria-hidden="true"
                                        ></i>
                                        <span className="font-bold text-black">
                                          Affiliate ID
                                        </span>
                                      </div>
                                      <span className="text-sm text-gray-600 ">
                                        {user.affid}
                                      </span>
                                    </div>
                                  )}

                                  {!org && user.paymentMethod && (
                                    <div className="flex flex-col w-[100%] items-start gap-3">
                                      <div className="flex items-center gap-2">
                                        <i
                                          style={{ color: "black" }}
                                          className="fa fa-paypal"
                                          aria-hidden="true"
                                        ></i>
                                        <span className="font-bold text-black">
                                          Payment Method
                                        </span>
                                      </div>
                                      <span className="text-sm text-gray-600 ">
                                        {user.paymentMethod}
                                      </span>
                                    </div>
                                  )}
                                </div>
                                {/* Account */}
                                {!org && (
                                  <div className="flex sm:flex-row flex-col justify-between sm:gap-20 gap-4">
                                    {user.account && (
                                      <div className="flex flex-col w-[100%] items-start gap-3">
                                        <div className="flex items-center gap-2">
                                          <i
                                            style={{ color: "black" }}
                                            className="fa fa-phone-square"
                                            aria-hidden="true"
                                          ></i>
                                          <span className="font-bold text-black">
                                            Account
                                          </span>
                                        </div>
                                        <span className="text-sm text-gray-600 ">
                                          {user.account}
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                )}

                                {/* State and Zip Code */}

                                {user.state && !org && (
                                  <div className="flex sm:flex-row flex-col justify-between sm:gap-20 gap-4">
                                    <div className="flex flex-col w-[100%] items-start gap-3">
                                      <div className="flex items-center gap-2">
                                        <i
                                          style={{ color: "black" }}
                                          className="fa fa-map-marker"
                                          aria-hidden="true"
                                        ></i>
                                        <span className="font-bold text-black">
                                          State
                                        </span>
                                      </div>
                                      <span className="text-sm text-gray-600 ">
                                        {user.state}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                  {showCampaigns && (
                    <div className="flex flex-col my-5 gap-3">
                      <span className=" font-bold text-black text-xl pl-5 pt-5 border-t border-[lightgray]">
                        {" "}
                        Joined Campaigns
                      </span>

                      <JoinedCampaigns
                        uid={userData[0].uid}
                        affid={userData[0].affid}
                      />
                    </div>
                  )}
                  {showRaiserCampaign && (
                    <div className="flex flex-col my-5 gap-3">
                      <span className=" font-bold text-black text-xl pl-5 pt-5 border-t border-[lightgray]">
                        {" "}
                        Campaigns
                      </span>

                      <ShowCampaigns uid={userData[0].uid} />
                    </div>
                  )}
                  {showUserCampaign && (
                    <div className="flex flex-col my-5 gap-3">
                      <span className=" font-bold text-black text-xl pl-5 pt-5 border-t border-[lightgray]">
                        {" "}
                        Campaigns
                      </span>

                      <ShowSuperAdminUserCampaign uid={userData[0].uid} />
                    </div>
                  )}
                </div>
                <div className="bg-gray-50 gap-5 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="mt-3 bg-[#283a99] hover:bg-[#283a999c] inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white border-none ring-0 outline-none sm:mt-0 sm:w-[20%]"
                    onClick={() => {
                      setOpen(false);
                      closeProfile();
                    }}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>
                  {showJoined && (
                    <button
                      type="button"
                      className="mt-3 bg-[#283a99] hover:bg-[#283a999c] inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white border-none ring-0 outline-none sm:mt-0 sm:w-[30%] "
                      onClick={toogleShowCampaign}
                      ref={cancelButtonRef}
                    >
                      {showCampaigns ? "Hide" : "Show"} Joined Campaigns
                    </button>
                  )}

                  {showSuperAdminCampaigns && (
                    <button
                      type="button"
                      className="mt-3 bg-[#283a99] hover:bg-[#283a999c] inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white border-none  ring-0 outline-none sm:mt-0 sm:w-[30%] "
                      onClick={toogleShowSuperAdminCampaigns}
                      ref={cancelButtonRef}
                    >
                      {showRaiserCampaign || showSuperAdminUserCampaigns
                        ? "Hide"
                        : "Show"}{" "}
                      Campaigns
                    </button>
                  )}

                  {showSuperAdminUserCampaigns && (
                    <button
                      type="button"
                      className="mt-3 bg-[#283a99] hover:bg-[#283a999c] inline-flex w-full justify-center rounded-md  px-3 py-2 text-sm font-semibold text-white border-none  ring-0 outline-none sm:mt-0 sm:w-[30%] "
                      onClick={toogleshowSuperAdminUserCampaigns}
                      ref={cancelButtonRef}
                    >
                      {showUserCampaign ? "Hide" : "Show"} Campaigns
                    </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
