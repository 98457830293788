import React from 'react'
import { useNavigate } from 'react-router-dom';

const AdminRaiserBox = ({ heading, para, number, color, bgColor, count }) => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col gap-2 bg-white shadow-md p-5 rounded-xl">
      <span className="text-black font-semibold text-xl ">Total {heading}</span>

      <span className=" text-sm font-normal text-gray-600">{para}</span>

      <div className="flex sm:flex-row flex-col text-[18px] gap-5">
        <span>Total Active {heading}: </span>
        <span className={`text-[${color}] font-bold`}>{count}</span>
      </div>

      <div className="flex justify-end">
        <button
          onClick={() => {
            navigate('/superadmin/raisers')
          }}
          className={` bg-[${color}] hover:bg-[${bgColor}] px-3 py-2 rounded-md text-white`}
        >
          View Detail
        </button>
      </div>
    </div>

  )
}

export default AdminRaiserBox
