import React from "react";
import organization from "../../../assets/images/organize.jpg";
import StrategyBox from "./StrategyBox";
import fund from "../../../assets/images/fundraiser.png";
import { motion } from "framer-motion";
import blur from "../../../assets/images/blur.jpg";
import stats from "../../../assets/Animation - 1703787843382.json";
import animated2 from "../../../assets/Animation - 1703964896339.json";
const WorkStrategy = () => {
  return (
    <div className="flex flex-col relative gap-5 xl:max-w-[88rem] px-8 py-10 m-auto">
      <span className="text-4xl font-bold text-[#283a99] text-center mt-10">
        Streamlining Connections - A Win-Win for Both Parties
      </span>
      <span className="xl:max-w-6xl m-auto text-center text-gray-600 mb-10">
        Our platform streamlines collaboration between organizations and
        advertisers. It offers an easy-to-use interface for managing campaigns
        and enables advertisers to promote various causes while earning income.
        Experience efficient fundraising and advertising synergy with us.
      </span>
      {!localStorage.getItem("auth") && (
        <div className="flex items-center justify-center mt-0 my-7">
          <video
            style={{ borderRadius: "10px" }}
            width="800"
            height="600"
            preload="auto"
            controls
            poster="https://firebasestorage.googleapis.com/v0/b/jewish-affiliate-network.appspot.com/o/Screenshot%202024-02-13%20233839.png?alt=media&token=8cd1935c-fab5-4bb1-ada1-fd4ac80f0e99"
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/jewish-affiliate-network.appspot.com/o/WhatsApp%20Video%202024-02-13%20at%2011.23.24%20PM.mp4?alt=media&token=5ca702df-36eb-44b8-8b48-f4ba9732c5df"
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.7, ease: "easeInOut", delay: 0 }}
      >
        <StrategyBox
          rounded
          reverse
          heading="Organizations"
          subheading={"Amplify Your Mission"}
          para1={
            "Organizations, leverage the ease of our platform to elevate your cause. Submit your campaign details to our site for a quick review and approval. Upon approval, we alert our network of dedicated advertisers about your campaign, opening doors to increased exposure and support."
          }
          para2={
            "Our streamlined process ensures your cause receives the attention it needs. Utilize our extensive network of motivated advertisers to propel your mission forward."
          }
          image={stats}
        />
      </motion.div>
      <img
        style={{
          top: "18%",
          mixBlendMode: "multiply",
          left: "27%",
          width: "55%",
          filter: "blur(5px)",
        }}
        src={blur}
        alt="blur"
        className="absolute z-0 xl:block hidden "
      />
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.7, ease: "easeInOut", delay: 0.1 }}
        className="mt-10"
      >
        <StrategyBox
          rounded
          heading="Advertisers"
          subheading={"Make an Impact and Earn"}
          para1={
            "Advertisers, seize the opportunity to support diverse and meaningful campaigns. Select from a range of causes, access the necessary media materials, create compelling promotional content, and share it across your chosen social media platforms. Your earnings begin as soon as donations are made through your links, allowing you to drive positive change and profit simultaneously"
          }
          para2={
            "By participating in these campaigns, you contribute to significant causes while enhancing your income. Use the power of social media to champion the campaigns you believe in, and together, let's forge a better world."
          }
          image={animated2}
        />
      </motion.div>
    </div>
  );
};

export default WorkStrategy;
