import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { db, auth } from "../../../config/FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import Charidy from "../../backend/charidy";
import Chesedfund from "../../backend/chesedfund";

const JoinedCampaigns = ({ uid, affid }) => {
  useEffect(() => {
    getJoinedData();
  }, []);
  const [joinedData, setJoinedData] = useState([]);
  const getJoinedData = async () => {
    let dataArr = [];

    await getDocs(collection(db, "campaigns")).then((querySnapshot) => {
      if (localStorage.getItem("uid")) {
        querySnapshot.forEach((doc) => {
          const {
            campId,
            commission,
            description,
            image,
            isApproved,
            joinedUsers,
            name,
            raisId,
            url,
            category,
          } = doc.data();
          joinedUsers.forEach((usr) => {
            if (usr.uid === uid && raisId === localStorage.getItem("uid")) {
              dataArr.push({
                campId,
                commission,
                description,
                image,
                isApproved,
                joinedUsers,
                name,
                raisId,
                url,
                category,
              });
            }
          });
        });
      } else {
        querySnapshot.forEach((doc) => {
          const {
            campId,
            commission,
            description,
            image,
            isApproved,
            joinedUsers,
            name,
            raisId,
            url,
            category,
          } = doc.data();
          joinedUsers.forEach((usr) => {
            if (usr.uid === uid) {
              dataArr.push({
                campId,
                commission,
                description,
                image,
                isApproved,
                joinedUsers,
                name,
                raisId,
                url,
                category,
              });
            }
          });
        });
      }
    });
    setJoinedData(dataArr);
    //   querySnapshot.forEach((doc) => {
    //     const { campId, commission, description, image, isApproved, joinedUsers, name, raisId, url } = doc.data();
    //     if (joinedUsers.includes(auth.currentUser.uid)) {
    //       ar.push({ campId, commission, description, image, isApproved, joinedUsers, name, raisId, url })
    //     }
    //   });
    // });
    // setJoinedData(ar)
  };
  return (
    <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table class="w-full text-sm text-center  text-gray-500 dark:text-gray-400">
        <thead class="text-xs text-white uppercase bg-[#283a99] ">
          <tr>
            <th scope="col" class="">
              #
            </th>
            <th scope="col" class="px-6 py-3">
              Id
            </th>
            <th scope="col" class="px-6 py-3">
              Name
            </th>
            <th scope="col" class="px-6 py-3">
              Funds Raised
            </th>
            <th scope="col" class="px-6 py-3">
              Commission
            </th>
          </tr>
        </thead>
        <tbody>
          {joinedData.map((camp, index) => {
            return (
              <tr
                key={index}
                class={`bg-white border-b ${
                  index % 2 !== 0 && "bg-[#fee5f78a]"
                } `}
              >
                <th
                  scope="row"
                  class=" font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {index + 1}
                </th>
                <td scope="row" class="px-6 py-3 ">
                  {camp.campId}
                </td>
                <td class="px-6 py-3">{camp.name}</td>
                <td className="px-6 py-3">
                  {(() => {
                    const foundUser = camp.joinedUsers.find(
                      (joinedUser) => joinedUser.uid === uid
                    );

                    if (foundUser) {
                      return `$${foundUser.amount}`;
                    } else {
                      return "$0";
                    }
                  })()}
                </td>
                <td className="px-6  py-3">
                  {(() => {
                    const foundUser = camp.joinedUsers.find(
                      (joinedUser) => joinedUser.uid === uid
                    );

                    if (foundUser) {
                      return `$${Number(
                        (Number(foundUser.amount) / 100) *
                          Number(camp.commission)
                      ).toFixed(0)}`;
                    } else {
                      return "$0";
                    }
                  })()}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

// function CalculatedSumz({
//   campaignUrlp,
//   campemail,
//   camppass,
//   returnEarn,
//   commission,
// }) {
//   const [calculatedSum, setCalculatedSum] = useState(null);
//   const location = useLocation();
//   useEffect(() => {
//     const storedSum = localStorage.getItem(`${campaignUrlp}`);
//     if (storedSum !== null) {
//       setCalculatedSum(parseFloat(storedSum));
//     }
//     const lastUpdated = localStorage.getItem(`${campaignUrlp}_lastUpdated`);
//     const currentTime = new Date().getTime();
//     const timeDifference = currentTime - parseInt(lastUpdated);

//     // Recalculate sum if more than 14 days have passed
//     if (!lastUpdated || timeDifference > 8 * 60 * 1000) {
//       calculateSum();
//     }
//   }, [campaignUrlp]);

//   async function loginUser() {
//     const apiKey = "AIzaSyAwCT67aajlOvjC1rOM2WzJWS6VYIJWQmw";
//     const loginEndpoint =
//       "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=" +
//       apiKey;

//     const credentials = {
//       email: campemail,
//       password: camppass,
//       returnSecureToken: true,
//     };
//     // console.log(campemail);
//     try {
//       const response = await fetch(loginEndpoint, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(credentials),
//       });

//       if (response.ok) {
//         const data = await response.json();
//         const accessToken = data.idToken;
//         // console.log("Login successful. Access Token:", data.idToken);
//         return accessToken;
//       } else {
//         const errorData = await response.json();
//         console.error("Login failed:", errorData.error.message);
//       }
//     } catch (error) {
//       console.error("An error occurred:", error);
//     }
//   }

//   const calculateSum = async () => {
//     try {
//       const url = new URL(`${campaignUrlp}`);
//       const pathnameParts = url.pathname.split("/");
//       const organization = pathnameParts[1];
//       const affiliate = url.searchParams.get("aff");

//       const authToken = await loginUser();
//       // Fetch the campaign HTML data with the provided authToken
//       const response = await fetch(`${campaignUrlp}`, {
//         method: "GET",
//         headers: {
//           "Access-Token": `${authToken}`,
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Network response was not ok (${response.status})`);
//       }
//       // console.log(response);
//       // const response = await fetch(`${campaignUrls}`);
//       const html = await response.text();

//       const jsonStart = html.indexOf('{"props":');
//       const jsonEnd = html.lastIndexOf("}</script>") + 1;
//       const jsonString = html.substring(jsonStart, jsonEnd);
//       const jsonData = JSON.parse(jsonString);

//       const extractedCampaignId =
//         jsonData.props.initialState.donationActiveCause.id;

//       const newURL = `https://us-central1-tcf-backend-prod.cloudfunctions.net/donations/${organization}/${extractedCampaignId}/aff/${affiliate}?per_page=-1`;

//       const transformedResponse = await fetch(newURL, {
//         method: "GET",
//         headers: {
//           "Access-Token": `${authToken}`,
//         },
//       });

//       if (!transformedResponse.ok) {
//         throw new Error(
//           `Network response was not ok (${transformedResponse.status})`
//         );
//       }
//       const transformedData = await transformedResponse.json();

//       const sumCents = transformedData.reduce(
//         (sum, entry) => sum + entry.dest_effective_amount_cents,
//         0
//       );
//       const sumDollars = sumCents / 100;
//       localStorage.setItem(`${campaignUrlp}`, sumDollars);
//       localStorage.setItem(`${campaignUrlp}_lastUpdated`, new Date().getTime());

//       return sumDollars;
//     } catch (error) {
//       console.error("Error calculating sum:", error);
//       return 0;
//     }
//   };
//   const earn =
//     calculatedSum !== null
//       ? ((calculatedSum / 100) * commission).toFixed(2)
//       : "...";
//   const tot = calculatedSum !== null ? calculatedSum.toFixed(2) : "...";
//   return returnEarn ? earn : tot;
// }

export default JoinedCampaigns;
