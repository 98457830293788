import React, { useState } from "react";
import reset from "../../../assets/images/reset.svg";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const navigate = useNavigate();

  return (
    <div className=" md:relative md:h-screen md:overflow-hidden md:flex-row flex-col-reverse flex">
      <div className="sm:flex hidden md:w-[30%] overflow-visible bg-[#efebff] w-full items-center justify-center">
        <img
          src={reset}
          alt="forgetPassword"
          className="md:absolute md:w-[40%] md:left-5"
        />
      </div>

      <div className="flex h-screen bg-[#283a99] md:w-[70%] w-100 p-10 items-center justify-center">
        <div className="flex md:w-[45%] gap-5 flex-col">
          <div className="flex gap-4 justify-between items-center">
            <span className="md:text-4xl text-3xl font-bold text-white">
              Reset Password?{" "}
            </span>
            <i
              onClick={() => {
                navigate("/", { replace: true });
              }}
              style={{ fontSize: "30px", cursor: "pointer", color: "white" }}
              className="fa fa-home"
              aria-hidden="true"
            ></i>
          </div>
          <img src={reset} alt="forgetPassword" className=" sm:hidden block" />

          <div className="relative ">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
              <i
                style={{ color: "white", fontSize: "20px" }}
                className="fa fa-lock"
                aria-hidden="true"
              ></i>
            </div>
            <input
              type="password"
              id="input-group-1"
              className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg  block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
              placeholder="New Password"
            />
          </div>

          <div className="relative ">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
              <i
                style={{ color: "white", fontSize: "20px" }}
                className="fa fa-lock"
                aria-hidden="true"
              ></i>
            </div>
            <input
              type="password"
              id="input-group-1"
              className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg  block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
              placeholder="Confirm Password"
            />
          </div>
          <button className="p-3 mt-10 text-[#283a99] bg-white text-md font-bold hover:scale-[1.1] cursor-pointer transition-transform duration-500 ease-in-out rounded-xl">
            Reset
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
