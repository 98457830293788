import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ProfileModal from "../../modals/ProfileModal";
import Charidy from "../../backend/charidy";
import Chesedfund from "../../backend/chesedfund";

const DonationTrackTable = ({ joinedUsers, campData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [showProfileData, setShowProfileData] = useState([]);
  useEffect(() => {}, []);
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <div class="relative my-5  w-full ">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            class="w-4 h-4 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          class="block w-full p-4 pl-10 text-sm text-gray-900 focus:outline-none border border-gray-300 rounded-lg bg-gray-50"
          placeholder="Search User ..."
          required
        />
        <button
          type="submit"
          class="text-white absolute right-2.5 bottom-2.5 bg-[#283a99] hover:bg-[#283a999c] focus:outline-none  font-medium rounded-lg text-sm px-4 py-2"
        >
          Search
        </button>
      </div>
      <table className="w-full text-sm text-center  text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-white uppercase bg-[#283a99] ">
          <tr>
            <th scope="col" className="">
              #
            </th>
            <th scope="col" className="px-6 py-3">
              Affiliate Id
            </th>

            <th scope="col" className="px-6 py-3">
              Funds Raised
            </th>
            <th scope="col" className="px-6 py-3">
              Commission
            </th>
            <th scope="col" className="pl-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {joinedUsers.length === 0 ? (
            <div className=" flex items-center justify-center my-5 mx-auto self-center text-center">
              <span>No Active Members</span>
            </div>
          ) : (
            joinedUsers.map((user, index) => {
              return (
                <tr
                  key={index}
                  className={`bg-white border-b ${
                    index % 2 !== 0 && "bg-[#fbceee8a]"
                  } `}
                >
                  <th
                    scope="row"
                    className=" font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </th>
                  <td scope="row" className="px-6 py-3 ">
                    {user.affid}
                  </td>
                  <td className="px-6 py-3">
                    {(() => {
                      const foundUser = campData.joinedUsers.find(
                        (joinedUser) => joinedUser.uid === user.uid
                      );

                      if (foundUser) {
                        return `$${foundUser.amount}`;
                      } else {
                        return "$0";
                      }
                    })()}
                  </td>
                  <td className="px-6  py-3">
                    {(() => {
                      const foundUser = campData.joinedUsers.find(
                        (joinedUser) => joinedUser.uid === user.uid
                      );

                      if (foundUser) {
                        return `$${Number(
                          (Number(foundUser.amount) / 100) *
                            Number(campData.commission)
                        ).toFixed(0)}`;
                      } else {
                        return "$0";
                      }
                    })()}
                  </td>
                  <td className="pl-6  py-3">
                    <button
                      onClick={() => {
                        setOpenModal(true);
                        setShowProfileData([user]);
                      }}
                      className=" bg-[#283a99] hover:bg-[#283a999c] px-2 py-1 rounded-md text-white"
                    >
                      Details
                    </button>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      {openModal && (
        <ProfileModal
          org
          userData={showProfileData}
          closeProfile={() => {
            setOpenModal(false);
          }}
        />
      )}
    </div>
  );
};
// function CalculatedSums({
//   campaignUrls,
//   campemail,
//   camppass,
//   returnEarn,
//   commission,
// }) {
//   const [calculatedSum, setCalculatedSum] = useState(null);
//   const location = useLocation();
//   useEffect(() => {
//     const storedSum = localStorage.getItem(`${campaignUrls}`);
//     if (storedSum !== null) {
//       setCalculatedSum(parseFloat(storedSum));
//     }
//     const lastUpdated = localStorage.getItem(`${campaignUrls}_lastUpdated`);
//     const currentTime = new Date().getTime();
//     const timeDifference = currentTime - parseInt(lastUpdated);

//     // Recalculate sum if more than 14 days have passed
//     if (!lastUpdated || timeDifference > 8 * 60 * 1000) {
//       calculateSum();
//     }
//   }, [campaignUrls]);

//   async function loginUser() {
//     const apiKey = "AIzaSyAwCT67aajlOvjC1rOM2WzJWS6VYIJWQmw";
//     const loginEndpoint =
//       "https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=" +
//       apiKey;

//     const credentials = {
//       email: campemail,
//       password: camppass,
//       returnSecureToken: true,
//     };
//     // console.log(campemail);
//     try {
//       const response = await fetch(loginEndpoint, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(credentials),
//       });

//       if (response.ok) {
//         const data = await response.json();
//         const accessToken = data.idToken;
//         // console.log("Login successful. Access Token:", data.idToken);
//         return accessToken;
//       } else {
//         const errorData = await response.json();
//         console.error("Login failed:", errorData.error.message);
//       }
//     } catch (error) {
//       console.error("An error occurred:", error);
//     }
//   }

//   const calculateSum = async () => {
//     try {
//       const url = new URL(`${campaignUrls}`);
//       const pathnameParts = url.pathname.split("/");
//       const organization = pathnameParts[1];
//       const affiliate = url.searchParams.get("aff");

//       const authToken = await loginUser();
//       // Fetch the campaign HTML data with the provided authToken
//       const response = await fetch(`${campaignUrls}`, {
//         method: "GET",
//         headers: {
//           "Access-Token": `${authToken}`,
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Network response was not ok (${response.status})`);
//       }
//       // console.log(response);
//       // const response = await fetch(`${campaignUrls}`);
//       const html = await response.text();

//       const jsonStart = html.indexOf('{"props":');
//       const jsonEnd = html.lastIndexOf("}</script>") + 1;
//       const jsonString = html.substring(jsonStart, jsonEnd);
//       const jsonData = JSON.parse(jsonString);

//       const extractedCampaignId =
//         jsonData.props.initialState.donationActiveCause.id;

//       const newURL = `https://us-central1-tcf-backend-prod.cloudfunctions.net/donations/${organization}/${extractedCampaignId}/aff/${affiliate}?per_page=-1`;

//       const transformedResponse = await fetch(newURL, {
//         method: "GET",
//         headers: {
//           "Access-Token": `${authToken}`,
//         },
//       });

//       if (!transformedResponse.ok) {
//         throw new Error(
//           `Network response was not ok (${transformedResponse.status})`
//         );
//       }
//       const transformedData = await transformedResponse.json();

//       const sumCents = transformedData.reduce(
//         (sum, entry) => sum + entry.dest_effective_amount_cents,
//         0
//       );
//       const sumDollars = sumCents / 100;
//       localStorage.setItem(`${campaignUrls}`, sumDollars);
//       localStorage.setItem(`${campaignUrls}_lastUpdated`, new Date().getTime());
//       return sumDollars;
//     } catch (error) {
//       console.error("Error calculating sum:", error);
//       return 0;
//     }
//   };

//   const earn =
//     calculatedSum !== null
//       ? ((calculatedSum / 100) * commission).toFixed(2)
//       : "...";
//   const tot = calculatedSum !== null ? calculatedSum.toFixed(2) : "...";
//   return returnEarn ? earn : tot;
// }

export default DonationTrackTable;
