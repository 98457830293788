import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth, db } from "../../../config/FirebaseConfig";
import { updateDoc, deleteDoc, doc } from "firebase/firestore";
import ActiveMemberDetails from "../../../components/admin/ActiveMembersDetail";
import CarouselComp from "../../../components/Carousel";
const SuperadminCampDetais = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [campDataItem, setcampDataItem] = useState("");
  const setCampDataItemData = () => {
    const item = JSON.parse(localStorage.getItem("item"));
    setcampDataItem(item);
  };
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    setCampDataItemData();
  }, []);
  const handleApprove = async () => {
    try {
      await updateDoc(doc(db, "campaigns", campDataItem.campId), {
        status: "approved",
      });
      navigate("/superadmin/campaigns");
    } catch (excep) {
      console.log(excep);
    }
  };

  const handleDisapprove = async () => {
    try {
      await updateDoc(doc(db, "campaigns", campDataItem.campId), {
        status: "disapproved",
      });
      navigate("/superadmin/campaigns");
    } catch (excep) {
      console.log(excep);
    }
  };

  const handleDelete = async () => {
    try {
      //Delete a campaign
      const documentRef = doc(db, "campaigns", campDataItem.campId);
      await deleteDoc(documentRef);
      navigate("/superadmin/campaigns");
    } catch (excep) {
      console.log(excep);
    }
  };

  const handleEndCampaign = async () => {
    try {
      const documentRef = doc(db, "campaigns", campDataItem.campId);
      await updateDoc(documentRef, {
        status: "ended",
      });
      navigate("/superadmin/campaigns");
    } catch (excep) {
      console.log(excep);
    }
  };

  return (
    <>
      {loading ? (
        <></>
      ) : (
        <>
          {campDataItem !== "" && <CarouselComp image={campDataItem.image} />}

          <div className=" max-w-[88rem] my-5 mb-10 p-5 m-auto flex flex-col gap-3">
            <div className="flex items-center">
              <span className="border-t-4 border-[#283a99] w-[23%]"></span>
              <i
                style={{ color: "#283a99", fontWeight: "bold" }}
                className="fa fa-ravelry"
                aria-hidden="true"
              ></i>
            </div>
            <div className="flex sm:flex-row flex-col justify-between items-center gap-5">
              <span className=" sm:text-4xl max-w-3xl text-3xl font-bold text-[#283a99] ">
                {campDataItem.name}
              </span>
              <div className=" flex gap-4">
                <button
                  onClick={() => {
                    navigate(-1);
                  }}
                  className=" rounded-md bg-[#283a99] hover:bg-[#283a999c] text-white px-5 py-2 "
                >
                  {" "}
                  <i className="fa pr-3 fa-handshake-o" aria-hidden="true"></i>
                  Go Back
                </button>
                {localStorage.getItem("approved") === "false" && (
                  <button
                    onClick={handleDisapprove}
                    className="bg-[#FF0000] hover:bg-[#ff0000b9] px-3 py-2 text-white text-[17px] rounded-md"
                  >
                    <i className="fa pr-3 fa-times" aria-hidden="true"></i>
                    Disapprove
                  </button>
                )}
                {localStorage.getItem("approved") === "false" && (
                  <button
                    onClick={handleApprove}
                    className="bg-[#3fbf3f] hover:bg-[#3fbf3fbb] px-3  py-2 text-white text-[17px] rounded-md"
                  >
                    <i className="fa pr-3 fa-check" aria-hidden="true"></i>
                    Approve
                  </button>
                )}
                {localStorage.getItem("approved") === "true" &&
                  localStorage.getItem("ended") === "false" && (
                    <button
                      onClick={handleEndCampaign}
                      className="bg-[#3fbf3f] hover:bg-[#3fbf3fbb] px-3  py-2 text-white text-[17px] rounded-md"
                    >
                      <i className="fa pr-3 fa-check" aria-hidden="true"></i>
                      End Campaign
                    </button>
                  )}
                <button
                  onClick={handleDelete}
                  className="bg-[#FF0000] hover:bg-[#ff0000b9] px-3 py-2 text-white text-[17px] rounded-md"
                >
                  <i className="fa pr-3 fa-trash" aria-hidden="true"></i>
                  Delete
                </button>
              </div>
            </div>

            <span className=" text-gray-600 max-w-screen-md">
              {campDataItem.description}
            </span>

            <div className="flex lg:flex-row flex-col justify-between pt-10 gap-5">
              {/* URL Details Here */}

              <div className="bg-gray-100 shadow-md flex flex-col gap-2 rounded-md sm:p-10 p-5">
                <span className="text-2xl text-[#283a99] font-semibold">
                  URL Details
                </span>
                <span className=" font-normal text-sm text-gray-500">
                  This is your Unique Url to share with others and only activity
                  through this link will be track in your account.
                </span>

                <div className="flex sm:flex-row flex-col gap-4 sm:gap-3 justify-between  items-center my-5 ">
                  <span
                    style={{ overflowWrap: "anywhere" }}
                    className=" underline"
                  >
                    {campDataItem.url}
                  </span>
                </div>
              </div>
            </div>
            {campDataItem !== "" && campDataItem.video !== "" && (
              <div className="flex flex-col gap-2">
                <div className="flex items-center">
                  <span className="text-lg font-bold mr-5 text-[#283a99]">
                    Attached Video
                  </span>

                  <a
                    href={campDataItem.video}
                    download={"campaignImage.jpg"}
                    target="_blank"
                  >
                    <div className="flex cursor-pointer transition-all ease-in-out duration-75 hover:scale-110 ">
                      <i
                        style={{ margin: "0px", fontSize: "20px" }}
                        className="fa mr-2 fa-cloud-download"
                        aria-hidden="true"
                      ></i>
                      <span className=" ml-2">Download Video</span>
                    </div>
                  </a>
                </div>
                <video
                  style={{ borderRadius: "10px" }}
                  width="800"
                  height="600"
                  controls
                >
                  <source src={campDataItem.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            )}
            {campDataItem !== "" && (
              <ActiveMemberDetails
                joinedUsers={campDataItem.joinedUsers}
                url={campDataItem.url}
                commission={campDataItem.commission}
                username={campDataItem.username}
                password={campDataItem.password}
                category={campDataItem.category}
                superAdmin
              />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default SuperadminCampDetais;
