import React, { useState, useEffect, useRef } from "react";
import { db } from "../../../config/FirebaseConfig";
import {
  collection,
  getDocs,
  doc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import Loader from "../../../components/loader";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as XLSX from "xlsx"; // Import xlsx library

const PaymentBox = ({
  camp,
  item,
  aff,
  user,
  totalPayment,
  balance,
  earning,
}) => {
  const [paymentData, setPaymentData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getPaymentData();
  }, []);

  const getPaymentData = async () => {
    setLoading(true);
    let paymentsData = [];
    const querySnapshot = await getDocs(collection(db, "payment"));
    querySnapshot.forEach((doc) => {
      if (doc.data().affId === aff) {
        paymentsData.push(doc.data());
      }
    });
    setPaymentData(paymentsData);
    setLoading(false);
  };

  const [payment, setPayment] = useState("");
  const onChange = (e) => {
    setPayment(e.target.value);
  };
  const [submitLoading, setsubmitLoading] = useState(false);
  const handleAddPayment = async () => {
    if (payment === "") {
      return;
    }
    setsubmitLoading(true);
    let amm = 0;

    const today = new Date();
    const formattedDate = `${
      today.getMonth() + 1
    }/${today.getDate()}/${today.getFullYear()}`;
    const docRef = doc(collection(db, "payment"));
    await setDoc(docRef, {
      payoutDate: formattedDate,
      affId: aff,
      amount: payment,
    });
    setPaymentData([
      ...paymentData,
      {
        payoutDate: formattedDate,
        affId: aff,
        amount: payment,
      },
    ]);
    setPayment("");
    setsubmitLoading(false);
    alert("Payment Added");
    window.location.reload();
  };

  const tableRef = useRef(null);

  const downloadPDF = () => {
    const input = tableRef.current;
    html2canvas(input).then((canvas) => {
      const pdf = new jsPDF();
      const imgData = canvas.toDataURL("image/png");

      // Add custom header content
      const headerContent1 = `Payment To: ${item.firstName} ${item.lastName}`;
      const headerContent2 = `Total Payment: ${totalPayment}`;
      const headerContent3 = `Earning: ${user ? earning : item.earning}`;
      const headerContent4 = `Balance: ${balance}`;
      const headerHeight = 50; // Adjust as needed

      pdf.addImage(imgData, "PNG", 0, headerHeight);

      // Set position for table content
      const tableContentPosY = headerHeight + canvas.height * 0.75; // Adjust 0.75 as needed

      // Add custom header text
      pdf.text(headerContent1, 10, 10);
      pdf.text(headerContent2, 10, 20);
      pdf.text(headerContent3, 10, 30);
      pdf.text(headerContent4, 10, 40);

      // Save PDF
      pdf.save("payment_table.pdf");
    });
  };
  const downloadExcel = () => {
    const wsData = [];

    // Add custom header content as additional rows
    wsData.push(["Payment To:", `${item.firstName} ${item.lastName}`]);
    wsData.push(["Total Payment:", totalPayment]);
    wsData.push(["Earning:", user ? earning : item.earning]);
    wsData.push(["Balance:", balance]);

    // Add table headers as a separate row
    wsData.push(["#", "Payout Date", "Amount"]);

    // Convert paymentData to worksheet format
    paymentData.forEach((item, index) => {
      const row = [
        index + 1, // # column
        item.payoutDate,
        item.amount,
        // Add more columns as needed
      ];
      wsData.push(row);
    });

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Payments");
    XLSX.writeFile(wb, "payment_table.xlsx");
  };

  return (
    <div className="mb-10">
      <table
        ref={tableRef}
        className={`${
          user ? "w-[100%]" : "w-[83%]"
        } text-sm text-center  text-gray-500 dark:text-gray-400`}
      >
        <thead className="text-xs text-white uppercase bg-[#283a99] ">
          <tr>
            <th scope="col">#</th>
            <th scope="col" className="px-6 py-3">
              Payout Date
            </th>
            <th scope="col" className="px-6 py-3">
              Payment
            </th>
            <th scope="col" className="px-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <Loader />
          ) : (
            paymentData.map((item, key) => {
              if (item.affId === aff) {
                return (
                  <tr key={key}>
                    <td scope="col">{key + 1}</td>
                    <td scope="col" className="px-6 py-3">
                      {item.payoutDate}
                    </td>
                    <td scope="col" className="px-6 py-3">
                      ${item.amount}
                    </td>
                    <td scope="col" className="px-6 py-3">
                      <button className="bg-[#283a99] rounded-md cursor-default text-white p-2">
                        Paid
                      </button>
                    </td>
                  </tr>
                );
              }
            })
          )}
        </tbody>
      </table>

      <div className="flex gap-5">
        {!user && (
          <button
            onClick={downloadPDF}
            className="bg-[#283a99] rounded-md text-white p-2 mt-4"
          >
            Download PDF
          </button>
        )}
        {user && earning > 0 && (
          <button
            onClick={downloadExcel}
            className="bg-[#283a99] rounded-md text-white p-2 mt-4"
          >
            Download Summary
          </button>
        )}
        {!user && (
          <button
            onClick={downloadExcel}
            className="bg-[#283a99] rounded-md text-white p-2 mt-4"
          >
            Download Exel
          </button>
        )}
      </div>

      {!user && (
        <h1 className=" text-[#283a99] text-2xl mt-5 font-bold">Add Payment</h1>
      )}
      <table className="w-[83%] text-sm text-center  text-gray-500 dark:text-gray-400">
        <tbody>
          {!user && (
            <tr>
              <td scope="col">#</td>
              <td scope="col" className="px-6 py-3">
                {(() => {
                  const today = new Date();
                  const formattedDate = `${
                    today.getMonth() + 1
                  }/${today.getDate()}/${today.getFullYear()}`;
                  return formattedDate;
                })()}
              </td>
              <td scope="col" className="px-6 py-3">
                <input
                  value={payment}
                  onChange={onChange}
                  className="border border-gray-300 focus:outline-none rounded-md p-2"
                  type="text"
                  placeholder="amount"
                />
              </td>
              <td scope="col" className="px-6 py-3">
                {submitLoading ? (
                  <Loader />
                ) : (
                  <button
                    onClick={handleAddPayment}
                    className="bg-[#283a99] rounded-md text-white p-2"
                  >
                    Submit
                  </button>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentBox;
