import React from "react";

const PaymentChoiceBox = ({ heading, para, method, index }) => {
  return (
    <div
      className={`bg-white flex flex-col cursor-pointer p-5 rounded-xl items-center gap-4 shadow-md ${
        method === index ? "border border-[black]" : ""
      } `}
    >
      {/* {icon} */}

      <span className="font-bold text-[18px] text-center">{heading}</span>
      <span className="text-gray-600 text-left">{para}</span>
    </div>
  );
};

export default PaymentChoiceBox;
