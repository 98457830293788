import React, { useState, useEffect } from "react";
import SuperAdminCampaignBox from "./SuperAdminCampaignBox";
import { getDocs, collection } from "firebase/firestore";
import { auth, db } from "../../../config/FirebaseConfig";
import Loading from "../../../components/animations/Loading";
const RejectedCampaigns = ({ option, toogleOption }) => {
  const [campaignData, setCampaignData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [affid, setAffid] = useState(0);
  useEffect(() => {
    getCampaignData();
  }, []);

  const getCampaignData = async () => {
    try {
      setLoading(true);
      const campItem = [];
      const querySnapshot = await getDocs(collection(db, "campaigns"));
      querySnapshot.forEach((doc) => {
        const { status } = doc.data();
        if (status === "disapproved") {
          campItem.push(doc.data());
        }
      });
      setCampaignData(campItem);
      setLoading(false);
    } catch (excep) {
      setLoading(false);
      console.log(excep);
    }
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div
          style={{ fontFamily: "'Baloo 2', cursive" }}
          className=" h-screen overflow-y-scroll fixed top-0 right-0 py-10  2xl:w-[83%] xl:w-[75%] md:w-[69%] sm:w-[90%] w-[85%]  "
        >
          <div className="flex sm:flex-row flex-col gap-4">
            <button
              onClick={() => {
                toogleOption("pending");
              }}
              className={` px-20 rounded-md py-3 ${
                option === "pending" ? "bg-[#283a99]" : "bg-[#283a999c]"
              }  text-white`}
            >
              Pending
            </button>
            <button
              onClick={() => {
                toogleOption("approved");
              }}
              className={` px-20 rounded-md py-3  ${
                option === "approved" ? "bg-[#283a99]" : "bg-[#283a999c]"
              }   text-white `}
            >
              Approved
            </button>
            <button
              onClick={() => {
                toogleOption("rejected");
              }}
              className={` px-20 rounded-md py-3  ${
                option === "rejected" ? "bg-[#283a99]" : "bg-[#283a999c]"
              }   text-white `}
            >
              Rejected
            </button>
          </div>
          <h1 class=" text-black text-[2.3rem] mt-5 text-center">
            Available Campaigns
          </h1>
          {campaignData.length === 0 ? (
            <div className=" flex flex-col my-10 items-center justify-center">
              {" "}
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-handshake-o text-xl text-black"
                aria-hidden="true"
              ></i>
              <span>No Rejected Campaign Data</span>
            </div>
          ) : (
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 my-5 gap-5">
              {campaignData.map((item, index) => {
                return (
                  <SuperAdminCampaignBox
                    rejected
                    getCampaignData={getCampaignData}
                    key={index}
                    item={item}
                    affid={affid}
                  />
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RejectedCampaigns;
