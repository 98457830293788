import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ProfileModal from "../../modals/ProfileModal";
import { getDocs, collection, query, where } from "firebase/firestore";
import Charidy from "../../backend/charidy";
import Chesedfund from "../../backend/chesedfund";
import { db, auth } from "../../../config/FirebaseConfig";
import CheseComponent from "../../backend/chesedcom";
import CharidyComponent from "../../backend/charidycom";

const ActiveMemberDetails = ({
  joinedUsers,
  url,
  commission,
  category,
  username,
  password,
  org,
  superAdmin,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [userData, setUserData] = useState([]);
  const [showDetails, setShowDetails] = useState([]);
  const [token, setToken] = useState("");
  const [useramount, setUserAmount] = useState();

  useEffect(() => {
    getUsersData();
  }, []);

  const getUsersData = async () => {
    const arr = [];
    await getDocs(collection(db, "users")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        //joined users is an array of object with keys of user id
        // console.log("user", joinedUsers, doc.id);

        joinedUsers.forEach((user) => {
          if (user.uid === doc.id) {
            const {
              firstName,
              uid,
              lastName,
              email,
              affid,
              phoneNumber,

              legalName,
              state,
              zipCode,
              city,
              paymentMethod,
              account,
              category,
            } = doc.data();
            arr.push({
              firstName,
              uid,
              lastName,
              email,
              affid,
              phoneNumber,
              zipCode,
              city,
              legalName,
              state,
              paymentMethod,
              account,
              category,
            });
          }
        });
      });
      setUserData(arr);
    });
    // const rs = await handleEarning();
    // console.log("rs", rs);
  };

  const handleEarning = async () => {
    // Firebase listener for authentication state changes

    const user = auth.currentUser;
    if (!user) {
      console.log("User not authenticated.");
      // You might want to handle this case, e.g., redirect to login.
      return;
    }

    try {
      const idToken = await user.getIdToken();
      setToken(idToken);
      const campaignUrl = url;
      let res;

      if (category === "Chesed Fund Campaign") {
        const data = {
          campaignUrls: campaignUrl,
        };
        const response = await fetch(
          `https://us-central1-jewish-affiliate-network.cloudfunctions.net/calculateSum`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        res = await response.json();
      } else if (category === "Charidy Campaign") {
        const response = await fetch(
          `https://us-central1-jewish-affiliate-network.cloudfunctions.net/charidyFunction?url=${campaignUrl}`,
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${idToken}`,
              "Content-Type": "application/json",
            },
          }
        );
        res = await response.json();
      }
      if (res) {
        return res;
      } else {
        return 0;
      }

      // Now you can use the idToken as needed, for example, send it to the server.
    } catch (error) {
      console.error("Error getting idToken:", error);
    }
  };

  const handleShowDetails = (item) => {
    let ar = [];
    ar.push(item);
    setShowDetails(ar);
    // console.log(setShowDetails);
    setOpenModal(true);
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <div class="relative my-5  w-full ">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            class="w-4 h-4 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          class="block w-full p-4 pl-10 text-sm text-gray-900 focus:outline-none border border-gray-300 rounded-lg bg-gray-50"
          placeholder="Search User ..."
          required
        />
        <button
          type="submit"
          class="text-white absolute right-2.5 bottom-2.5 bg-[#283a99] hover:bg-[#283b999c] focus:outline-none  font-medium rounded-lg text-sm px-4 py-2"
        >
          Search
        </button>
      </div>
      <table className="w-full text-sm text-center  text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-white uppercase bg-[#283a99] ">
          <tr>
            <th scope="col" className="">
              #
            </th>
            {!org && (
              <>
                <th scope="col" className="px-6 py-3">
                  User Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
              </>
            )}
            <th scope="col" className="px-6 py-3">
              Affiliate ID
            </th>
            <th scope="col" className="px-6 py-3">
              Fund Raised
            </th>
            <th scope="col" className="px-6 py-3">
              Commission
            </th>
            <th scope="col" className="px-6 py-3">
              Clicks
            </th>
            <th scope="col" className="px-6 py-3">
              Commission
            </th>
            <th scope="col" className="pl-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {userData.length === 0 ? (
            <div className=" flex items-center justify-center my-5 mx-auto self-center text-center">
              <span>No Active Members</span>
            </div>
          ) : (
            userData.map((user, index) => {
              return (
                <tr
                  key={index}
                  className={`bg-white border-b ${
                    index % 2 !== 0 && "bg-[#fbceee8a]"
                  } `}
                >
                  <th
                    scope="row"
                    className=" font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </th>
                  {!org && (
                    <>
                      <td scope="row" className="px-6 py-3 ">
                        {user.firstName + " " + user.lastName}
                      </td>
                      <td className="px-6 py-3">{user.email}</td>
                    </>
                  )}
                  <td className="px-6 py-3">{user.affid}</td>
                  {superAdmin ? (
                    <td className="px-6 py-3">
                      {(() => {
                        const foundUser = joinedUsers.find(
                          (joinedUser) => joinedUser.uid === user.uid
                        );

                        if (foundUser) {
                          if (foundUser.amount) {
                            return `$${foundUser.amount}`;
                          } else {
                            return "$0";
                          }
                        } else {
                          return "$0";
                        }
                      })()}
                    </td>
                  ) : (
                    <td className="px-6 py-3">
                      {(() => {
                        const foundUser = joinedUsers.find(
                          (joinedUser) => joinedUser.uid === user.uid
                        );

                        if (foundUser) {
                          if (foundUser.amount) {
                            return `$${foundUser.amount}`;
                          } else {
                            return "$0";
                          }
                        } else {
                          return "$0";
                        }
                      })()}
                    </td>
                  )}
                  {superAdmin ? (
                    <td className="px-6  py-3">
                      {(() => {
                        const foundUser = joinedUsers.find(
                          (joinedUser) => joinedUser.uid === user.uid
                        );

                        if (foundUser) {
                          if (foundUser.amount) {
                            return `$${Number(
                              (Number(foundUser.amount) / 100) *
                                Number(commission)
                            ).toFixed(0)}`;
                          } else {
                            return "$0";
                          }
                        } else {
                          return "$0";
                        }
                      })()}
                    </td>
                  ) : (
                    <td className="px-6  py-3">
                      {(() => {
                        const foundUser = joinedUsers.find(
                          (joinedUser) => joinedUser.uid === user.uid
                        );

                        if (foundUser) {
                          if (foundUser.amount) {
                            return `$${Number(
                              (Number(foundUser.amount) / 100) *
                                Number(commission)
                            ).toFixed(0)}`;
                          } else {
                            return "$0";
                          }
                        } else {
                          return "$0";
                        }
                      })()}
                    </td>
                  )}
                  <td className="px-6  py-3">
                    {(() => {
                      const foundUser = joinedUsers.find(
                        (joinedUser) => joinedUser.uid === user.uid
                      );

                      if (foundUser) {
                        if (foundUser.clicks) {
                          return foundUser.clicks;
                        } else {
                          return "0";
                        }
                      } else {
                        return "0";
                      }
                    })()}
                  </td>
                  <td className="pl-6  py-3">
                    <button
                      onClick={() => {
                        handleShowDetails(user);
                      }}
                      className=" bg-[#283a99] hover:bg-[#283a999c] px-2 py-1 rounded-md text-white"
                    >
                      Details
                    </button>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      {openModal && (
        <ProfileModal
          org={!superAdmin}
          userData={showDetails}
          showJoined
          closeProfile={() => {
            setOpenModal(false);
          }}
        />
      )}
    </div>
  );
};

export default ActiveMemberDetails;
