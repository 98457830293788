import React, { useState } from "react";
import ProfileModal from "../../../components/modals/ProfileModal";

const RaiserList = ({ raiserData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [showRaiser, setShowRaiser] = useState("");
  return (
    <>
      <table className="w-full text-sm text-center  text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-white uppercase bg-[#283a99] ">
          <tr>
            <th scope="col" className="">
              #
            </th>
            <th scope="col" className="px-6 py-3">
              Fisrt Name
            </th>
            <th scope="col" className="px-6 py-3">
              Last Name
            </th>
            <th scope="col" className="px-6 py-3">
              Email
            </th>
            <th scope="col" className="px-6 py-3">
              Ogranization Id
            </th>
            <th scope="col" className="pl-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {raiserData.length === 0 ? (
            <div className=" flex items-center justify-center my-5 mx-auto self-center text-center">
              <span>No Active Members</span>
            </div>
          ) : (
            raiserData.map((user, index) => {
              return (
                <tr
                  key={index}
                  className={`bg-white border-b ${
                    index % 2 !== 0 && "bg-[#fee5f78a]"
                  } `}
                >
                  <th
                    scope="row"
                    className=" font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </th>
                  <td scope="row" className="px-6 py-3 ">
                    {user.firstName}
                  </td>
                  <td className="px-6 py-3">{user.lastName}</td>
                  <td className="px-6 py-3">{user.email}</td>
                  <td className="px-6 py-3">{user.orgid}</td>
                  <td className="pl-6  py-3">
                    <button
                      onClick={() => {
                        setShowRaiser([user]);
                        setOpenModal(true);
                      }}
                      className=" bg-[#283a99] hover:bg-[#283a999c] px-2 py-1 rounded-md text-white"
                    >
                      Details
                    </button>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      {openModal && (
        <ProfileModal
          showSuperAdminCampaigns
          userData={showRaiser}
          closeProfile={() => {
            setOpenModal(false);
          }}
        />
      )}
    </>
  );
};

export default RaiserList;
