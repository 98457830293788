import React, { useState } from "react";
import campaign from "../../../assets/images/campaign.jpg";
import ApprovedCampaings from "../../components/campaigns/ApprovedCampaings";
import PendingCampaigns from "../../components/campaigns/PendingCampaigns";
import RejectedCampaigns from "../../components/campaigns/RejectedCampaings";
const SuperAdminCampagn = () => {
  const [option, setOption] = useState("pending");
  const toogleOption = (key) => {
    setOption(key);
  }
  return (
    <>


      {option === "pending" && (
        <PendingCampaigns option={option} toogleOption={toogleOption} />
      )}
      {option === "approved" && (
        <ApprovedCampaings option={option} toogleOption={toogleOption} />
      )}
      {option === "rejected" && (
        <RejectedCampaigns option={option} toogleOption={toogleOption} />
      )}
    </>
  );
};

export default SuperAdminCampagn;
