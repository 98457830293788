import React from "react";
import { useNavigate } from "react-router-dom";
import footerImage from "../../assets/images/footerImage.jfif";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className=" relative bg-[#283a99]">
      <div className="xl:max-w-[88rem] m-auto px-8 py-10">
        <div className="grid sm:grid-cols-3  grid-cols-1 gap-10 ">
          <div className="flex flex-col gap-2">
            <span className="font-bold sm:text-3xl text-2xl text-white">
              Jewish Affiliate Network
            </span>

            <span className="text-white ">
              Connecting Organizations with Advertisers
            </span>
            {!localStorage.getItem("auth") && (
              <div className=" my-0 flex flex-col gap-3">
                <span className="text-white text-xl">
                  Log in as Super Admin?{" "}
                </span>
                <button
                  onClick={() => {
                    navigate("superAdminlogin");
                  }}
                  className=" bg-white p-3 text-black w-fit px-10 rounded-lg shadow-xl"
                >
                  Login
                </button>
              </div>
            )}
          </div>
          <div className="flex md:justify-center gap-6 px-10">
            <div className="flex flex-col gap-3">
              <span className="text-white font-bold">About</span>
              <span
                onClick={() => {
                  navigate("/about");
                }}
                className="text-white cursor-pointer"
              >
                About Us
              </span>
              <span
                onClick={() => {
                  navigate("/contact");
                }}
                className="text-white cursor-pointer"
              >
                Contact
              </span>
            </div>
          </div>
          <div className="flex  gap-5">
            {/* <img src={footerImage} className="w-[40%] self-center" alt="" /> */}
            <div className="flex flex-col gap-1">
              <span
                style={{ fontFamily: "'Baloo 2', sans-serif" }}
                className="text-white text-4xl font-bold"
              >
                Designed By
              </span>
              <span
                className="text-white text-[1.7rem] "
                style={{ fontFamily: "'Baloo 2', sans-serif" }}
              >
                Techmunist Ltd
              </span>
              <span className="text-white text-md ">
                Building smarter solutions for a better <br /> tomorrow
              </span>
              <div className="flex mt-3  gap-5">
                <a href="mailto:techmunist@gmail.com">
                  <i
                    style={{
                      fontSize: "28px",
                      cursor: "pointer",
                      color: "white",
                    }}
                    className="fa fa-google"
                    aria-hidden="true"
                  ></i>
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/techmunist-ltd/"
                >
                  <i
                    style={{
                      fontSize: "28px",
                      cursor: "pointer",
                      color: "white",
                    }}
                    className="fa fa-linkedin-square"
                    aria-hidden="true"
                  ></i>
                </a>
                <a href="https://wa.me/message/W2D5JLJWQDH4B1">
                  <i
                    style={{
                      fontSize: "28px",
                      cursor: "pointer",
                      color: "white",
                    }}
                    className="fa fa-whatsapp"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
