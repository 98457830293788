import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import PaymentChoiceBox from "./PaymentChoiceBox";
import help1 from "../../assets/images/help1.jpeg";
import help2 from "../../assets/images/help2.jpeg";
import category1 from "../../assets/images/category1.jpeg";
import category2 from "../../assets/images/category2.jpeg";
import step1 from "../../assets/images/step1.jpeg";
import step2 from "../../assets/images/step2.jpeg";
import step3 from "../../assets/images/step3.jpeg";
import hhlep from "../../assets/images/hhelp.jpeg";
import { auth, db } from "../../config/FirebaseConfig";
import { doc, updateDoc } from "firebase/firestore";
import Loader from "../loader";
export default function AdminPaymentMethod({ closeModal, item, updateDone }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [method, setMethod] = useState(0);
  const [loading, setLoading] = useState(false);
  const toogleMethod = (key) => {
    setMethod(key === 1 ? 1 : key === 2 ? 2 : 3);
  };
  const handleSubmit = async () => {
    if (method === 0) return;
    setLoading(true);
    try {
      const docRef = doc(db, "campaigns", item.campId);
      await updateDoc(docRef, {
        method:
          method === 1 ? "Method 1" : method === 2 ? "Method 2" : "Manual",
      });
      setOpen(false);
      closeModal();
      setLoading(false);
      updateDone();
    } catch (error) {
      console.log(error);
    }
  };

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setOpen();
          closeModal();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-[#d6e1fc] sm:p-10 p-3 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                <div className=" px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full  bg-slate-200 sm:mx-0 sm:h-10 sm:w-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
                        />
                      </svg>
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-[24px] font-semibold  text-[#000713]"
                      >
                        Enable Donation Tracking
                      </Dialog.Title>
                      <div className="mt-2"></div>
                    </div>
                  </div>
                  <div className="flex my-10 flex-col gap-5 ">
                    {item.category !== "Others" && (
                      <>
                        <div
                          onClick={() => {
                            toogleMethod(1);
                          }}
                        >
                          <PaymentChoiceBox
                            method={method}
                            index={1}
                            icon={
                              <i
                                style={{ fontSize: "40px", color: "#283a99" }}
                                className="fa fa-user"
                                aria-hidden="true"
                              ></i>
                            }
                            heading={"Method 1"}
                            para={
                              "Grant Administrative Access to the Jewish Affiliate Network: By assigning administrative rights to the Jewish Affiliate Network on your account, our network gains comprehensive access to your data. Our utilization will be strictly confined to extracting data related to donations facilitated through our affiliate links. While our access extends to all your data, our usage is limited to necessary statistics only. If you prefer not to share your entire dataset, Method 2 might be more suitable"
                            }
                          />
                        </div>
                        <div
                          onClick={() => {
                            toogleMethod(2);
                          }}
                        >
                          <PaymentChoiceBox
                            index={2}
                            method={method}
                            icon={
                              <i
                                style={{ fontSize: "40px", color: "#283a99" }}
                                className="fa fa-sitemap"
                                aria-hidden="true"
                              ></i>
                            }
                            heading={"Method 2"}
                            para={
                              "Selective Data Sharing Guidance: In this method, we will assist you in sharing only the essential data required by our system to track affiliate donation statistics. This process will be executed on your computer, ensuring that the Jewish Affiliate Network does not access any additional data from your account."
                            }
                          />
                        </div>
                      </>
                    )}
                    <div
                      onClick={() => {
                        toogleMethod(3);
                      }}
                    >
                      <PaymentChoiceBox
                        index={3}
                        method={method}
                        icon={
                          <i
                            style={{ fontSize: "40px", color: "#283a99" }}
                            className="fa fa-sitemap"
                            aria-hidden="true"
                          ></i>
                        }
                        heading={"Method 3"}
                        para={"Manual Method"}
                      />
                    </div>
                    {item.category === "Chesed Fund Campaign" &&
                    method === 1 ? (
                      <div className=" flex flex-col gap-3">
                        <div className="flex flex-col gap-2">
                          <span className=" font-bold text-md text-center">
                            Step 1
                          </span>
                          <img
                            src={help2}
                            alt="hwlpingImage"
                            className="w-1/2 self-center"
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className=" font-bold text-md text-center">
                            Step 2
                          </span>
                          <img
                            src={help1}
                            alt="hwlpingImage"
                            className="w-[70%] self-center"
                          />
                        </div>
                      </div>
                    ) : item.category === "Charidy Campaign" && method === 1 ? (
                      <div className=" flex flex-col gap-3">
                        <div className="flex flex-col gap-2">
                          <span className=" font-bold text-md text-center">
                            Step 1
                          </span>
                          <img
                            src={step1}
                            alt="hwlpingImage"
                            className=" self-center"
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className=" font-bold text-md text-center">
                            Step 2
                          </span>
                          <img
                            src={step2}
                            alt="hwlpingImage"
                            className=" self-center"
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <span className=" font-bold text-md text-center">
                            Step 3
                          </span>
                          <img
                            src={hhlep}
                            alt="hwlpingImage"
                            className=" self-center"
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {loading ? (
                  <Loader />
                ) : (
                  <div className=" px-4 py-3 gap-5 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-[#283a99] hover:bg-[#283b99a4] p-3 text-white sm:mt-0 sm:w-auto"
                      onClick={() => {
                        setOpen(false);
                        closeModal();
                      }}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-[#283a99] hover:bg-[#283b99a4] p-3 text-white sm:mt-0 sm:w-auto"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
