import React, { useEffect, useState } from "react";
import mobileImg from "../../../assets/orgsignup.json";
import VerifyEmail from "../../../components/modals/VerifyEmail";
import ErrorModal from "../../../components/modals/ErrorModal";
import GeneralMsgModal from "../../../components/modals/GeneralMsgModal";
import TermsConditions from "../../../components/termsConditions";
import { auth, db } from "../../../config/FirebaseConfig";
import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  setDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import { TermsListAdmin } from "../../../components/admin/temsConditionAdmin/termsListAdmin";
import Loader from "../../../components/loader";
import { States } from "../../../components/states";
import AnimateImg from "../../../components/animations/AnimateImg";
const OrganizationSignup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [authCred, setAuthCred] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    address: "",
    state: "",
    zipCode: "",
    paypalId: "",
  });
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [userCount, setUserCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [verifyModal, setVerifyModal] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [openTermModal, setOpenTermModal] = useState(false);
  const closeVerifyModal = () => {
    setVerifyModal(false);
  };
  useEffect(() => {
    if (localStorage.getItem("auth")) {
      navigate("/");
    }
  }, []);
  const getCount = async () => {
    let ocount = 0;
    const querySnapshot = await getDocs(collection(db, "raiserCount"));
    querySnapshot.forEach((doc) => {
      const { count } = doc.data();
      ocount = count + 1;
      updateCount(count + 1);
    });
    return ocount;
  };
  const updateCount = async (count) => {
    const countDocRef = doc(collection(db, "raiserCount"), "count");
    await updateDoc(countDocRef, { count: count });
  };

  const onChange = (e) => {
    setAuthCred({ ...authCred, [e.target.name]: e.target.value });
  };
  const handleSignup = async (e) => {
    if (
      authCred.firstName === "" ||
      authCred.lastName === "" ||
      authCred.email === "" ||
      authCred.password === "" ||
      authCred.phoneNumber === ""
    ) {
      setErrorModalOpen(true);
      setMessage("Please fill all the fields");
    } else {
      console.log(
        "termsAccepted",
        localStorage.getItem("termsAccepted") === true
      );
      if (localStorage.getItem("termsAccepted") || accepted) {
        setLoading(true);
        const count = await getCount();
        e.preventDefault();
        try {
          const userCred = await createUserWithEmailAndPassword(
            auth,
            authCred.email,
            authCred.password
          );
          const user = userCred.user;
          await sendEmailVerification(user);
          await updateProfile(auth.currentUser, {
            displayName: authCred.firstName + " " + authCred.lastName,
          });
          const userDocRef = doc(collection(db, "raiser"), user.uid);

          const data = {
            orgid: `org${count}`,
            uid: user.uid,
            email: user.email,
            firstName: authCred.firstName,
            lastName: authCred.lastName,
            phoneNumber: authCred.phoneNumber,
            campaigns: [],
            joinedUsers: [],
          };

          await setDoc(userDocRef, data);

          // await updateDoc(doc(db, 'userCount', 'userCount'), { count: userCount + 1 })

          setVerifyModal(true);
          setTimeout(() => {
            setLoading(false);
            localStorage.removeItem("termsAccepted");
            navigate("/orgLogin");
          }, 9000);
        } catch (error) {
          localStorage.removeItem("termsAccepted");
          if (error.code === "auth/email-already-in-use") {
            setMessage(
              "Email address is already in use. Please use a different email."
            );
            setLoading(false);
          } else {
            setMessage(error.message);
            setLoading(false);
          }
          setError(true);
          setLoading(false);
          setErrorModalOpen(true);
        }
      } else {
        setLoading(false);
        setOpenTermModal(true);
      }
    }
  };
  return (
    <div className=" md:h-screen md:overflow-hidden sm:flex-row flex-col-reverse flex gap-5">
      <div className="md:flex hidden md:w-[50%] w-full items-center justify-center">
        {/* <img src={mobileImg} alt="" /> */}
        <AnimateImg json={mobileImg} width={"100%"} height={"100%"} />
      </div>
      <div className="flex flex-col gap-4  md:w-[90%] w-full lg:px-40 md:px-20 px-9 py-16 md:py-0 text-white  justify-center bg-[#283a99]">
        <span className="md:text-4xl text-center text-2xl font-bold mb-5">
          ORGANIZATION SIGN UP
        </span>
        <div className="flex justify-between items-center">
          <span className="md:text-3xl text-2xl font-bold">Create Account</span>
          <i
            onClick={() => {
              navigate("/", { replace: true });
            }}
            style={{ fontSize: "30px", cursor: "pointer", color: "white" }}
            className="fa fa-home"
            aria-hidden="true"
          ></i>
        </div>
        <div className=" mx-auto w-auto  md:hidden flex">
          <AnimateImg json={mobileImg} width={"100%"} height={"100%"} />
        </div>

        {/* First Name and LAst Name */}
        <div className="flex sm:flex-row flex-col justify-between gap-4">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
              <i
                style={{ color: "white", fontSize: "20px" }}
                className="fa fa-user"
                aria-hidden="true"
              ></i>
            </div>
            <input
              onChange={onChange}
              name="firstName"
              value={authCred.firstName}
              type="text"
              id="input-group-1"
              className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg focus:ring-0 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
              placeholder="First Name"
              required={true}
            />
          </div>

          <div className="relative w-full ">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
              <i
                style={{ color: "white", fontSize: "20px" }}
                className="fa fa-lock"
                aria-hidden="true"
              ></i>
            </div>
            <input
              onChange={onChange}
              name="lastName"
              value={authCred.lastName}
              type="test"
              id="input-group-2"
              className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg  block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
              placeholder="Last Name"
              required={true}
            />
          </div>
        </div>

        {/* Email Password */}
        <div className="flex sm:flex-row flex-col justify-between gap-4">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
              <i
                style={{ color: "white", fontSize: "20px" }}
                className="fa fa-envelope"
                aria-hidden="true"
              ></i>
            </div>
            <input
              onChange={onChange}
              name="email"
              value={authCred.email}
              type="email"
              id="input-group-3"
              className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg focus:ring-0 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
              placeholder="name@flowbite.com"
              required={true}
            />
          </div>

          <div className="relative w-full ">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
              <i
                style={{ color: "white", fontSize: "20px" }}
                className="fa fa-lock"
                aria-hidden="true"
              ></i>
            </div>
            <input
              onChange={onChange}
              name="password"
              value={authCred.password}
              type="password"
              id="input-group-4"
              className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg  block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
              placeholder="**********"
              required={true}
            />
          </div>
        </div>
        {/* Phone Number and Paypal Id */}

        <div className="flex sm:flex-row flex-col justify-between gap-4">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
              <i
                style={{ color: "white", fontSize: "20px" }}
                className="fa fa-phone-square"
                aria-hidden="true"
              ></i>
            </div>
            <input
              onChange={onChange}
              value={authCred.phoneNumber}
              name="phoneNumber"
              type="text"
              id="input-group-5"
              className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg focus:ring-0 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
              placeholder="Phone Number"
            />
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <button
            onClick={handleSignup}
            className="p-3 bg-white md:w-[70%] w-[100%] mx-auto text-[#283a99] text-md font-bold hover:scale-[1.1] cursor-pointer transition-transform duration-500 ease-in-out rounded-xl"
          >
            Signup
          </button>
        )}

        <span className="mt-5 ">
          Already have and account?{" "}
          <span
            onClick={() => {
              navigate("/orgLogin");
            }}
            className="cursor-pointer hover:text-slate-300 underline "
          >
            Login
          </span>{" "}
        </span>
        {errorModalOpen && (
          <ErrorModal
            errorMessage={message}
            closeModal={() => setErrorModalOpen(false)}
          />
        )}
      </div>

      {verifyModal && <VerifyEmail closeVerifyModal={closeVerifyModal} />}
      {openTermModal && (
        <TermsConditions
          termsList={TermsListAdmin}
          visible={openTermModal}
          handleAccept={() => {
            setAccepted(true);
            localStorage.setItem("termsAccepted", true);
            setOpenTermModal(false);
          }}
          closeModal={() => {
            setOpenTermModal(false);
          }}
        />
      )}
    </div>
  );
};

export default OrganizationSignup;
