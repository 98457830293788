import Lottie from "react-lottie-player";

import React from "react";

const AnimateImg = ({ json, width, height }) => {
  return (
    <Lottie
      loop={true}
      autoPlay={true}
      animationData={json}
      play
      style={{ width: width, height: height }}
    />
  );
};

export default AnimateImg;
