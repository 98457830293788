import React, { useEffect, useState } from "react";
import CampaignCard from "../../../components/admin/campaigns/CampaignCard";
import DonationTrackingCard from "../../../components/admin/donationTracking/DonationTrackingCard";
import { auth, db } from "../../../config/FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
const DonationTracking = () => {
  useEffect(() => {
    getCampaignsData();
  }, []);
  const [campData, setCampData] = useState([]);
  const getCampaignsData = async () => {
    let ar = [];
    await getDocs(collection(db, "campaigns")).then((querynapshot) => {
      querynapshot.forEach((doc) => {
        const { raisId } = doc.data();
        if (raisId === auth.currentUser.uid) {
          ar.push(doc.data());
        }
      });
    });
    setCampData(ar);
  };
  return (
    <div
      style={{ fontFamily: "'Baloo 2', cursive" }}
      className=" h-screen overflow-y-scroll fixed top-0 right-0 py-10  2xl:w-[80%] xl:w-[75%] md:w-[70%] sm:w-[90%] w-[80%]  "
    >
      <div className="flex flex-col">
        <span className=" lg:pl-5 pl-10 md:text-4xl sm:text-3xl text-2xl font-bold text-shadow-md text-[#283a99]">
          Donation Tracking
        </span>

        <div className="grid 2xl:grid-cols-3 xl:grid-cols-2 grid-cols-1 lg:pr-10 sm:pr-0 pr-5 lg:px-0 sm:px-10 my-5 gap-5">
          {campData.map((item, index) => {
            return (
              <DonationTrackingCard
                campData={item}
                count={item.joinedUsers.length}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DonationTracking;
