import React, { useEffect, useState } from "react";
import AdminCampaignBox from "../../../components/admin/dashboardCollection/AdminCampaignBox";
import admindash from "../../../assets/images/adminDash.jpg";
// import { PieChart } from 'react-minimal-pie-chart';
import DashboardGraph from "../../../components/admin/dashboardCollection/DashboardGraph";
import PieChart from "../../../components/admin/dashboardCollection/PieChart";
import { getDocs, collection, updateDoc, doc } from "firebase/firestore";
import { auth, db } from "../../../config/FirebaseConfig";
import Loader from "../../../components/loader";
const AdminHome = () => {
  const [membersCount, setMembersCount] = useState("-");
  const [campaignsCount, setCampaignsCount] = useState("-");
  const [campaignData, setCampaignData] = useState([]);
  const [yearsData, setYearsData] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getCampaigns();
    getMembers();
  }, []);
  const getCampaigns = async () => {
    let count = 0;
    let graphData = [];
    let camps = [];
    setLoading(true);
    await getDocs(collection(db, "campaigns")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const { raisId, joinedUsers } = doc.data();
        if (raisId === auth.currentUser.uid) {
          count++;
          camps.push(doc.data());

          joinedUsers.forEach((item) => {
            const year = item.date.split("-")[2];
            const month = parseInt(item.date.split("-")[1]);

            const existingData = graphData.find(
              (data) => data.year === year && data.month === month
            );

            if (existingData) {
              existingData.count += 1;
            } else {
              graphData.push({ year, month, count: 1 });
            }
          });
        }
      });

      setYearsData(graphData);
      setCampaignsCount(count);
      setCampaignData(camps);
      console.log(graphData);
      setLoading(false);
    });
  };

  const getMembers = async () => {
    await getDocs(collection(db, "raiser")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (auth.currentUser.uid === doc.id) {
          const { campaigns } = doc.data();

          const lenghtss = doc.data().joinedUsers.length;
          setMembersCount(lenghtss);
        }
      });
    });
  };
  return (
    <div
      style={{ fontFamily: "'Baloo 2', cursive" }}
      className=" h-screen overflow-y-scroll fixed top-0 right-0 py-10  2xl:w-[80%] xl:w-[75%] md:w-[69%] sm:w-[90%] w-[85%]  "
    >
      <div className="flex flex-col">
        <span className=" pl-5 md:text-4xl sm:text-3xl text-2xl font-bold text-shadow-md text-[#283a99]">
          Organization Dashboard
        </span>
        {/* <img src={admindash} alt="" className=' w-1/2 m-auto pr-12' /> */}
        <div className="  my-10">
          {/* <PieChart campData={campaignData} /> */}

          {loading ? <Loader /> : <DashboardGraph yearsData={yearsData} />}
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 lg:pr-12 lg:px-0 px-10">
          <AdminCampaignBox
            count={campaignsCount}
            heading={"Campaign"}
            para={
              "Get a bird's-eye view of all active campaigns on your admin portal. Track, manage, and optimize each campaign's performance with ease, ensuring your fundraising efforts are always on target"
            }
            number={15}
            color="#283a99"
            bgColor="#283a999c"
          />
          <AdminCampaignBox
            count={membersCount}
            heading={"Members"}
            para={
              "Stay informed with a comprehensive count of all registered members on your admin portal. Manage your community effectively and nurture your network with precision"
            }
            number={20}
            color="#283a99"
            bgColor="#283a999c"
          />
        </div>
      </div>
    </div>
  );
};

export default AdminHome;
