import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ActiveMemberDetails from "../../../components/admin/ActiveMembersDetail";
import {
  InformationCircleIcon,
  CircleStackIcon,
} from "@heroicons/react/24/outline";
import Loader from "../../../components/loader";
import CarouselComp from "../../../components/Carousel";
import CharidyCampaign from "../../../components/backend/CharidyCampaign";
import ChesedCampaign from "../../../components/backend/chesedCampaign";
import category1 from "../../../assets/images/category1.jpeg";
import category2 from "../../../assets/images/category2.jpeg";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage, auth, db } from "../../../config/FirebaseConfig";
import {
  collection,
  setDoc,
  updateDoc,
  getDocs,
  doc,
} from "firebase/firestore";
import * as XLSX from "xlsx"; // Import xlsx library
const CampaignDetail = () => {
  const handleCopy = (copyText) => {
    navigator.clipboard.writeText(copyText);
  };
  const [campDataItem, setcampDataItem] = useState("");
  const setItemData = () => {
    const item = JSON.parse(localStorage.getItem("item"));
    setcampDataItem(item);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setItemData();
  }, []);
  const navigate = useNavigate();
  const location = useLocation();

  const [exel, setExel] = useState("");
  const [amountsData, setAmountsData] = useState([]);
  const [excelLoading, setExcelLoading] = useState(false);

  const handleOnChangeFile = async (e) => {
    setExel(e.target.files[0]);
    try {
      setExcelLoading(true);
      const jsonData = await getAmountData(e.target.files[0]);
      // Use jsonData here
      setAmountsData(jsonData);
      const url = await uploadFile(e.target.files[0]);
      await updateDatabase(url, jsonData);
      setExcelLoading(false);
      localStorage.setItem("excel", url);
      window.location.reload();
    } catch (error) {
      console.error("Error reading file:", error);
    }
  };

  const getAmountData = async (file) => {
    return new Promise((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet);
          resolve(jsonData);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsArrayBuffer(file);
      } catch (error) {
        reject(error);
      }
    });
  };

  const uploadFile = async (media) => {
    const filename = `media_${Date.now()}.${media.name.split(".").pop()}`;

    const mediaRef = ref(storage, `campaigns/${filename}`);

    try {
      const uploadTask = uploadBytesResumable(mediaRef, media);

      uploadTask.on("state_changed", (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      });

      await uploadTask;

      const downloadURL = await getDownloadURL(mediaRef);

      return downloadURL;
    } catch (error) {
      console.error("Error uploading media: ", error);
      throw error; // Re-throwing the error for handling in the caller
    }
  };

  const updateDatabase = async (excel, json) => {
    try {
      const updatedJoinedUsers = campDataItem.joinedUsers.map((user) => {
        const matchingJsonUser = json.find(
          (jsonUser) => jsonUser.affId === user.affId
        );
        if (matchingJsonUser) {
          return {
            ...user,
            amount: matchingJsonUser.amount,
          };
        } else {
          // If no match found, return the original user
          return user;
        }
      });
      console.log(updatedJoinedUsers);
      const date = new Date();
      const time = date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      });
      const excelTime = `${time}`;
      // Update the campaigns table with the modified 'joined users' array
      await updateDoc(doc(db, "campaigns", `${campDataItem.campId}`), {
        ...campDataItem,
        joinedUsers: updatedJoinedUsers,
        excel: excel,
        excelTime: excelTime,
      });

      console.log("Database updated successfully!");
    } catch (error) {
      console.error("Error updating database:", error);
    }
  };

  const downloadExel = () => {
    window.open(localStorage.getItem("excel"), "_blank");
  };

  return (
    <>
      {campDataItem !== "" && <CarouselComp image={campDataItem.image} />}
      <div className=" max-w-[88rem] my-5 mb-10 p-5 m-auto flex flex-col gap-3">
        <div className="flex justify-center sm:justify-normal items-center">
          <span className="border-t-4 border-[#283a99] sm:w-[23%] w-[40%]"></span>
          <i
            style={{ color: "#283a99", fontWeight: "bold" }}
            className="fa fa-ravelry"
            aria-hidden="true"
          ></i>
        </div>
        <div className="flex sm:flex-row flex-col justify-between items-center gap-5">
          <span className=" sm:text-4xl text-3xl font-bold text-[#283a99] ">
            {campDataItem.name}
          </span>
          <div className="flex gap-2 items-center">
            <button
              onClick={() => {
                navigate(-1);
              }}
              className=" rounded-md bg-[#283a99] hover:bg-[#283a999c] text-white px-5 py-2 "
            >
              {" "}
              <i className="fa pr-3 fa-handshake-o" aria-hidden="true"></i>
              Go Back
            </button>
          </div>
        </div>

        <span className=" text-gray-600 max-w-screen-md">
          {campDataItem.description}
        </span>

        <span className="mt-10 font-semibold text-lg text-black max-w-screen-md">
          Category
        </span>
        <div
          className={`  border border-[#283a99] bg-slate-100 rounded-xl w-fit p-10 `}
        >
          {campDataItem.category === "Charidy Campaign" ? (
            <img
              src={category1}
              alt="categoryImage"
              className="w-[200] h-[200]"
            />
          ) : campDataItem.category === "Chesed Fund Campaign" ? (
            <img
              src={category2}
              alt="CategoryImage"
              className="w-[200] h-[200]"
            />
          ) : (
            <div className="w-[200px] h-auto">
              <span className="font-bold text-2xl">Others</span>
            </div>
          )}
        </div>

        <div
          className={`${
            campDataItem.method === "Manual" &&
            "flex md:flex-row flex-col gap-3 justify-between"
          }`}
        >
          <div
            className={`bg-gray-100 shadow-md flex flex-col ${
              campDataItem.method !== "Manual"
                ? "sm:w-[70%] mx-auto"
                : "md:w-[80%] w-[100%]"
            } gap-2 my-8 rounded-md sm:p-10 p-5`}
          >
            <span className="text-2xl text-[#283a99] font-semibold">URL</span>

            <span style={{ overflowWrap: "anywhere" }} className=" underline">
              {campDataItem.url}
            </span>
            <span className="text-xl mt-5 text-[#283a99] font-semibold">
              Commission
            </span>
            <div className="flex items-center justify-around my-5 ">
              <div className="flex flex-col gap-2">
                <span className=" underline">Commission</span>
                <span className="text-[#283a99] text-2xl font-semibold text-center ">
                  {campDataItem.commission}%
                </span>
              </div>
              <div className="flex flex-col gap-2">
                <span className=" underline">Amount Raised</span>
                <span className="text-[#283a99] text-2xl font-semibold text-center ">
                  ${" "}
                  {campDataItem.category === "Chesed Fund Campaign" ? (
                    <ChesedCampaign userFriendlyLink={campDataItem.url} />
                  ) : campDataItem.category === "Charidy Campaign" ? (
                    <CharidyCampaign url={campDataItem.url} />
                  ) : (
                    "loading"
                  )}
                </span>
              </div>
            </div>
          </div>
          {campDataItem.method === "Manual" && (
            <div className="bg-gray-100 md:w-[60%] w-[100%] shadow-md flex items-center flex-col gap-5 my-8 rounded-md sm:p-10 p-5">
              <span className="text-center font-bold text-xl">
                Manual Tracking
              </span>
              {!campDataItem.excel ? (
                <div className="flex flex-col gap-2 items-center">
                  <InformationCircleIcon className="h-24 w-24"></InformationCircleIcon>
                  <span className="text-[gray]">No File Choosen</span>
                </div>
              ) : (
                <div
                  onClick={downloadExel}
                  className="flex cursor-pointer flex-col gap-2 items-center"
                >
                  <CircleStackIcon className="h-24 w-24 text-green-600"></CircleStackIcon>
                  <span className=" text-blue-500">1 File Attached</span>
                </div>
              )}
              {excelLoading ? (
                <Loader />
              ) : (
                <input
                  onChange={handleOnChangeFile}
                  className="block w-full text-sm mt-6 text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  id="file_input"
                  type="file"
                />
              )}
            </div>
          )}
        </div>

        {campDataItem !== "" && campDataItem.video !== "" && (
          <div className="flex flex-col gap-2">
            <div className="flex items-center">
              <span className="text-lg font-bold mr-5 text-[#283a99]">
                Attached Video
              </span>

              <a
                href={campDataItem.video}
                download={"campaignImage.jpg"}
                target="_blank"
              >
                <div className="flex cursor-pointer transition-all ease-in-out duration-75 hover:scale-110 ">
                  <i
                    style={{ margin: "0px", fontSize: "20px" }}
                    className="fa mr-2 fa-cloud-download"
                    aria-hidden="true"
                  ></i>
                  <span className=" ml-2">Download Video</span>
                </div>
              </a>
            </div>
            <video
              style={{ borderRadius: "10px" }}
              width="800"
              height="600"
              controls
            >
              <source src={campDataItem.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}

        <div className="flex my-10 flex-col gap-4">
          <div className="flex items-center">
            <span className="border-t-4 border-[#283a99] w-[20%]"></span>
            <i
              style={{ color: "#283a99", fontWeight: "bold" }}
              className="fa fa-ravelry"
              aria-hidden="true"
            ></i>
          </div>
          <span className=" sm:text-3xl text-2xl font-bold text-[#283a99]">
            Active Members
          </span>

          {campDataItem !== "" && (
            <ActiveMemberDetails
              joinedUsers={campDataItem.joinedUsers}
              url={campDataItem.url}
              commission={campDataItem.commission}
              username={campDataItem.username}
              password={campDataItem.password}
              category={campDataItem.category}
              org={localStorage.getItem("isSuperAdmin") ? false : true}
              superAdmin={localStorage.getItem("isSuperAdmin") ? true : false}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CampaignDetail;
