import React from "react";
import settings from "../../../assets/images/setting1.png";
// import feature1 from "../../../assets/images/feature1.jpg";
// import feature2 from "../../../assets/images/feature2.jpg";
// import feature3 from "../../../assets/images/feature3.jpg";
// import feature4 from "../../../assets/images/feature4.jpg";

import feature1 from "../../../assets/campaign.json";
import feature2 from "../../../assets/earn.json";
import feature3 from "../../../assets/mobile-payment.json";
import feature4 from "../../../assets/tracking.json";

import FeatureBox from "../features/FeatureBox";
import { motion } from "framer-motion";
const Features = () => {
  return (
    <div className="bg-[#cbdaff] relative mt-10">
      <div className="flex lg:flex-row flex-col gap-5 sm:p-10 p-5 max-w-[88rem] m-auto">
        <div className="flex flex-1 sticky 2xl:justify-start pt-0 lg:pt-10 justify-center flex-col gap-5">
          <img src={settings} alt="" className=" sm:w-2/12 w-1/2" />
          <span className="text-6xl font-bold">
            Our Powerful <br />{" "}
            <span className="text-7xl text-[#283a99]">Feature</span>{" "}
          </span>
          <span className=" 2xl:max-w-xl xl:max-w-md text-gray-600">
            Explore the array of powerful tools and capabilities that set our
            platform apart. From efficient campaign management to robust user
            data analytics, discover how our features empower you to achieve
            your fundraising and advertising goals.
          </span>
        </div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          className="flex-1 flex-col gap-5 relative"
        >
          <div className="relative lg:left-[-30%]">
            <FeatureBox
              image={feature1}
              heading="Diverse Campaigns"
              para="Select from a wide variety of fundraising campaigns as an advertiser, or
            showcase your cause as an organization, tapping into the influence of micro-influencers.
            "
            />
          </div>
          <div className="relative lg:left-[-10%]">
            <FeatureBox
              image={feature2}
              heading="Earn Commissions"
              para="Earn a predetermined commission for every successful donation made
            through your referral link."
            />
          </div>
          <div>
            <FeatureBox
              image={feature3}
              heading="Effortless Payout"
              para="Enjoy hassle-free withdrawals post-campaign, with a prompt transfer of your earned commissions."
            />
          </div>
          <div className="relative lg:left-[-10%]">
            <FeatureBox
              image={feature4}
              heading="Real-Time Tracking"
              para="Organizations can monitor the effectiveness of their advertising partners,
            while advertisers can track their advertising efforts and commission earnings."
            />
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Features;
