import React, { useEffect } from "react";
import UsersList from "../../../components/admin/users/UsersList";
import { auth, db } from "../../../config/FirebaseConfig";
import { getDocs, collection, arrayRemove } from "firebase/firestore";
import { useState } from "react";
const Users = () => {
  const [usersData, setUsersData] = useState([]);
  const [campData, setCampData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUsers();
    getCampaignData(); //add//
  }, []);
  const getUsers = async () => {
    const ar = new Set(); // Use a Set to store unique user IDs
    const UserArr = [];

    const querySnapshot = await getDocs(collection(db, "raiser"));
    querySnapshot.forEach((doc) => {
      const { joinedUsers, uid } = doc.data();
      if (auth.currentUser.uid === uid && joinedUsers) {
        joinedUsers.forEach((user) => {
          ar.add(user.uid); // Add user IDs to the Set
        });
      }
    });

    if (ar.size > 0) {
      // Check the size of the Set
      const userDocs = await getDocs(collection(db, "users"));

      for (const user of ar) {
        userDocs.forEach((doc) => {
          const {
            affid,
            firstName,
            lastName,
            city,
            zipCode,
            phoneNumber,
            legalName,
            email,
            uid,
            paymentMethod,
            account,
          } = doc.data();
          if (uid === user) {
            UserArr.push({
              affid,
              firstName,
              lastName,
              city,
              zipCode,
              phoneNumber,
              legalName,
              email,
              uid,
              paymentMethod,
              account,
            });
          }
        });
      }
      setUsersData(UserArr);
    }
  };

  const getCampaignData = async () => {
    let ar = [];

    setLoading(true);
    await getDocs(collection(db, "campaigns")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const {
          campId,
          commission,
          description,
          image,
          isApproved,
          joinedUsers,
          name,
          raisId,
          url,
        } = doc.data();
        if (raisId === auth.currentUser.uid) {
          ar.push({
            campId,
            commission,
            description,
            image,
            isApproved,
            joinedUsers,
            name,
            raisId,
            url,
          });
        }
      });
    });

    setCampData(ar);
    setLoading(false);
  };
  return (
    <div
      style={{ fontFamily: "'Baloo 2', cursive" }}
      className=" h-screen overflow-y-scroll fixed top-0 right-0 py-10  2xl:w-[83%] xl:w-[75%] md:w-[69%] sm:w-[90%] w-[85%]  "
    >
      <div className="flex flex-col">
        <span className=" pl-5 md:text-4xl sm:text-3xl text-2xl font-bold text-shadow-md text-[#283a99]">
          Our Users
        </span>
        <span className=" pl-5 max-w-3xl text-gray-600 my-5">
          Dive into the wealth of user data, including their donation history
          and commission earnings, that powers our platform's success. Leverage
          these insights to offer a more personalized and responsive experience
          for our community.
        </span>

        <div className=" sm:pr-10 s:pl-0 pl-5">
          <UsersList usersData={usersData} />
        </div>
      </div>
    </div>
  );
};

export default Users;
