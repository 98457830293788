import React from "react";
import AnimateImg from "../../animations/AnimateImg";
const FeatureBox = ({ heading, para, image }) => {
  return (
    <div className="flex sm:flex-row flex-col shadow-md gap-3 p-5 my-5 rounded-2xl bg-white items-center">
      <div className="flex flex-[0.5] items-center justify-center">
        {/* <img className="sm:w-full w-1/2" src={image} alt="" /> */}
        <AnimateImg json={image} width={"100%"} height={"100%"} />
      </div>

      <div className="flex flex-1 flex-col gap-1">
        <div className="flex items-center">
          <span
            className={`border-t-4 border-[#283a99] ${
              heading === "Effortless Payout" ? "w-[39%]" : "w-[45%]"
            }`}
          ></span>
          <i
            style={{ color: "#283a99", fontWeight: "bold" }}
            className="fa fa-ravelry"
            aria-hidden="true"
          ></i>
        </div>
        <h1 className="text-xl font-bold leading-tight">{heading}</h1>
        <span className="text-gray-600">{para}</span>
      </div>
    </div>
  );
};

export default FeatureBox;
