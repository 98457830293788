export const TermsListAdmin = [
  {
    heading: "Jewish Affiliate Network Terms and Conditions for Organizations",
    para: "This Organization Agreement sets the terms for participation in the Jewish Affiliate Network. It includes provisions for campaign approval, setting commission rates, and a mandatory 10% fee payable to the network (besides the percentage given to the advertisers. The document clearly defines the payment process and the network's right to terminate campaigns. Acceptance of these terms is required for using the network's services. The agreement is designed to be clear and concise for ease of understanding.",
  },
  {
    heading: "Introduction",
    para: "Welcome to the Affiliate Marketing Program operated by JCloud Media LLC, doing business as 'Jewish Affiliate Network' (hereinafter referred to as 'JCloud Media,' 'we,' 'our,' or 'us'). By using our website and services, Advertisers ('you' or 'your') agree to comply with and be bound by these Terms and Conditions. Please read this agreement carefully.",
  },
  {
    heading: "Commission Structure",
    subheading: [
      {
        heading: "Commission to Advertisers",
        para: "Organizations have the flexibility to determine the commission percentage they are willing to pay to Advertisers for valid Donations made through unique affiliate links. The commission percentage shall be specified by the Organization and agreed upon with each Advertiser individually.",
      },
      {
        heading: "Commission to Jewish Affiliate Network",
        para: "In addition to the commissions paid to Advertisers, Organizations agree to pay Jewish Affiliate Network a commission of 10% of all Donations received through the platform.",
      },
    ],
  },
  {
    heading: "Campaign Approval and Credit Card Hold",
    subheading: [
      {
        heading: "Campaign Approval",
        para: "To initiate a campaign on Jewish Affiliate Network, Organizations must provide a valid credit card and specify the commission percentage they are willing to pay to Advertisers. A minimum credit card hold of $1500 is required before campaign approval",
      },
      {
        heading: "Credit Card Approval",
        para: "Once the credit card is approved with a minimum hold of $1500, the campaign will be approved for promotion on the platform",
      },
    ],
  },
  {
    heading: "Campaign Content",
    subheading: [
      {
        heading: "Approval Based on Content",
        para: "All campaigns will be subject to approval based on their content. Jewish Affiliate Network reserves the right to approve or reject campaigns at its discretion",
      },
    ],
  },
  {
    heading: "Additional Credit Card Holds",
    subheading: [
      {
        heading: "Exceeding $1500 in Commissions",
        para: "If the total commissions payable to Advertisers exceed $1500 for a specific campaign, an additional $1000 will be placed on hold on the credit card provided by the Organization to continue the promotion of campaigns",
      },
    ],
  },
  {
    heading: "Payment",
    subheading: [
      {
        heading: "Payment to Jewish Affiliate Network",
        para: "Before the 28th of each month, any remaining commission amounts owed to Jewish Affiliate Network will be charged to the credit card provided by the Organization or through another agreed-upon method of payment.",
      },
    ],
  },
  {
    heading: "Termination",
    para: "Jewish Affiliate Network reserves the right to terminate any campaign at any time for any reason, including but not limited to campaigns that violate our content guidelines or any fraudulent activities.",
  },
  {
    heading: "Agreement Acceptance",
    para: "By signing up or using the services of Jewish Affiliate Network, the Organization agrees to these Terms and Conditions.",
  },
];
