import React from "react";
import { auth } from "../../../config/FirebaseConfig";
import { useNavigate } from "react-router-dom";
import Moneygraph from "../../../assets/Moneygraph.json";
import AnimateImg from "../../animations/AnimateImg";
const Hero = ({ openLoginModal, openSignupModal }) => {
  const navigate = useNavigate();
  const handleSignout = () => {
    auth.signOut().then(() => {
      localStorage.removeItem("auth");
      navigate("/affLogin");
    });
  };
  return (
    <div className="xl:py-32 py-10  bg-[#cbdaff] ">
      <div className="xl:max-w-[88rem]  m-auto px-8 flex xl:flex-row flex-col gap-5  ">
        <div className="flex flex-col flex-1 gap-3  ">
          <span className="xl:text-7xl sm:text-5xl text-3xl font-bold leading-tight">
            {" "}
            <span className="text-[#283a99]">Jewish</span>{" "}
            <br className="sm:block hidden text-[#25224A]" /> Affiliate{" "}
            <span className="text-[#283a99]">Network</span>{" "}
          </span>
          <span className="text-[#543FFF] sm:text-[24px] font-[500]">
            Bridging Organizations and Advertisers
          </span>
          <span className="sm:text-[16px] text-[15px] text-gray-600">
            The Jewish Affiliate Network: A commission-based advertising
            platform that offers a risk-free opportunity for organizations. By
            linking these organizations with a global network of advertisers, it
            not only boosts donations but also allows advertisers to generate
            earnings through commissions
          </span>

          {localStorage.getItem("auth") ? (
            <div className="flex gap-6 mt-5 items-center">
              <button
                onClick={handleSignout}
                className="bg-[#283a99] hover:bg-[#283b999c] text-white p-3 rounded-md"
              >
                <i className="fa mr-3 fa-sign-out" aria-hidden="true"></i>Logout
              </button>
              <button
                onClick={() => {
                  navigate("/campaign");
                }}
                className="bg-[#283a99] hover:bg-[#283b999c] text-white p-3 rounded-md"
              >
                <i className="fa mr-7 fa-users" aria-hidden="true"></i>
                Campaigns
              </button>
            </div>
          ) : (
            <div className="flex gap-6 mt-5 items-center">
              <button
                onClick={() => {
                  openSignupModal();
                }}
                className="bg-[#283a99] hover:bg-[#283b999c] text-white p-3 rounded-md"
              >
                Register Now
              </button>
              <div
                onClick={() => {
                  openLoginModal();
                }}
                className="flex gap-2 items-center hover:scale-[1.1] cursor-pointer transition-transform duration-500 ease-in-out"
              >
                <button className="text-[17px] ">Sign In</button>
                <i
                  style={{ color: "black", fontSize: "20px" }}
                  className="fa fa-arrow-circle-right"
                  aria-hidden="true"
                ></i>
              </div>
            </div>
          )}
        </div>

        <div className="flex items-center justify-center flex-1">
          {localStorage.getItem("auth") ? (
            <div className="flex flex-col gap-5">
              <span className="font-bold text-[18px] text-[#000] ">
                Watch this video to learn how to use our platform
              </span>
              <video
                preload="auto"
                style={{ borderRadius: "10px", width: "100%" }}
                controls
              >
                <source
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/jewish-affiliate-network.appspot.com/o/WhatsApp%20Video%202024-02-19%20at%2004.03.15_bff1d210.mp4?alt=media&token=64957a8c-e67a-421f-8b4e-d153c4fc2daf"
                  }
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : (
            <AnimateImg json={Moneygraph} width={400} height={400} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
