import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { auth } from '../../../config/FirebaseConfig'
import { signInWithEmailAndPassword } from 'firebase/auth';
import GeneralMsgModal from '../../../components/modals/GeneralMsgModal';
import Loader from '../../../components/loader';
const Login = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('')
  const [authCred, setAuthCred] = useState({ email: '', password: '' })
  const [loading, setLoading] = useState(false)
  const onChange = (e) => {
    setAuthCred({ ...authCred, [e.target.name]: e.target.value })
  }

  const handleLogin = async (e) => {
    e.preventDefault();
    if (authCred.email === '' || authCred.password === '') {
      setError(true);
      setMessage('Please fill all the fields');
    } else if (authCred.email === 'admin@jan.com' && authCred.password === 'Admin123') {
      setLoading(true);
      await signInWithEmailAndPassword(auth, authCred.email, authCred.password)
        .then((user) => {
          localStorage.setItem('superAdmin', true);
          // console.log(user)
          localStorage.setItem('auth', true);
          setLoading(false);
          navigate('/superadmin/', { replace: true })
        }).catch(err => {
          setError(true);
          setMessage('Invalid Credentials');
        })
    } else {
      setError(true);
      setMessage('Invalid Credentials');
    }
  }
  return (
    <div className="bg-white rounded-lg shadow-lg w-full">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <span className="flex items-center mb-6 font-semibold  cursor-pointer  sm:text-2xl text-xl text-[#000]" style={{ "fontFamily": "'Akaya Telivigala', cursive" }}>
          Jewish Affiliate Network
        </span>
        <div className="w-full  border-md border-gray-400 bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-md font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Signin To Admin Pannel
            </h1>
            <form className="space-y-4 md:space-y-6" action="#">
              <div>
                <label for="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your email</label>
                <input onChange={onChange} value={auth.email} type="email" name="email" id="email" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="name@company.com" required="" />
              </div>
              <div>
                <label for="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                <input onChange={onChange} value={auth.password} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
              </div>

              {loading ? <Loader /> : <button onClick={handleLogin} type="submit" className="w-full text-white bg-[#0D33BF] bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800">Sign in</button>}

            </form>
          </div>
        </div>
      </div>
      {error && <GeneralMsgModal message={message} heading={"Warning"} closeModal={() => { setError(false) }} />}
    </div>
  )
}

export default Login
