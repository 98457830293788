import React, { useEffect, useState } from "react";

const extractShortlinkFromUrl = (url) => {
  try {
    const urlObject = new URL(url);
    const pathnameParts = urlObject.pathname.split("/");
    // Assuming the shortlink is the last part of the pathname
    const campaignShortlink = pathnameParts[pathnameParts.length - 1];
    return campaignShortlink;
  } catch (error) {
    console.error("Error extracting shortlink:", error);
    return null;
  }
};

const getTotalFromCampaignData = async (campaignShortlink) => {
  const apiUrl = `https://api.charidy.com/api/v1/initial?ip=&response_v=2&campaign_shortlink=${campaignShortlink}&extend[]=converted_currency`;

  try {
    const response = await fetch(apiUrl);

    if (response.ok) {
      const jsonData = await response.json();

      // Extract the "total" value from the JSON response
      const total =
        jsonData.data.attributes.campaign.relationships.campaign_stats.data
          .total;

      if (total !== undefined && total !== null) {
        return total;
      } else {
        console.log(
          `Could not find 'total' in the response for ${campaignShortlink}`
        );
        return null;
      }
    } else {
      console.log(
        `Failed to retrieve data for ${campaignShortlink}. Status code: ${response.status}`
      );
      console.log("Response text:", await response.text());
      return null;
    }
  } catch (error) {
    console.error("An error occurred:", error);
    return null;
  }
};

const CharidyCampaign = ({ url }) => {
  const [totalValue, setTotalValue] = useState(null);

  useEffect(() => {
    const campaignShortlink = extractShortlinkFromUrl(url);

    if (campaignShortlink) {
      const fetchTotal = async () => {
        const total = await getTotalFromCampaignData(campaignShortlink);
        setTotalValue(total);
      };

      fetchTotal();
    } else {
      console.error(
        "Failed to extract campaign shortlink from the provided URL"
      );
    }
  }, [url]);

  return <span>{totalValue !== null ? totalValue : <p>Loading...</p>}</span>;
};

export default CharidyCampaign;
