import React, { useState } from "react";
import forget from "../../../assets/images/forget2.svg";
import { useNavigate } from "react-router-dom";
// import { auth} from "../../../config/FirebaseConfig";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import GeneralMsgModal from "../../../components/modals/GeneralMsgModal";

const FotgetPassword = () => {
  const navigate = useNavigate();
  const [passwordResetEmail, setPasswordResetEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);

  const auth = getAuth();

  const handlePasswordReset = async () => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

    if (!emailPattern.test(passwordResetEmail)) {
      setMessage("Please enter a valid email address.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, passwordResetEmail);
      setMessage("A password reset email has been sent to your email address.");
    } catch (error) {
      setMessage("Please enter a valid email address.");
    }
  };

  return (
    <div className=" sm:relative sm:gap-0 gap-5 sm:h-screen sm:overflow-hidden sm:flex-row flex-col-reverse flex">
      <div className="sm:flex hidden md:w-[30%] overflow-visible bg-[#283a99] w-full items-center justify-center">
        <img
          src={forget}
          alt="forgetPassword"
          className="md:absolute md:w-[47%] md:left-0"
        />
      </div>
      <div className="flex h-screen bg-[#efebff] md:w-[70%] w-[100%] md:p-10 items-center justify-center">
        <div className="flex md:w-[45%] flex-col">
          <div className="flex justify-between gap-5 items-center">
            <span className="md:text-4xl text-3xl font-bold text-[#283a99]">
              Forget Password?{" "}
            </span>

            <i
              onClick={() => {
                navigate("/", { replace: true });
              }}
              style={{ fontSize: "30px", cursor: "pointer", color: "#283a99" }}
              className="fa fa-home"
              aria-hidden="true"
            ></i>
          </div>
          <img src={forget} alt="forgetPassword" className=" sm:hidden block" />
          <label
            value={passwordResetEmail}
            onChange={(e) => setPasswordResetEmail(e.target.value)}
            for="email"
            className="mt-6 my-2"
          >
            Please Enter Your Email
          </label>
          <div className="relative ">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
              <i
                style={{ color: "black", fontSize: "20px" }}
                className="fa fa-user"
                aria-hidden="true"
              ></i>
            </div>

            <input
              value={passwordResetEmail}
              onChange={(e) => setPasswordResetEmail(e.target.value)}
              type="email"
              id="input-group-1"
              className=" bg-transparent border border-gray-500 text-black text-sm placeholder-slate-600 rounded-lg focus:ring-0 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
              placeholder="name@flowbite.com"
            />
          </div>
          <button
            onClick={handlePasswordReset}
            className="p-3 mt-10 text-white bg-[#283a99] text-md font-bold hover:scale-[1.1] cursor-pointer transition-transform duration-500 ease-in-out rounded-xl"
          >
            Verify Email
          </button>
          {message && (
            <GeneralMsgModal
              message={message}
              heading={"Warning"}
              closeModal={() => {
                setMessage(false);
                navigate("/");
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default FotgetPassword;
