import React from 'react'
import Lottie from 'react-lottie-player'
import json from '../../assets/loading.json'
const Loading = () => {
  return (
    <div className='flex relative h-screen w-full bg-[#ffffff]'>

      <div className=' flex h-[100%] w-[100%] justify-center items-center'>
        <Lottie
          loop={true}
          autoPlay={true}
          animationData={json}
          play
          style={{ width: 350, height: 350 }}
        />
      </div>

    </div>
  )
}

export default Loading
