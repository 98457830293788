import React from "react";

const GridItems = ({ camp, aff }) => {
  return (
    <div class="flex mt-5 flex-col bg-[#f5f4f4] rounded-lg overflow-hidden">
      <div className="flex px-4 flex-col gap-2 my-10">
        <span className="text-[20px] text-[#283a99] font-semibold">
          {camp.name.length > 50 ? camp.name.slice(0, 50) + "..." : camp.name}
        </span>
        <span className="text-gray-500">Commission: {camp.commission} </span>
        <span className=" text-green-500">
          Earning:
          {(() => {
            const foundUser = camp.joinedUsers.find(
              (joinedUser) => joinedUser.affId === aff
            );

            if (foundUser) {
              if (foundUser.amount) {
                return `$${Number(
                  (Number(foundUser.amount) / 100) * Number(camp.commission)
                ).toFixed(0)}`;
              } else {
                return "$0";
              }
            } else {
              return "$0";
            }
          })()}
        </span>
      </div>
    </div>
  );
};

export default GridItems;
