import React, { useState } from "react";
import { db } from "../../config/FirebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import Loader from "../../components/loader";

const ContactUs = () => {
  const [messageData, setMessageData] = useState({
    message: "",
    email: "",
    phone: "",
  });
  const [loading, setLoading] = useState(false);
  const handleMessageSend = async () => {
    if (messageData.message === "" || messageData.email === "") {
      alert("Please fill all the fields");
      return;
    }
    setLoading(true);
    const date = new Date();
    const time = date.toLocaleTimeString();
    const addDate = `${date.toLocaleDateString()} - ${time}`;
    const messagedData = {
      message: messageData.message,
      email: messageData.email,
      phone: messageData.phone,
      date: addDate,
    };
    await addDoc(collection(db, "messages"), messagedData);
    setMessageData({ message: "", email: "", phone: "" });
    setLoading(false);
    setShow(true);
    setTimeout(() => {
      setShow(false);
    }, 2000);
  };
  const [show, setShow] = useState(false);
  return (
    <div className="flex flex-col h-[100vh] justify-center items-center">
      <div className="flex w-[60%] flex-col gap-2">
        <span className=" text-3xl font-bold text-[#283a99] mb-10">
          Contact Us
        </span>
        <div className="flex flex-col gap-2">
          <label className="font-bold text-black" htmlFor="phone">
            Phone*
          </label>
          <input
            type="text"
            name="phone"
            id="phone"
            className=" p-3 rounded-md focus:outline-none border border-[lightgray] text-black w-full"
            placeholder="Your Phone Number"
            onChange={(e) => {
              setMessageData({ ...messageData, phone: e.target.value });
            }}
            value={messageData.phone}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="font-bold text-black" htmlFor="phone">
            Email*
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className=" p-3 rounded-md focus:outline-none border border-[lightgray] text-black w-full"
            placeholder="Your Email Address"
            onChange={(e) => {
              setMessageData({ ...messageData, email: e.target.value });
            }}
            value={messageData.email}
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="font-bold text-black" htmlFor="phone">
            Please write your message in the box bellow*
          </label>
          <textarea
            name="message"
            id="message"
            className=" p-3 rounded-md focus:outline-none resize-none border border-[lightgray] text-black w-full"
            placeholder="What's on your mind?"
            rows={5}
            onChange={(e) => {
              setMessageData({ ...messageData, message: e.target.value });
            }}
            value={messageData.message}
          ></textarea>
        </div>
        <div className={` flex flex-col gap-2 `}>
          <span
            className={`font-bold ${
              !show ? "text-white" : "text-[orangered]"
            } `}
          >
            Thank You for contacting us
          </span>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <button
            onClick={handleMessageSend}
            className=" bg-[#283a99] mt-3 p-3 text-white w-fit px-10 rounded-lg shadow-xl"
          >
            Submit
          </button>
        )}
        <div className="mt-10 flex flex-col gap-2">
          <span className="font-bold">For Question and quries:</span>
          <span className="text-sm">
            Call us at{" "}
            <span className=" text-red-600 font-bold">917-426-4801</span>
          </span>
          <span className="text-sm">
            Email us at{" "}
            <span className=" text-red-600 font-bold">
              info@jewishaffiliatenetwork.net
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
