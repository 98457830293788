import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import human from "../../../assets/images/human.png";
import ProfileModal from "../../modals/ProfileModal";
import AddCampaign from "../../admin/addCampaign/index.js";
import { getDocs, collection, updateDoc, doc } from "firebase/firestore";
import { auth, db } from "../../../config/FirebaseConfig";
import GeneralMsgModal from "../../modals/GeneralMsgModal";
const AdminNavbar = () => {
  const [openProfile, setOpenProfile] = useState(false);
  const [openAddCampaignModal, setOpenAddCampaignModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    getUserData();
  }, []);
  const getUserData = async () => {
    setLoading(true);
    const arr = [];
    await getDocs(collection(db, "raiser")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (auth.currentUser.uid === doc.id) {
          const { firstName, lastName, email, password, phoneNumber } =
            doc.data();
          arr.push({ firstName, lastName, email, password, phoneNumber });
        }
      });
      setUserData(arr);
      setLoading(false);
    });
  };

  const handleLogin = () => {
    auth.signOut();
    localStorage.clear();
    navigate("/orgLogin");
  };
  return (
    <>
      <aside
        id="default-sidebar"
        className={`fixed shadow-md top-0 left-0 z-40 md:w-64 h-screen w-16 overflow-x-hidden ${
          location.pathname === "/admin/campDetail" && "hidden"
        } `}
        aria-label="Sidebar"
      >
        <div className="h-full sm:px-3 px-2 py-4 overflow-y-auto bg-[#cbdaff] dark:bg-gray-800">
          <ul className="space-y-3 font-medium">
            <li
              onClick={() => {
                navigate("/admin");
              }}
              className=" cursor-pointer"
            >
              <a className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white">
                <span
                  className="font-bold text-center cursor-pointer md:block hidden sm:text-3xl text-xl text-[#283a99]"
                  style={{ fontFamily: "'Akaya Telivigala', cursive" }}
                >
                  Jewish Affiliate Network
                </span>
              </a>
            </li>
            <img
              onClick={() => {
                setOpenProfile(true);
              }}
              src={human}
              alt=""
              className=" m-auto md:w-[40%] w-[85%] cursor-pointer"
            />

            <li
              onClick={() => {
                navigate("/admin");
              }}
              className=" pt-5 cursor-pointer"
            >
              <span
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname === "/admin" && "bg-white"
                }`}
              >
                <i
                  style={{
                    fontSize: "20px",
                    color: `${
                      location.pathname === "/admin" ? "black" : "#626262"
                    }`,
                  }}
                  className="fa fa-pie-chart"
                  aria-hidden="true"
                ></i>

                <span
                  className={`ml-3 md:flex hidden ${
                    location.pathname === "/admin"
                      ? "text-black"
                      : "text-[#626262]"
                  } `}
                >
                  Dashboard
                </span>
              </span>
            </li>
            <li
              onClick={() => {
                navigate("/admin/users");
              }}
              className=" cursor-pointer"
            >
              <a
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname === "/admin/users" && "bg-white"
                }`}
              >
                <i
                  style={{
                    fontSize: "20px",
                    color: `${
                      location.pathname === "/admin/users" ? "black" : "#626262"
                    }`,
                  }}
                  className="fa fa-users"
                  aria-hidden="true"
                ></i>

                <span
                  className={`ml-3 md:flex hidden ${
                    location.pathname === "/admin/users"
                      ? "text-black"
                      : "text-[#626262]"
                  } `}
                >
                  Users
                </span>
              </a>
            </li>
            <li
              onClick={() => {
                navigate("/admin/campaigns");
              }}
              className=" cursor-pointer"
            >
              <a
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname === "/admin/campaigns" && "bg-white"
                }`}
              >
                <i
                  style={{
                    fontSize: "20px",
                    color: `${
                      location.pathname === "/admin/campaigns"
                        ? "black"
                        : "#626262"
                    }`,
                  }}
                  className="fa fa-handshake-o"
                  aria-hidden="true"
                ></i>

                <span
                  className={`ml-3 md:flex hidden ${
                    location.pathname === "/admin/campaigns"
                      ? "text-black"
                      : "text-[#626262]"
                  } `}
                >
                  Campaigns
                </span>
              </a>
            </li>
            <li
              onClick={() => {
                setOpenAddCampaignModal(true);
              }}
              className=" cursor-pointer"
            >
              <a
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname === "/admin/addCampaign" && "bg-white"
                }`}
              >
                <i
                  style={{
                    fontSize: "20px",
                    color: `${
                      location.pathname === "/admin/addCampaign"
                        ? "black"
                        : "#626262"
                    }`,
                  }}
                  className="fa fa-plus-square"
                  aria-hidden="true"
                ></i>

                <span
                  className={`ml-3 md:flex hidden ${
                    location.pathname === "/admin/addCampaign"
                      ? "text-black"
                      : "text-[#626262]"
                  } `}
                >
                  Add Campaign
                </span>
              </a>
            </li>

            <li
              onClick={() => {
                navigate("/admin/trackCampaign");
              }}
              className=" cursor-pointer"
            >
              <a
                className={`flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group ${
                  location.pathname === "/admin/trackCampaign" && "bg-white"
                }`}
              >
                <i
                  style={{
                    fontSize: "20px",
                    color: `${
                      location.pathname === "/admin/trackCampaign"
                        ? "black"
                        : "#626262"
                    }`,
                  }}
                  className="fa fa-thumb-tack"
                  aria-hidden="true"
                ></i>

                <span
                  className={`ml-3 md:flex hidden ${
                    location.pathname === "/admin/trackCampaign"
                      ? "text-black"
                      : "text-[#626262]"
                  } `}
                >
                  Campaign Tracking
                </span>
              </a>
            </li>

            <li
              onClick={handleLogin}
              className=" fixed bottom-5 left-3 w-[15%] cursor-pointer "
            >
              <a className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700 group">
                <svg
                  className="flex-shrink-0 w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                  />
                </svg>
                <span className="flex-1 md:flex hidden ml-3 ">Logout</span>
              </a>
            </li>
          </ul>
        </div>
      </aside>

      {openProfile && (
        <ProfileModal
          loading={loading}
          userData={userData}
          closeProfile={() => {
            setOpenProfile(false);
          }}
        />
      )}

      {openAddCampaignModal && (
        <AddCampaign
          closeModal={() => {
            setOpenAddCampaignModal(false);
          }}
        />
      )}
    </>
  );
};

export default AdminNavbar;
