import React, { useState, useEffect } from "react";
import CampaignBox from "./CampaignBox";
import { getDocs, collection } from "firebase/firestore";
import { auth, db } from "../../config/FirebaseConfig";
import Loading from "../animations/Loading";
const JoinedCampaign = ({ toogleOption, option }) => {
  const [campaignData, setCampaignData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [affid, setAffid] = useState(0);

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = async () => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, "users"));
      querySnapshot.forEach((doc) => {
        const { affid, uid } = doc.data();
        if (uid === auth.currentUser.uid) {
          setAffid(affid);
        }
      });
      getJoinedData();

      setLoading(false);
    } catch (excep) {
      setLoading(false);
      console.log(excep);
    }
  };

  const getJoinedData = async () => {
    try {
      setLoading(true);
      const campItem = [];
      const querySnapshot = await getDocs(collection(db, "campaigns"));
      querySnapshot.forEach((doc) => {
        const {
          campId,
          commission,
          description,
          image,
          isApproved,
          joinedUsers,
          name,
          raisId,
          url,
          status,
          username,
          password,
          video,
          category,
          tinyUrl,
          tracking,
          promotion,
          excelTime,
        } = doc.data();
        if (
          joinedUsers.length > 0 &&
          joinedUsers.find((user) => user.uid === auth.currentUser.uid) &&
          status === "approved"
        ) {
          campItem.push({
            campId,
            commission,
            description,
            image,
            isApproved,
            joinedUsers,
            name,
            raisId,
            url,
            username,
            video,
            password,
            category,
            tinyUrl,
            tracking,
            promotion,
            excelTime,
          });
        }
      });

      const sortedCampItem = campItem.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );
      setCampaignData(sortedCampItem);

      setLoading(false);
    } catch (excep) {
      setLoading(false);
      console.log(excep);
    }
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div class="bg-gray-100 p-5 text-black sm:w-[85%] w-[97%] sm:mt-0 mt-10 m-auto sm:top-[-20px] sm:px-10 relative rounded-[12px] ">
          <div className="flex sm:flex-row flex-col gap-4">
            <button
              onClick={() => {
                toogleOption("available");
              }}
              className={` px-20 rounded-md py-3 ${
                option === "available" ? "bg-[#283a99]" : "bg-[#283a999c]"
              }  text-white`}
            >
              Available
            </button>
            <button
              onClick={() => {
                toogleOption("joined");
              }}
              className={` px-20 rounded-md py-3  ${
                option === "joined" ? "bg-[#283a99]" : "bg-[#283a999c]"
              }   text-white `}
            >
              Joined
            </button>
            <button
              onClick={() => {
                toogleOption("ended");
              }}
              className={` px-20 rounded-md py-3  ${
                option === "ended" ? "bg-[#283a99]" : "bg-[#283a999c]"
              }   text-white `}
            >
              Ended
            </button>
          </div>
          <h1 class=" text-black text-[2.3rem] text-center mt-5">
            Joined Campaigns
          </h1>
          {campaignData.length === 0 ? (
            <div className=" flex flex-col my-10 items-center justify-center">
              {" "}
              <i
                style={{ fontSize: "20px" }}
                className="fa fa-handshake-o text-xl text-black"
                aria-hidden="true"
              ></i>
              <span>No Joined Campaign Data</span>
            </div>
          ) : (
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 my-5 gap-5">
              {campaignData.map((item, index) => {
                return (
                  <CampaignBox key={index} joined item={item} affid={affid} />
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default JoinedCampaign;
