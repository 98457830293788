import React, { useState, useEffect } from "react";
import GridItems from "./GridItems";
import { db } from "../../../config/FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import Loader from "../../../components/loader";
import PaymentBox from "./PaymentBox";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as XLSX from "xlsx"; // Import xlsx library

const PaymentsTracking = () => {
  const [selected, setSelected] = useState(0);
  const [userData, setdata] = useState([]);
  const [usersCampainsData, setUsersCampainsData] = useState([]);
  const [usersPaymentData, setUsersPaymentData] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    let users = [];
    const querySnapshot = await getDocs(collection(db, "users"));
    querySnapshot.forEach((doc) => {
      users.push(doc.data());
    });
    const camps = await getUsersCampaign();
    let usersWithCampaigns = [];
    users.forEach((user) => {
      // Filter the joinedUsers array to find matching addID
      const matchingCampaigns = camps.filter((camp) =>
        camp.joinedUsers.some((joinedUser) => joinedUser.affId === user.affid)
      );

      // If matching campaigns are found, add them to the user object
      if (matchingCampaigns.length > 0) {
        usersWithCampaigns.push({
          userId: user.affid,
          camps: matchingCampaigns,
        });
      }
    });
    let usersPayment = [];
    const querySnapshotPayment = await getDocs(collection(db, "payment"));
    querySnapshotPayment.forEach((doc) => {
      usersPayment.push(doc.data());
    });
    setUsersPaymentData(usersPayment);
    setUsersCampainsData(usersWithCampaigns);
    // fILTER THE USERS WHO HAVE 0 EARNING
    let newUsers = [];
    users.forEach((user) => {
      let totlae = getTotalEarning(user.affid, usersWithCampaigns);
      if (totlae > 0) {
        newUsers.push({ ...user, earning: totlae });
      }
    });
    setdata(newUsers);
    console.log("newUsers", newUsers);
    setLoading(false);
  };

  const getUsersCampaign = async () => {
    let campData = [];
    const querySnapshot = await getDocs(collection(db, "campaigns"));
    querySnapshot.forEach((doc) => {
      campData.push(doc.data());
    });
    return campData;
  };

  const handleSelected = (key) => {
    if (key === selected) {
      setSelected(0);
    } else {
      setSelected(key);
    }
  };
  const getTotalEarning = (affid, usersCampainsData) => {
    let sum = 0;
    const foundUser = usersCampainsData.find((user) => user.userId === affid);
    if (foundUser) {
      foundUser.camps.map((camp) => {
        const newFoundUser = camp.joinedUsers.find(
          (joinedUser) => joinedUser.affId === affid
        );
        if (newFoundUser) {
          if (newFoundUser.amount) {
            sum +=
              (Number(newFoundUser.amount) / 100) * Number(camp.commission);
          }
        }
      });
    }
    return sum.toFixed(0);
  };
  const [totalPay, setTotalPay] = useState(0);
  const getTotalPayment = (affid) => {
    let sum = 0;
    usersPaymentData.forEach((user) => {
      if (user.affId === affid) {
        sum += Number(user.amount);
      }
    });
    return sum.toFixed(0);
  };
  const getBalance = (totalPay, earning) => {
    return (Number(earning) - Number(totalPay)).toFixed(0);
  };
  const downloadExcel = async () => {
    const wsData = [];
    for (const usr of userData) {
      wsData.push(["Payment To:", `${usr.firstName} ${usr.lastName}`]);
      wsData.push(["Total Payment:", getTotalPayment(usr.affid)]);
      wsData.push(["Earning:", usr.earning]);
      wsData.push([
        "Balance:",
        getBalance(getTotalPayment(usr.affid), usr.earning),
      ]);
      wsData.push(["#", "Payout Date", "Amount"]);

      const paymentData = await getPaymentData(usr.affid);

      paymentData.forEach((pay, index) => {
        const row = [
          index + 1, // # column
          pay.payoutDate,
          pay.amount,
          // Add more columns as needed
        ];
        wsData.push(row);
      });

      wsData.push(["", ""]);
    }

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Payments");
    XLSX.writeFile(wb, "payment_summary.xlsx");
  };

  const getPaymentData = async (affid) => {
    let paymentsData = [];
    const querySnapshot = await getDocs(collection(db, "payment"));
    querySnapshot.forEach((doc) => {
      if (doc.data().affId === affid) {
        paymentsData.push(doc.data());
      }
    });
    return paymentsData;
  };
  return (
    <div
      style={{ fontFamily: "'Baloo 2', cursive" }}
      className=" h-screen overflow-y-scroll fixed top-0 right-0 py-10  2xl:w-[80%] xl:w-[75%] md:w-[69%] sm:w-[90%] w-[85%]  "
    >
      <div className="flex flex-col">
        <span className=" pl-5 md:text-4xl sm:text-3xl text-2xl font-bold text-shadow-md text-[#283a99]">
          Payments Tracking
        </span>
        <span className=" pl-5 max-w-5xl text-gray-600 my-5">
          Effortlessly manage and monitor your financial transactions with our
          comprehensive payment tracking system. Stay in control of your cash
          flow, review transaction details, and gain valuable insights into your
          financial performance. Experience the convenience of centralized
          payment tracking, providing you with a clear and organized view of
          your financial landscape.
        </span>
      </div>
      {!loading && (
        <div className="pl-5 mb-5">
          <button
            onClick={downloadExcel}
            className="bg-[#283a99] rounded-md text-white p-2 mt-4"
          >
            Download
          </button>
        </div>
      )}

      <div className="flex flex-col pl-5">
        <div className="flex justify-between items-center  bg-[#cbdaff] p-3 max-w-5xl  my-2 rounded-md">
          <span className="font-bold flex-[0.2]">#</span>
          <span className="flex-1 text-center">Name</span>
          <span className="flex-1 text-center">Earning</span>
          <span className="flex-1 text-center">Total Payment</span>
          <span className="flex-1 text-center">Balance</span>
        </div>
        {loading ? (
          <Loader />
        ) : (
          userData.map((item, key) => {
            return (
              <>
                <div
                  key={key}
                  onClick={() => {
                    handleSelected(key + 1);
                  }}
                  className="flex justify-between items-center cursor-pointer  bg-[#cbdaff] p-3 max-w-5xl  my-2 rounded-md"
                >
                  <span className="font-bold flex-[0.2]">{key + 1}</span>
                  <span className="flex-1 text-center">{item.firstName}</span>
                  <span className="flex-1 text-center ">${item.earning}</span>
                  <span className="flex-1 text-center ">
                    ${getTotalPayment(item.affid)}
                  </span>
                  <span className="flex-1 text-center ">
                    ${getBalance(getTotalPayment(item.affid), item.earning)}
                  </span>

                  {selected === key + 1 ? (
                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  ) : (
                    <i class="fa fa-chevron-right" aria-hidden="true"></i>
                  )}
                </div>

                {selected === key + 1 && (
                  <PaymentBox
                    totalPayment={getTotalPayment(item.affid)}
                    item={item}
                    aff={item.affid}
                    key={key}
                    balance={getBalance(
                      getTotalPayment(item.affid),
                      item.earning
                    )}
                  />
                )}
                <div className=" grid  sm:grid-cols-2 grid-cols-1 gap-5 max-w-5xl">
                  {selected === key + 1 &&
                    usersCampainsData.map((it, key) => {
                      return (
                        it.userId === item.affid &&
                        it.camps.map((camp, key) => {
                          return (
                            <GridItems aff={item.affid} key={key} camp={camp} />
                          );
                        })
                      );
                    })}
                </div>
              </>
            );
          })
        )}
      </div>
    </div>
  );
};

export default PaymentsTracking;
