import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const DashboardGraph = ({ yearsData }) => {
  const monthsList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  // Extract the count values for each month from yearsData
  const seriesData = yearsData.map((data) => data.count);

  const [options] = useState({
    chart: {
      id: "apexchart-example",
    },
    xaxis: {
      categories: yearsData.map((data) => monthsList[data.month - 1]),
    },
  });

  const [series] = useState([
    {
      name: "Users",
      data: seriesData,
    },
  ]);

  return (
    <Chart
      options={options}
      series={series}
      type="bar"
      width={1200}
      height={320}
    />
  );
};

export default DashboardGraph;
