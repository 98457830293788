import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
const CarouselComp = ({ image }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <div className="">
      <Carousel
        responsive={responsive}
      >
        {image.map((item, index) => {
          return <div className="p-10 relative" key={index}> <a href={item} download={"campaignImage.jpg"} target="_blank">
            <div className="flex cursor-pointer transition-all ease-in-out duration-75 hover:scale-125 bg-[#f4f1f1] absolute right-[3.1rem] rounded-md p-2 items-center justify-center top-[3.1rem] ">
              <i style={{ margin: '0px' }} className="fa fa-cloud-download" aria-hidden="true"></i>

            </div></a> <img src={item} alt="Imagss" /></div>
        })}

      </Carousel>
    </div>
  )
}

export default CarouselComp
