import React from "react";
import bg from "../../assets/images/aboutBG.jpg";
import about from "../../assets/images/about us.jpeg";
import LeftRightImage from "./LeftRightImage";
import PointParaItem from "./PointParaItem";
import joinus from "../../assets/images/joinus.jpg";
const AboutUs = () => {
  return (
    <>
      {/* Header */}
      <div
        className="h-[40vh] bg-center bg-cover flex items-center justify-center"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <h1 className="xl:text-[64px] text-[42px] text-center font-extrabold text-white">
          Welcome to the <br /> Jewish Affiliate Network
        </h1>
      </div>
      {/* Content */}
      <div className="my-10 2xl:max-w-7xl lg:max-w-4xl sm:px-10 px-5 m-auto">
        <LeftRightImage
          para={
            "At the Jewish Affiliate Network, we've pioneered the first-ever affiliate platform dedicated to Jewish fundraising campaigns. Our mission? To simplify and amplify fundraising efforts by seamlessly connecting organizations with a vast network of advertisers.'} heading={'Revolutionizing Jewish Fundraising through Affiliate Marketing"
          }
          heading={
            "Revolutionizing Jewish Fundraising through Affiliate Marketing"
          }
          img={about}
        />
      </div>
      <div className="my-10 2xl:max-w-7xl lg:max-w-4xl sm:px-10 px-5 m-auto">
        <PointParaItem
          heading={"For Organizations: Streamlined, Risk-Free Advertising"}
          points={[
            "Navigating the advertising landscape can be costly and time-consuming, with the risk of mismatched partnerships. We eliminate this uncertainty. Our platform allows organizations to effortlessly present their campaigns - simply upload images, videos, and a campaign description, and our team will handle the rest. Once approved, our extensive network of advertisers is alerted, ready to promote your cause.",
            "Our unique tracking system offers real-time insights into each advertiser's performance. And the best part? Organizations don’t pay advertisers directly. We manage all payments, providing a hassle-free solution to fundraising.",
          ]}
        />
      </div>
      <div className="my-10 2xl:max-w-7xl lg:max-w-4xl sm:px-10 px-5 m-auto">
        <PointParaItem
          heading={"For Advertisers: Empowering Your Influence"}
          points={[
            "Whether you're a newcomer to advertising or have a substantial social media presence, our platform empowers you to make a meaningful impact. By promoting campaigns, you not only aid organizations in raising funds but also earn a percentage of donations made through your unique link.",
            "Getting started is easy: create an account, choose a campaign, and download the necessary media and links. You can share these across any platform - WhatsApp, TikTok, Instagram, or even physical posters in local communities. Every contribution made via your link is meticulously tracked, ensuring fair compensation for your efforts.",
          ]}
        />
      </div>
      <div className="my-10 2xl:max-w-7xl lg:max-w-4xl sm:px-10 px-5 m-auto">
        <LeftRightImage
          reverse
          para={
            "The Jewish Affiliate Network is more than just a platform; it's a bridge connecting organizations and advertisers in an efficient and effective manner. By joining us, you become part of a community dedicated to making a difference in the Jewish fundraising landscape."
          }
          heading={"Join Our Network: Where Connection Meets Simplicity"}
          img={joinus}
        />
      </div>
      <div className="my-10 mt-20 text-center">
        <span className=" font-bold text-[20px] border-t-2 py-2 border-[#283b9931] border-b-2">
          Embark on this journey with us - Join Today!
        </span>
      </div>
    </>
  );
};

export default AboutUs;
