import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import DonationTrackModal from "./DonationTrackModal";
const DonationTrackingCard = ({ campData, count }) => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-col overflow-hidden pb-8 justify-between mb-10 bg-white  rounded-md"
      style={{ boxShadow: "0px 5px 7px 0px rgb(114 114 114 / 25%)" }}
    >
      <div className="flex flex-col">
        <div
          className=" bg-cover bg-center bg-no-repeat h-[40vh]"
          style={{ backgroundImage: `url(${campData.image})` }}
        />

        <div className="flex px-4 flex-col gap-2 my-10">
          <div className="flex items-center justify-between gap-2">
            <span className="text-[25px] text-[#283a99] font-semibold">
              {campData.name}
            </span>
          </div>
          <span>Total Users: {count}</span>
        </div>

        <div className=" flex px-4 items-center">
          <button
            onClick={() => {
              setOpenModal(true);
            }}
            className="bg-[#283a99] hover:bg-[#283a999c] px-3 w-full py-2 text-white text-[17px] rounded-md"
          >
            View Details
          </button>
        </div>
      </div>
      {openModal && (
        <DonationTrackModal
          joinedUsers={campData.joinedUsers}
          campData={campData}
          closeModal={() => {
            setOpenModal(false);
          }}
        />
      )}
    </div>
  );
};

export default DonationTrackingCard;
