import { Fragment, useRef, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import DonationTrackTable from "./DonationTrackTable";
import { auth, db } from "../../../config/FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
export default function DonationTrackModal({
  closeModal,
  joinedUsers,
  campData,
}) {
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const cancelButtonRef = useRef(null);
  const [joinedUsersData, setJoinedUsersData] = useState([]);
  useEffect(() => {
    getJoinedUsersData();
    // console.log(joinedUsers, 'joinedUsers')
  }, []);
  const getJoinedUsersData = async () => {
    const ar = [];
    const userPromises = joinedUsers.map(async (user) => {
      const querySnapshot = await getDocs(collection(db, "users"));
      querySnapshot.forEach((doc) => {
        const { uid } = doc.data();
        if (user.uid === uid) {
          ar.push(doc.data());
        }
      });
    });

    // Wait for all promises to resolve
    await Promise.all(userPromises);

    setJoinedUsersData(ar);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[10000]"
        initialFocus={cancelButtonRef}
        onClose={() => {
          setOpen();
          closeModal();
          navigate("/");
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg font-semibold  text-gray-900"
                      >
                        Joined Users
                      </Dialog.Title>
                      <div className="mt-2">
                        <DonationTrackTable
                          joinedUsers={joinedUsersData}
                          campData={campData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-[#283a99] px-14 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 hover:text-black sm:mt-0 sm:w-auto"
                    onClick={() => {
                      setOpen(false);
                      closeModal();
                    }}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
