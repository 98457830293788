import React, { useEffect, useState } from "react";

function ChesedCampaign({ userFriendlyLink }) {
  const [campaignIdentifier, setCampaignIdentifier] = useState("");
  const [apiLink, setApiLink] = useState("");
  const [amountFundedDollars, setAmountFundedDollars] = useState(null);

  useEffect(() => {
    // Extract the campaign identifier and user-friendly part from the provided link
    const linkParts = userFriendlyLink.split("/");

    // Construct the API link by combining the campaign identifier and the user-friendly part
    const apiLink = `https://us-central1-tcf-backend-prod.cloudfunctions.net/causes_live/${linkParts[3]}/${linkParts[4]}`;
    setApiLink(apiLink);

    // Check if data is stored in local storage and within the time span
    const storedData = localStorage.getItem(apiLink);
    if (storedData) {
      const { timestamp, amountFunded } = JSON.parse(storedData);
      const currentTime = new Date().getTime();
      const timeDifference = currentTime - timestamp;
      if (currentTime - timestamp < 6 * 60 * 60 * 1000) {
        setAmountFundedDollars(amountFunded);

        return;
      }
    }

    // Fetch data from the API link
    fetch(apiLink)
      .then((response) => response.json())
      .then((data) => {
        const amountFundedCents = data.amount_funded_cents;
        const amountFundedDollars = amountFundedCents / 100;
        setAmountFundedDollars(amountFundedDollars);

        // Store data in local storage
        localStorage.setItem(
          apiLink,
          JSON.stringify({
            timestamp: new Date().getTime(),
            amountFunded: amountFundedDollars,
          })
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [userFriendlyLink, campaignIdentifier]);

  return (
    <span>
      {amountFundedDollars !== null ? amountFundedDollars : "Loading..."}
    </span>
  );
}

export default ChesedCampaign;
