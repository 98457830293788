import React, { useState, useEffect } from "react";
import Hero from "../../components/homeCollection/hero/index";
import WorkStrategy from "../../components/homeCollection/workStrategy";
import Features from "../../components/homeCollection/features";
import LoginModal from "../../components/login/LoginModal";
import SignupModal from "../../components/signup/SignupModal";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../config/FirebaseConfig";
import { getDocs, collection } from "firebase/firestore";
import HomePayoutModal from "../../components/modals/HomePayoutModal";
import LiveCampaigns from "../../components/homeCollection/LiveCampaigns";

const Home = () => {
  const [LoginModalShow, setLoginModalShow] = useState(false);
  const [SignupModalShow, setSignupModalShow] = useState(false);
  const naviagte = useNavigate();
  const openLoginModal = () => {
    setLoginModalShow(true);
  };
  const closeLoginModal = () => {
    setLoginModalShow(false);
  };

  const openSignupModal = () => {
    setSignupModalShow(true);
  };
  const closeSignupModal = () => {
    setSignupModalShow(false);
  };
  useEffect(() => {
    if (localStorage.getItem("admin") !== null) {
      naviagte("/admin");
    } else if (localStorage.getItem("superAdmin")) {
      naviagte("/superadmin");
    }
  }, []);

  useEffect(() => {
    checkPayout();
  }, []);
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const checkPayout = async () => {
    const authTok = localStorage.getItem("auth");
    if (authTok) {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, "users"));
      querySnapshot.forEach((doc) => {
        const { profile } = doc.data();
        if (!profile && doc.id === localStorage.getItem("uid")) {
          setModalOpen(true);
        }
      });
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex items-center h-[100vh] justify-center">
          <h1>Loading...</h1>
        </div>
      ) : (
        <div>
          <Hero
            openLoginModal={openLoginModal}
            openSignupModal={openSignupModal}
          />
          <WorkStrategy />
          <Features />
          {LoginModalShow && <LoginModal closeLoginModal={closeLoginModal} />}
          {SignupModalShow && (
            <SignupModal closeSignupModal={closeSignupModal} />
          )}
          {modalOpen && (
            <HomePayoutModal closeModal={() => setModalOpen(false)} />
          )}
        </div>
      )}
    </>
  );
};

export default Home;
