import { Disclosure } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";
import human from "../../assets/images/human.png";
import ProfileModal from "../modals/ProfileModal";
import { useEffect, useState } from "react";
import { auth, db } from "../../config/FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import LoginModal from "../login/LoginModal";
import SignupModal from "../signup/SignupModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  useEffect(() => {
    localStorage.getItem("auth") && getUserData();
  }, []);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(false);
  const getUserData = async () => {
    let ar = [];
    setLoading(true);
    await getDocs(collection(db, "users")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        if (auth.currentUser.uid === doc.id) {
          const {
            email,
            firstName,
            lastName,
            password,
            state,
            city,
            legalName,
            phoneNumber,
            zipCode,
            paymentMethod,
            account,
          } = doc.data();
          ar.push({
            email,
            firstName,
            lastName,
            password,
            state,
            city,
            legalName,
            phoneNumber,
            zipCode,
            paymentMethod,
            account,
          });
        }
      });
    });
    setUserData(ar);
    setLoading(false);
  };

  const location = useLocation();
  const navigate = useNavigate();
  const [LoginModalShow, setLoginModalShow] = useState(false);
  const [SignupModalShow, setSignupModalShow] = useState(false);
  const naviagte = useNavigate();
  const openLoginModal = () => {
    setLoginModalShow(true);
  };
  const closeLoginModal = () => {
    setLoginModalShow(false);
  };

  const openSignupModal = () => {
    setSignupModalShow(true);
  };
  const closeSignupModal = () => {
    setSignupModalShow(false);
  };
  const handleSignout = () => {
    auth.signOut().then(() => {
      localStorage.removeItem("auth");
      naviagte("/affLogin");
    });
  };
  const [openProfile, setOpenProfile] = useState(false);
  const navigation = [
    {
      name: "Home",
      current: location.pathname === "/" ? true : false,
      path: "/",
    },
    {
      name: "About",
      current: location.pathname === "/about" ? true : false,
      path: "/about",
    },
    {
      name: "Campaigns",
      current: location.pathname === "/campaign" ? true : false,
      path: "/campaign",
    },
    ...(localStorage.getItem("auth")
      ? [
          {
            name: "Payments",
            current: location.pathname === "/paymentTracking" ? true : false,
            path: "/paymentTracking",
          },
        ]
      : []),
  ];
  return (
    <>
      <Disclosure as="nav" className="bg-[#283a99]">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-[95rem] pt-3 px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:text-black">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-1 items-center sm:pr-0 pr-5 sm:items-stretch sm:justify-between justify-end">
                  <div className="flex flex-shrink-0 items-center">
                    <span
                      onClick={() => {
                        navigate("/");
                      }}
                      className="font-bold cursor-pointer sm:text-3xl text-xl text-[#fff]"
                      style={{ fontFamily: "'Baloo 2', sans-serif" }}
                    >
                      Jewish Affiliate Network
                    </span>
                  </div>
                  <div className="hidden sm:ml-6 sm:block">
                    <div className="flex gap-4 ">
                      {navigation.map((item) => (
                        <span
                          key={item.name}
                          onClick={() => {
                            navigate(item.path);
                          }}
                          className={classNames(
                            item.current
                              ? "bg-[#cadafe] text-black font-[700]"
                              : "text-white font-[400] hover:bg-[#cadafe] hover:text-black",
                            "rounded-md px-3 py-2 text-black text-sm font-medium cursor-pointer"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
                {!localStorage.getItem("auth") ? (
                  <div className=" hidden ml-4 md:flex gap-2 items-center">
                    <button
                      onClick={() => {
                        openSignupModal();
                      }}
                      className="bg-[#fff] hover:bg-[#979595] text-black p-2 text-sm rounded-md"
                    >
                      Register Now
                    </button>
                    <div
                      onClick={() => {
                        openLoginModal();
                      }}
                      className="flex gap-2 items-center hover:scale-[1.1] cursor-pointer transition-transform duration-500 ease-in-out"
                    >
                      <button className="text-sm text-white ">Sign In</button>
                      <i
                        style={{ color: "white", fontSize: "15px" }}
                        className="fa fa-arrow-circle-right"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={handleSignout}
                    className=" ml-4 hidden md:flex bg-[#fff] hover:bg-[#979595] text-black p-2 rounded-md"
                  >
                    Logout
                  </button>
                )}

                {localStorage.getItem("auth") && (
                  <div className=" md:flex hidden  md:w-[4%] pl-2 sm:w-[7%] w-[11%]">
                    <img
                      onClick={() => {
                        setOpenProfile(true);
                      }}
                      src={human}
                      alt=""
                      className=" w-[100%] cursor-pointer"
                    />
                  </div>
                )}
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden border-b-2 border-white">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {localStorage.getItem("auth") && (
                  <div className="   md:w-[4%] pl-2 sm:w-[7%] w-[11%]">
                    <img
                      onClick={() => {
                        setOpenProfile(true);
                      }}
                      src={human}
                      alt=""
                      className=" w-[100%] cursor-pointer"
                    />
                  </div>
                )}
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    onClick={() => {
                      navigate(item.path);
                    }}
                    className={classNames(
                      item.current
                        ? "bg-[#cadafe] text-black font-[600]"
                        : "text-white hover:bg-[#cadafe] hover:text-white",
                      "block rounded-md px-3 py-2 text-base "
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      {openProfile && (
        <ProfileModal
          loading
          userData={userData}
          closeProfile={() => {
            setOpenProfile(false);
          }}
        />
      )}
      {LoginModalShow && <LoginModal closeLoginModal={closeLoginModal} />}
      {SignupModalShow && <SignupModal closeSignupModal={closeSignupModal} />}
    </>
  );
}
