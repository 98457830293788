import React, { useState } from "react";
import ProfileModal from "../../modals/ProfileModal";
const UsersList = ({ usersData }) => {
  const [openModal, setOpenModal] = useState(false);
  const [showUserDatail, setShowUserDatail] = useState("");
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <div className="relative my-5  w-full ">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            className="w-4 h-4 text-gray-500 dark:text-gray-400"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
          >
            <path
              stroke="currentColor"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
            />
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          className="block w-full p-4 pl-10 text-sm text-gray-900 focus:outline-none border border-gray-300 rounded-lg bg-gray-50"
          placeholder="Search User ..."
          required
        />
        <button
          type="submit"
          className="text-white absolute right-2.5 bottom-2.5 bg-[#283a99] hover:bg-[#283a999c] focus:outline-none  font-medium rounded-lg text-sm px-4 py-2"
        >
          Search
        </button>
      </div>
      <table className="w-full text-sm text-center  text-gray-500 dark:text-gray-400">
        <thead className="text-xs text-white uppercase bg-[#283a99] ">
          <tr>
            <th scope="col" className="">
              #
            </th>
            {/* <th scope="col" className="px-6 py-3">
              Fisrt Name
            </th>
            <th scope="col" className="px-6 py-3">
              Last Name
            </th>
            <th scope="col" className="px-6 py-3">
              Email
            </th> */}
            <th scope="col" className="px-6 py-3">
              Affiliate Id
            </th>
            {/* <th scope="col" className="px-6 py-3">
              Payment Method
            </th>
            <th scope="col" className="px-6 py-3">
              Account No
            </th> */}
            <th scope="col" className="pl-6 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {usersData.length === 0 ? (
            <div className=" flex items-center justify-center my-5 mx-auto self-center text-center">
              <span>No Active Members</span>
            </div>
          ) : (
            usersData.map((user, index) => {
              return (
                <tr
                  key={index}
                  className={`bg-white border-b ${
                    index % 2 !== 0 && "bg-[#fee5f78a]"
                  } `}
                >
                  <th
                    scope="row"
                    className=" font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </th>
                  {/* <td scope="row" className="px-6 py-3 ">
                    {user.firstName}
                  </td>
                  <td className="px-6 py-3">{user.lastName}</td>
                  <td className="px-6 py-3">{user.email}</td> */}
                  <td className="px-6 py-3">{user.affid}</td>
                  {/* <td className="px-6 py-3">{user.paymentMethod}</td>
                  <td className="px-6 py-3">{user.account}</td> */}
                  <td className="pl-6  py-3">
                    <button
                      onClick={() => {
                        setShowUserDatail([user]);
                        setOpenModal(true);
                      }}
                      className=" bg-[#283a99] hover:bg-[#283a999c] px-2 py-1 rounded-md text-white"
                    >
                      Details
                    </button>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      {openModal && (
        <ProfileModal
          showJoined
          org
          userData={showUserDatail}
          closeProfile={() => {
            setOpenModal(false);
          }}
        />
      )}
    </div>
  );
};

export default UsersList;
