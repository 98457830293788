import React from "react";
import AnimateImg from "../../animations/AnimateImg";

const StrategyBox = ({ heading, para1, para2, image, reverse, subheading }) => {
  return (
    <div
      className={`flex gap-4 flex-col justify-between ${
        reverse ? " xl:flex-row-reverse" : "xl:flex-row"
      } `}
    >
      <div
        className={`flex-1 flex flex-col gap-2 ${
          reverse && "xl:pl-20"
        } justify-center`}
      >
        <div className="flex items-center">
          <span
            className={`border-t-4 border-[#283a99] ${
              subheading !== "Amplify Your Mission"
                ? "xl:w-[59%] md:w-[30%] w-[46%]"
                : " xl:w-[58%] lg:w-[30%] md:w-[30%] w-[40%]"
            } `}
          ></span>
          <i
            style={{ color: "#283a99", fontWeight: "bold" }}
            className="fa fa-ravelry"
            aria-hidden="true"
          ></i>
        </div>
        <span className="text-2xl font-bold ">
          {heading}: <span className=" font-normal">{subheading}</span>{" "}
        </span>
        <span className="xl:max-w-xl max-w-screen-md text-gray-600">
          {para1}
        </span>
        <span className="xl:max-w-xl max-w-screen-md text-gray-600">
          {para2}
        </span>
      </div>

      <div className="flex-1 flex items-center justify-center">
        {/* <img src={image} alt="cover" /> */}
        <AnimateImg json={image} width={400} height={400} />
      </div>
    </div>
  );
};

export default StrategyBox;
