import React from "react";

const LeftRightImage = ({ img, heading, para, reverse }) => {
  return (
    <>
      <div
        className={`flex justify-between items-center gap-10 ${
          reverse
            ? "md:flex-row-reverse flex-col-reverse"
            : "lg:flex-row flex-col"
        } `}
      >
        <div
          className={`flex  flex-col ${
            reverse ? "flex-1" : "flex-[0.7]"
          } gap-5`}
        >
          <span className="text-[22px] text-[#283a99] font-bold">
            {heading}
          </span>
          <span className=" text-gray-500 text-justify">{para}</span>
        </div>

        <div
          className={`flex flex-1  items-center ${
            !reverse ? "justify-center" : "md:justify-normal justify-center"
          } `}
        >
          <img
            className={`${!reverse ? "sm:w-3/4" : ""} rounded-2xl`}
            src={img}
          />
        </div>
      </div>
    </>
  );
};

export default LeftRightImage;
