import React from 'react'

const ConditionBody = ({ heading, subheading, para, points }) => {
  return (
    <div className='flex flex-col gap-2'>
      <h1 className='text-md font-semibold text-gray-900 dark:text-white'>{heading}</h1>
      {para && <p class="text-sm leading-relaxed text-gray-500 dark:text-gray-400">{para}</p>}
      {points && points.map((point, index) => {
        return <div key={index} className=' pl-5 flex gap-2 items-center'>
          <i className="fa fa-check" aria-hidden="true"></i>
          <span className='text-sm leading-relaxed text-gray-500 dark:text-gray-400'>{point}</span>
        </div>
      })}
      {subheading && subheading.map((sub, index) => {
        return <span key={index} className=' text-sm font-bold'>{sub.heading}: <span className='font-normal'>{sub.para}</span></span>
      })}
    </div>
  )
}

export default ConditionBody
