import React, { useState, useEffect } from "react";
import MessageBox from "../../components/messages/MessageBox";
import { db } from "../../../config/FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import Loader from "../../../components/loader";
const Messages = () => {
  const [selected, setSelected] = useState(0);
  const toogleSelected = (key) => {
    if (selected === key) {
      setSelected(0);
    } else {
      setSelected(key);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const getData = async () => {
    setLoading(true);
    let messages = [];
    const querySnapshot = await getDocs(collection(db, "messages"));
    querySnapshot.forEach((doc) => {
      messages.push(doc.data());
    });
    setMessages(messages);
    setLoading(false);
  };
  return (
    <div
      style={{ fontFamily: "'Baloo 2', cursive" }}
      className=" h-screen pr-5 overflow-y-scroll fixed top-0 right-0 py-10  2xl:w-[80%] xl:w-[75%] md:w-[69%] sm:w-[90%] w-[85%]  "
    >
      <div className="flex flex-col">
        <span className=" md:text-4xl sm:text-3xl text-2xl font-bold text-shadow-md text-[#283a99]">
          Messages
        </span>
      </div>
      <div className="flex mt-14 flex-col gap-5">
        {loading ? (
          <Loader />
        ) : messages.length > 0 ? (
          messages.map((message, index) => {
            return (
              <MessageBox
                toogleSelected={toogleSelected}
                selected={selected}
                index={index + 1}
                message={message}
                key={index}
              />
            );
          })
        ) : (
          <span className="text-center text-2xl">No Messages</span>
        )}
      </div>
    </div>
  );
};

export default Messages;
