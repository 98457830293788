import React, { useEffect, useState } from "react";
import AdminCampaignBox from "../../../components/admin/dashboardCollection/AdminCampaignBox";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../../../config/FirebaseConfig";
import AdminRaiserBox from "../../components/homeCollection/AdminRaiserBox";
const SuperAdminHome = () => {
  useEffect(() => {
    getCampaigns();
    getRaisers();
  }, []);

  const [raisers, setRaisers] = useState([]);
  const [raisersCount, setRaisersCount] = useState("-");

  const [campCount, setCampaignsCount] = useState("-");
  const [campData, setCampData] = useState([]);
  const getCampaigns = async () => {
    let count = 0;
    let data = [];
    await getDocs(collection(db, "campaigns")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        data.push(doc.data());
        count++;
      });
      setCampaignsCount(count);
      setCampData(data);
    });
  };

  const getRaisers = async () => {
    let count = 0;
    let data = [];
    await getDocs(collection(db, "raiser")).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        data.push(doc.data());
        count++;
      });
      setRaisersCount(count);
      setRaisers(data);
    });
  };
  return (
    <div
      style={{ fontFamily: "'Baloo 2', cursive" }}
      className=" h-screen overflow-y-scroll fixed top-0 right-0 py-10  2xl:w-[80%] xl:w-[75%] md:w-[69%] sm:w-[90%] w-[85%]  "
    >
      <div className="flex flex-col">
        <span className=" pl-5 md:text-4xl sm:text-3xl text-2xl font-bold text-shadow-md text-[#283a99]">
          Admin Dashboard
        </span>
        <div className="grid lg:grid-cols-1 mt-20 grid-cols-1 gap-5 lg:pr-12 lg:px-0 px-10">
          <AdminCampaignBox
            count={campCount}
            heading={"Campaigns"}
            para={
              "Get a bird's-eye view of all active campaigns on your admin portal. Track, manage, and optimize each campaign's performance with ease, ensuring your fundraising efforts are always on target"
            }
            number={15}
            color="#283a99"
            bgColor="#283a999c"
          />
          <AdminRaiserBox
            heading={"Organizations"}
            para={
              "Get a bird's-eye view of all active campaigns on your admin portal. Track, manage, and optimize each campaign's performance with ease, ensuring your fundraising efforts are always on target"
            }
            number={20}
            color="#283a99"
            bgColor="#283a999c"
            count={raisersCount}
          />
        </div>
      </div>
    </div>
  );
};

export default SuperAdminHome;
