import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import React, { useEffect, useState } from "react";
import mobileImg from "../../../assets/orgsignup.json";
import pcImage from "../../../assets/images/signupNew.svg";
import VerifyEmail from "../../../components/modals/VerifyEmail";
import ErrorModal from "../../../components/modals/ErrorModal";
import GeneralMsgModal from "../../../components/modals/GeneralMsgModal";
import { auth, db } from "../../../config/FirebaseConfig";
import { States } from "../../../components/states";
import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  setDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import {
  RecaptchaVerifier,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  signInWithPhoneNumber,
  updateProfile,
} from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../../../components/loader";
import TermsConditions from "../../../components/termsConditions";
import { termsList } from "../../../components/termsConditions/termsList";
import AnimateImg from "../../../components/animations/AnimateImg";
const AffiliateSignup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [authCred, setAuthCred] = useState({
    email: "",
    password: "",
    firstName: "",
    paymentMethod: "PaypalId",
    lastName: "",
    phoneNumber: "",
    paypalId: "",
    legalName: "",
    city: "",
    address: "",
    state: "",
    zipCode: "",
    check: "",
  });
  const [message, setMessage] = useState("");
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [signupOption, setSignupOption] = useState(1);
  const [receiveEmailNotifications, setReceiveEmailNotifications] =
    useState(true);
  const [termsModal, setTermsModal] = useState(false);
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("auth")) {
      navigate("/");
    }
  }, []);
  const getCount = async () => {
    let acount = 0;
    const querySnapshot = await getDocs(collection(db, "userCount"));
    querySnapshot.forEach((doc) => {
      const { count } = doc.data();
      acount = count + 1;
      updateCount(count + 1);
    });
    return acount;
  };
  const updateCount = async (count) => {
    const countDocRef = doc(collection(db, "userCount"), "count");
    await updateDoc(countDocRef, { count: count });
  };

  const [verifyModal, setVerifyModal] = useState(false);

  const closeVerifyModal = () => {
    setVerifyModal(false);
  };

  const onChange = (e) => {
    setAuthCred({ ...authCred, [e.target.name]: e.target.value });
  };

  const handleSignup = async (e) => {
    if (authCred.email === "" || authCred.password === "") {
      setErrorModalOpen(true);
      setMessage("Please fill all the fields");
      setLoading(false);
      return;
    } else {
      setLoading(true);
      e.preventDefault();
      const count = await getCount();

      try {
        const userCred = await createUserWithEmailAndPassword(
          auth,
          authCred.email,
          authCred.password
        );
        const user = userCred.user;
        await sendEmailVerification(user);
        const userDocRef = doc(collection(db, "users"), user.uid);

        const data = {
          email: user.email,
          emailNotifications: receiveEmailNotifications,
        };
        await setDoc(userDocRef, data);
        setVerifyModal(true);
        setTimeout(() => {
          setLoading(false);
          navigate("/affLogin");
        }, 3000);
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          setMessage(
            "Email address is already in use. Please use a different email."
          );
          setErrorModalOpen(true);
        } else {
          setMessage(error.message);
        }
        setErrorModalOpen(true);
        setLoading(false);
      }
    }
  };
  const handleEmailNotificationChange = () => {
    setReceiveEmailNotifications(!receiveEmailNotifications);
  };
  const [phone, setPhone] = useState("");
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [confirmedUser, setConfirmedUser] = useState("");
  const [vcode, setvcode] = useState("");
  const onPhoneChange = (value) => {
    setPhone(value);
  };
  const sendOTP = async () => {
    try {
      const recaptchav = new RecaptchaVerifier(auth, "recaptcha", {
        size: "invisible",
      });
      const confirmation = await signInWithPhoneNumber(
        auth,
        `+${phone}`,
        recaptchav
      );
      if (confirmation) {
        setIsConfirmed(true);
        setConfirmedUser(confirmation);
      }
    } catch (error) {
      // Handle the error gracefully here
      console.error("An error occurred while sending OTP:", error);
      // Optionally, you can show a user-friendly error message or perform other actions
    }
  };

  const handleVerify = async () => {
    try {
      const userr = await confirmedUser.confirm(vcode);
      if (userr) {
        const userDocRef = doc(collection(db, "users"), userr.user.uid);

        const data = {
          emailNotifications: receiveEmailNotifications,
          phoneNumber: phone,
        };
        await setDoc(userDocRef, data);

        navigate("/affLogin");
      }
    } catch (error) {
      console.error("An error occurred while verifying OTP:", error);
    }
  };

  return (
    <div className=" md:h-screen md:overflow-hidden sm:flex-row flex-col-reverse flex gap-5">
      <div className="md:flex hidden md:w-[50%] w-full items-center justify-center">
        {/* <img src={pcImage} alt="" /> */}
        <AnimateImg json={mobileImg} width={"100%"} height={"100%"} />
      </div>
      <div className="flex flex-col gap-4  md:w-[90%] w-full lg:px-40 md:px-20 px-9 py-16 md:py-0 text-white  justify-center bg-[#283a99]">
        <div className="flex mb-10 justify-between items-center">
          <span className="md:text-4xl text-2xl flex-1 text-center font-bold">
            Affiliate Signup
          </span>
          <i
            onClick={() => {
              navigate("/", { replace: true });
            }}
            style={{ fontSize: "30px", cursor: "pointer", color: "white" }}
            className="fa fa-home"
            aria-hidden="true"
          ></i>
        </div>
        <div className=" mx-auto w-auto  md:hidden flex">
          <AnimateImg json={mobileImg} width={"100%"} height={"100%"} />
        </div>

        <div className="flex w-[50%] gap-4 self-center flex-row justify-between">
          <span
            onClick={() => {
              setSignupOption(1);
            }}
            className={`flex flex-1 cursor-pointer p-[10px] rounded-[8px] justify-center bg-${
              signupOption === 1 ? "white" : "whiteAlpha"
            } text-${signupOption === 1 ? "black" : "white"}`}
          >
            Email
          </span>
          <span
            onClick={() => {
              setSignupOption(2);
              setAuthCred({ email: "" });
            }}
            className={`flex flex-1 cursor-pointer p-[10px] rounded-[8px] justify-center bg-${
              signupOption === 2 ? "white" : "whiteAlpha"
            } text-${signupOption === 2 ? "black" : "white"}`}
          >
            Phone
          </span>
        </div>

        {/* Email Password */}
        <div className="flex sm:flex-row flex-col w-[70%] self-center  justify-between gap-4">
          {signupOption === 1 ? (
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                <i
                  style={{ color: "white", fontSize: "20px" }}
                  className="fa fa-envelope"
                  aria-hidden="true"
                ></i>
              </div>
              <input
                onChange={onChange}
                value={authCred.email}
                name="email"
                type="email"
                id="input-group-3"
                className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg focus:ring-0 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                placeholder="name@flowbite.com"
              />
            </div>
          ) : isConfirmed ? (
            <div className="relative w-full">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                <i
                  style={{ color: "white", fontSize: "20px" }}
                  className="fa fa-phone"
                  aria-hidden="true"
                ></i>
              </div>
              <input
                onChange={(val) => {
                  setvcode(val.target.value);
                }}
                value={vcode}
                name="vcode"
                type="text"
                id="input-group-3"
                className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg focus:ring-0 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                placeholder="Enter Verification Code"
              />
            </div>
          ) : (
            <PhoneInput
              country={"us"}
              inputStyle={{ color: "black" }}
              value={phone}
              onChange={onPhoneChange}
            />
          )}
        </div>
        {signupOption !== 1 &&
          (isConfirmed ? (
            <button
              onClick={handleVerify}
              className="p-3 bg-white md:w-[70%] w-[100%] mx-auto text-[#283a99] text-md font-bold hover:scale-[1.1] cursor-pointer transition-transform duration-500 ease-in-out rounded-xl"
            >
              Verify
            </button>
          ) : (
            <div className="flex  w-[70%] self-center flex-col justify-between gap-4">
              <button
                onClick={sendOTP}
                className="p-3 bg-white md:w-[70%] w-[100%] mx-auto text-[#283a99] text-md font-bold hover:scale-[1.1] cursor-pointer transition-transform duration-500 ease-in-out rounded-xl"
              >
                Send Otp
              </button>
              <div id="recaptcha"></div>
            </div>
          ))}

        {signupOption === 1 && (
          <div className="flex sm:flex-row w-[70%] self-center flex-col justify-between gap-4">
            <div className="relative w-full ">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3.5 pointer-events-none">
                <i
                  style={{ color: "white", fontSize: "20px" }}
                  className="fa fa-lock"
                  aria-hidden="true"
                ></i>
              </div>
              <input
                onChange={onChange}
                value={authCred.password}
                name="password"
                type="password"
                id="input-group-4"
                className=" bg-transparent border border-gray-300 text-white text-sm placeholder-slate-300 rounded-lg  block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                placeholder="********"
              />
            </div>
          </div>
        )}
        <div className="flex self-center items-center text-white">
          <input
            type="checkbox"
            checked={receiveEmailNotifications}
            onChange={handleEmailNotificationChange}
          />
          <label className="ml-2">
            Yes, I'd like to receive email updates about new campaigns.
          </label>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <button
            onClick={handleSignup}
            className="p-3 bg-white md:w-[70%] w-[100%] mx-auto text-[#283a99] text-md font-bold hover:scale-[1.1] cursor-pointer transition-transform duration-500 ease-in-out rounded-xl"
          >
            Signup
          </button>
        )}

        <span className="mt-5 text-center ">
          Already have and account?{" "}
          <span
            onClick={() => {
              navigate("/affLogin");
            }}
            className="cursor-pointer hover:text-slate-300 underline "
          >
            Login
          </span>{" "}
        </span>

        {/* {error && <div className="text-red-500">{message}</div>} */}
        {errorModalOpen && (
          <ErrorModal
            errorMessage={message}
            closeModal={() => setErrorModalOpen(false)}
          />
        )}
      </div>

      {verifyModal && <VerifyEmail closeVerifyModal={closeVerifyModal} />}
      {termsModal && (
        <TermsConditions
          termsList={termsList}
          visible={termsModal}
          handleAccept={() => {
            setAccepted(true);
            setTermsModal(false);
          }}
          closeModal={() => {
            setTermsModal(false);
          }}
        />
      )}
    </div>
  );
};

export default AffiliateSignup;
